import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ContractForm from './ContractForm';
import PaymentForm from './PaymentForm';
import IndividualContract from './IndividualContract';
import { handleResponse, FormResponse, errorsFormatter } from '../../../../../forms/Response';
import { Loading } from '../../../../../forms/Helpers';

export default class ContractsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contractFormOpen: false,
      currentContract: null,
    }
  }

  componentDidMount() {
    let url = window.location.href;
    let currentContract;
    let contractFormOpen;
    if (url.split('&action').length > 1 && url.includes('action=create')) {
      contractFormOpen = true;
    }
    if (url.includes('&contract_id')) {
      let contractId = url.split('&contract_id=')[1].split('&')[0];
      currentContract = this.props.vendor.contracts.filter(c => c.id === parseInt(contractId))[0];
      contractFormOpen = true;
    }
    if (currentContract || contractFormOpen) {
      this.setState({contractFormOpen, currentContract })
    }
  }

  // handleDeletedPayments = (vals, contract) => {
  //   if (contract && contract.vendor_payments.length > vals.vendor_payments.length) {
  //     let payments = [...contract.vendor_payments];
  //     let savedPayments = vals.vendor_payments.map(v => v.id);
  //     payments.forEach((payment) => {
  //       if (savedPayments.includes(payment.id) === false) {
  //         payment['_destroy'] = true;
  //       }
  //     })
  //     return payments;
  //   } else {
  //     return vals.vendor_payments;
  //   }
  // }

  deleteContract = () => {
    if (confirm('Are you sure you want to delete this contract? This cannot be undone.')) {
      this.setState({ isSubmitting: true }, () => {
        let { currentContract } = this.state;
        axios.delete(`/api/v1/vendor_contracts/${currentContract.id}`, {
          headers: {
            'X-CSRF-Token': this.props.token,
            'Content-Type': 'application/json'
          }
        })
        .then((result) => {
          this.setState({ currentContract: null, contractFormOpen: false, isSubmitting: false }, () => {
            this.props.removeDeletedContract(currentContract.id);
          })
        })
        .catch((error) => {
          this.setState({ isSubmitting: false }, () => {
            let message = errorsFormatter(error.response.data.errors) || 'There was a problem deleting this contract.';
            handleResponse(this, message, error.response.status, 4000);
          })
        })
      })
    }
  }

  openContractForm = (contract) => {
    this.setState({ contractFormOpen: true, currentContract: contract });
  }

  contractsList = () => {
    let { vendor } = this.props;
    if (vendor.contracts.length > 0) {
      return vendor.contracts.map((c) => {
        return (
          <ul key={c.id}>
            <IndividualContract
              contract={c}
              openContractForm={this.openContractForm}/>
          </ul>
        )
      })
    } else {
      return (
        <p>No contracts added yet for { vendor.company_name }. </p>
      )
    }
  }


  closeForm = () => {
    this.setState({ contractFormOpen: false, currentContract: null, paymentFormOpen: false })
  }

  addOrUpdateContract = (contract) => {
    this.setState({ contractFormOpen: false, isSubmitting: true, currentContract: null }, () => {
      this.props.addOrUpdateContract(contract, this.setState({isSubmitting: false}))
    })
  }

  togglePayment = () => {
    this.setState({ paymentFormOpen: !this.state.paymentFormOpen })
  }

  handleNewVendorPayment = (payment) => {
    this.setState({ paymentFormOpen: false }, () => {
      this.props.handleNewVendorPayment(payment);
    })
  }

  content = () => {
    if (this.state.isSubmitting) {
      return <Loading />
    } else if (this.state.contractFormOpen) {
      return (
        <ContractForm
          vendor={this.props.vendor}
          contract={this.state.currentContract}
          addOrUpdateContract={this.addOrUpdateContract}
          deleteContract={this.deleteContract}
          token={this.props.token}
          closeForm={this.closeForm}
          vendorTypes={this.props.vendorTypes}
          vendorCategories={this.props.vendorCategories} />
      )
    } else if (this.state.paymentFormOpen) {
      return <PaymentForm
              vendor={this.props.vendor}
              contract={this.props.vendor.contracts[0]}
              handleNewVendorPayment={this.handleNewVendorPayment}
              token={this.props.token}
              closeForm={this.closeForm} />
    }
      else {
      return (
        <div>
          <div className='contracts__list'>
            { this.contractsList()}
          </div>

          { this.props.vendor.contracts.length === 0 &&
            <button
              onClick={() => this.setState({ contractFormOpen: true })}
              className='button button-primary'>Add Contract details</button>
          }

        </div>
      )
    }
  }

  render() {
    return (
      <div>
        { this.content() }
      </div>
    )
  }
}

ContractsContainer.propTypes = {
  vendor: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  addOrUpdateContract: PropTypes.func.isRequired
}
