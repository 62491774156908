import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Loading } from '../../../forms/Helpers';
import { FormResponse, handleResponse, errorsFormatter } from '../../../forms/Response';

export default class NonContractPaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expenseName: '',
      expenseAmount: '',
      expenseType: '',
      doNotIncludeInBudget: false,
      addToPackList: false
    }
  }

  componentDidMount() {
    let { item } = this.props;
    if (this.props.item) {
      this.setState({
        expenseName: item.name,
        expenseAmount: item.amount,
        expenseType: item.vendor_type_id,
        doNotIncludeInBudget: item.not_included_in_budget,
        addToPackList: false
      })
    } else {
      this.setState({
        expenseName: '',
        expenseAmount: '',
        expenseType: '',
        doNotIncludeInBudget: false,
        addToPackList: false
      })
    }
  }

  vendorTypeOptions = () => {
    const { vendorTypes, vendorCategories } = this.props;
    return vendorCategories?.map(c => {
      if (vendorTypes?.map(t => t[2]).includes(c[0])) {
        return (
          <optgroup key={c[0]} label={c[1]}>
            {
              vendorTypes?.map(t => {
                if (c[0] === t[2]) {
                  return (
                    <option key={t[0]} value={t[0]}>{t[1]}</option>
                  )
                }
              })
            }
          </optgroup>
        )
      }
    })
  }

  submitExpense = (e) => {
    e.preventDefault()
    let url = '/api/v1/non_contract_payments';
    let method = 'POST';
    if (this.props.item) {
      url = `/api/v1/non_contract_payments/${this.props.item.id}`;
      method = 'PATCH';
    }

    this.setState({ isSubmitting: true }, () => {
      axios({
        url: url,
        method: method,
        data: {
          non_contract_payment: {
            name: this.state.expenseName,
            user_id: this.props.user.id,
            vendor_type_id: this.state.expenseType,
            amount: this.state.expenseAmount,
            not_included_in_budget: this.state.doNotIncludeInBudget,
            add_to_pack_list: this.state.addToPackList
          },
          authenticity_token: this.props.token
        }
      })
      .then((response) => {
        this.props.toggleModalAndAddPayment(response.data.payment);
      })
      .catch((error) => {
        let message = errorsFormatter(error.response.data.errors) || 'There was a problem saving this expense.'
        this.setState({ isSubmitting: false, submitError:  message }, () => {
          setTimeout(() => this.setState({ submitError: null }), 4000);
        });
      })
    })
  }

  handleDelete = () => {
    if (confirm('Are you sure you want to delete this expense?')) {
      axios.delete(`/api/v1/non_contract_payments/${this.props.item.id}`, {
        headers: {
          'X-CSRF-Token': this.props.token,
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        this.props.deleteExpense()
      })
      .catch(() => {
        let message = 'There was a problem deleting this expense.'
        this.setState({ isSubmitting: false, submitError:  message }, () => {
          setTimeout(() => this.setState({ submitError: null }), 4000);
        });
      })
    }
  }

  formDisabled = () => {
    let { expenseName, isSubmitting } = this.state;
    return !expenseName || expenseName.length === 0 || isSubmitting;
  }

  handleFieldChange = (e, field) => {
    this.setState({ [field]: e.target.value });
  }

  submitResponse = () => {
    if (this.state.submitSuccess) {
      return <p className='success mb-one-em'>Saved this expense!</p>
    } else if (this.state.submitError) {
      return <p className='error mb-one-em'>{this.state.submitError}</p>
    }
  }

  render() {
    if (this.state.isSubmitting) {
      return ( <Loading /> )
    } else {
      return (
        <form onSubmit={this.submitExpense}>
          <h4 style={{clear:'both'}} className='is-4 title'>
            { this.props.item ? 'Edit Expense' : 'Add Expense' }
          </h4>
            { this.submitResponse() }
          <div className='field'>
            <div className='control'>
              <input
                className='input'
                onChange={(e) => this.handleFieldChange(e, 'expenseName')}
                value={this.state.expenseName}
                type='text' placeholder='Name' />
            </div>
          </div>
          <div className='field'>
            <div className='control'>
              <input
                className='input'
                onChange={(e) => this.handleFieldChange(e, 'expenseAmount')}
                value={this.state.expenseAmount}
                type='number' placeholder='$' />
            </div>
          </div>

          <div className='field'>
            <label className='label'>Expense Type for Budget</label>
            <div className='select'>
              <select
                value={this.state.expenseType}
                onChange={(e) => this.handleFieldChange(e, 'expenseType')}>
                <option></option>
                { this.vendorTypeOptions()}
                <option >Other</option>
              </select>
            </div>

          </div>

          <div className='field'>
            <label className='checkbox'>
              <input
                checked={this.state.doNotIncludeInBudget}
                onChange={(e) => this.setState({ doNotIncludeInBudget: e.target.checked })}
                value={this.state.doNotIncludeInBudget}
                type='checkbox'
                style={{marginRight: '10px'}} />
              Do not include in budget?
            </label>
            <span data-tooltip="We understand that friends or family members may be taking care of the cost of some items. If you have not added their contribution to your total budget, check this box to exclude the cost of this item from your Guided Bride Budget."
                  className="has-tooltip-multiline has-tooltip-right"><i className='icon icon-question'></i></span>

          </div>

          <div className='field'>
            <label className='checkbox'>
              <input
                checked={this.state.addToPackList}
                onChange={(e) => this.setState({ addToPackList: e.target.checked })}
                value={this.state.addToPackList}
                type='checkbox'
                style={{marginRight: '10px'}} />
              Add to pack list?
            </label>
            <span data-tooltip="This item will be added to your personal list of items that should be gathered along with your belongings and brought to the venue for the wedding."
                  className="has-tooltip-multiline has-tooltip-right"><i className='icon icon-question'></i></span>
          </div>

          <div className='row-buttons'>
            <button
              className='button'
              type='button'
              onClick={this.props.toggleModal}>cancel</button>
            <button
              disabled={this.formDisabled()}
              className='button button-primary'
              type='submit'>submit</button>
          </div>
          { this.props.item &&
            <p
              onClick={this.handleDelete}
              className='error mt-one-em clickable'>delete this expense</p>
          }
        </form>
      )
    }
  }
}

NonContractPaymentForm.propTypes = {
  vendorCategories: PropTypes.array.isRequired,
  vendorTypes: PropTypes.array.isRequired,
  deleteExpense: PropTypes.func.isRequired
}
