import React from 'react';
import { tasksByDate, TASK_STATUSES, UNFINISHED_STATUSES } from '../tasks/TaskFunctions';
import PropTypes from 'prop-types';

const NUMBER_OF_TASKS_TO_SHOW = 4;

export default class TaskOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  dueAndOverDueTasks(tasks) {
    const today = this.dateWithoutHours(new Date);
    const tasksSortedByDate = tasksByDate(tasks);
    const unfinishedTasks = tasksSortedByDate.filter((t) => UNFINISHED_STATUSES.includes(t.status));
    const overdueAndDue = unfinishedTasks.filter((t) => Date.parse(this.dateWithoutHours(t.due_date)) <= Date.parse(today));
    const selection = overdueAndDue.slice(0, NUMBER_OF_TASKS_TO_SHOW);
    return this.taskList(selection);
  }

  taskList(tasks) {
    return tasks.length > 0
      ? tasks.map(t => this.taskItem(t))
      : <p style={{marginTop: '10px', textAlign: 'center'}}>None!</p>
  }

  taskItem(task) {
    const taskClass = this.taskClass(task);
    return (
      <div
        key={task.id}
        className={'tasks-overview__task ' + `${taskClass}`}
        onClick={() => this.props.openTask(task, taskClass)}>
        <span className='tasks-overview__top'>
          { this.taskHeader(taskClass, task)}
        </span>
        <p>{task.title}</p>
      </div>
    )
  }

  taskHeader = (taskClass, task) => {
    if (taskClass.includes('today')) {
      return 'Due today';
    } else if (taskClass.includes('overdue')) {
      return 'Overdue';
    } else {
      return `Due on ${this.dateFormatted(task.due_date)}`
    }
  }

  isToday(taskDate) {
    const now = this.dateWithoutHours(new Date);
    return now.getTime() === taskDate.getTime();
  }

  taskClass = (task) => {
    const now = this.dateWithoutHours(new Date);
    const taskDate = this.dateWithoutHours(task.due_date);
    if (this.isToday(taskDate)) {
      return 'tasks-overview__task--today'
    } else if (taskDate < now) {
      return 'tasks-overview__task--overdue'
    } else {
      return 'tasks-overview__task--upcoming'
    }
  }

  dateFormatted(date) {
    const dateArray = date.split('-');
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
  }

  dateWithoutHours(d) {
    const dateToUpdate = new Date(d)
    dateToUpdate.setHours(0,0,0,0);
    return dateToUpdate
  }

  upcomingTasks(tasks) {
    const today = this.dateWithoutHours(new Date);
    const tasksSortedByDate = tasksByDate(tasks);
    const unfinishedTasks = tasksSortedByDate.filter((t) => UNFINISHED_STATUSES.includes(t.status));
    const upcoming = unfinishedTasks.filter((t) => Date.parse(this.dateWithoutHours(t.due_date)) > Date.parse(today));
    const selection = upcoming.slice(0, NUMBER_OF_TASKS_TO_SHOW);
    return this.taskList(selection);
  }

  render() {
    const { tasks } = this.props;
    return (
      <div className='tasks-overview'>
        <div className='tasks-overview__inner'>
          <div className='header-with-swatch'>
            <h4 className='subtitle is-4 has-text-centered mb-quarter-em section-header'>Tasks</h4>
          </div>

          <div className='columns'>
            <div className="column">
              <h6 className='subtitle is-6 has-text-centered'>Due & Overdue</h6>
              { this.dueAndOverDueTasks(tasks) }
            </div>
            <div className="column">
              <h6 className='subtitle is-6 has-text-centered'>Upcoming</h6>
              { this.upcomingTasks(tasks) }
            </div>
          </div>
          <div className='has-text-centered'>
            <a className='' href='/tasks'>see all</a>
          </div>
        </div>
      </div>
    )
  }
}

TaskOverview.propTypes = {

}
