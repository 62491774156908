import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios'
import { Formik, Form, useField, FieldArray } from 'formik';
import { FormTextInput, FormSelect, DatePickerField, Loading } from '../../../../../forms/Helpers';
import { formatMoney } from '../../../../../HelperFunctions';

import { FormResponse, handleResponse, errorsFormatter } from '../../../../../forms/Response';
import * as Yup from 'yup';

export default class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  initialValues() {
    return {
      total_due: '',
      due_date: '',
      days_before_to_send_reminder: '',
      status: 'unpaid'
    }
  }

  remainingAmount = (vals) => {
    let contractTotal = parseFloat(this.props.contract.total_price);
    let paymentsTotal = parseFloat(this.paymentsTotal(vals));
    let total = contractTotal - paymentsTotal;
    let c = total >= 0 ? 'success' : 'error';
    return <p className={c}>{`Total remaining: ${formatMoney(total)}`}</p>
  }

  paymentsTotal = (vals) => {
    let { contract } = this.props;
    let currentVal = vals.total_due != '' ? vals.total_due : 0;
    let total = parseFloat(currentVal);
    if (contract.deposit_amount && contract.deposit_amount != '') {
      total += parseFloat(contract.deposit_amount);
    }
    contract.vendor_payments.map(p => total += parseFloat(p.total_due));
    return total;
  }

  paymentsLessThanOrEqualToTotal = (vals) => {
    let total = this.paymentsTotal(vals);
    return total > parseInt(this.props.contract.total_price) ? false : true;
  }

  submitDisabled = (vals) => {
    return this.paymentsLessThanOrEqualToTotal(vals) != true;
  }

  submitPayment = (vals) => {
    let paymentsLessThanOrEqualToTotal = this.paymentsLessThanOrEqualToTotal(vals);
    if (paymentsLessThanOrEqualToTotal === false ) {
      let message = 'The payments exceed the total amount of the contract.';
      message += ` The contract total is ${formatMoney(this.props.contract.total_price)},
      but the total amount of payments you\'ve entered is ${formatMoney(this.paymentsTotal(vals))} `
      handleResponse(this, message, 422, 8000);
    } else {
      this.setState({ isSubmitting: true }, () => {
        axios.post('/api/v1/vendor_payments', {
          vendor_payment: {
            vendor_contract_id: this.props.contract.id,
            total_due: vals.total_due,
            due_date: vals.due_date,
            days_before_to_send_reminder: vals.days_before_to_send_reminder,
            status: vals.status
          },
          authenticity_token: this.props.token
        })
        .then((response) => {
          this.props.handleNewVendorPayment(response.data.vendor_payment);
        })
        .catch((error) => {
          this.setState({ isSubmitting: false } , () => {
            let message = 'There was a problem saving this payment.';
            let status = 422;
            if (error.response && error.response.data) {
              message = errorsFormatter(error.response.data.errors)
              status = error.response.status
            }
            handleResponse(this, message, status, 4000);
          })
        })
      })
    }
  }

  render() {
    let { responseMessage, responseCode } = this.state;
    if (this.state.isSubmitting) {
      return <Loading />
    } else {
      return (
        <>
        <Formik
          initialValues={this.initialValues()}
          validationSchema={Yup.object({
            total_due: Yup.number()
              .required('Please enter a valid amount.')
              .min(1, 'Must be more than 0!'),
            due_date: Yup.date()
              .required('Please enter a valid date.')
          })}
          onSubmit={(values, { setSubmitting }) => {
            this.submitPayment(values);
          }}
          render={({ values }) => (
            <Form>
              { this.remainingAmount(values) }

              <div className='mb-one-em'>
                { FormResponse(responseMessage, responseCode) }
              </div>
              <div>
                <h5 className='subtitle is-5'>Add New Payment</h5>
                <div className='field is-grouped'>
                  <FormTextInput
                    name='total_due'
                    type="number"
                    label='Payment Amount'
                    placeholder="Payment Amount"
                  />

                   <FormSelect label="Paid?" name='status'>
                     <option value={'unpaid'}>Unpaid</option>
                     <option value={'fully_paid'}>Paid</option>
                   </FormSelect>
                </div>

                <div className='field is-grouped'>
                  <DatePickerField
                    label={values.status === 'fully_paid' ? 'Payment Date' : 'Due Date'}
                    name='due_date'
                   />

                </div>
              </div>

              <div className='row-buttons'>
                <button
                  className='button'
                  type='button'
                  onClick={this.props.closeForm}>cancel</button>
                <button
                  disabled={this.submitDisabled(values)}
                  className='button button-primary'
                  type='submit'>submit</button>
              </div>
            </Form>
          )}>
          </Formik>
        </>

      );
    }
  }
}

PaymentForm.propTypes = {

}
