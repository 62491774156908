import React from 'react';
import PropTypes from 'prop-types';

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <footer className="footer">
        <div className="content">
          <div className='columns'>
            <div className='column'>
              <a href='/'><img src='/images/gb-logo.png' /></a>
              <div className='footer__social'>
                <a href='https://www.facebook.com/guidedbride' target='_blank'>
                  <i className='icon icon-facebook'></i>
                </a>
                <a href='https://www.instagram.com/guidedbride' target='_blank'>
                  <i className='icon icon-instagram'></i>
                </a>
                <a href='https://www.pinterest.com/guidedbride' target='_blank'>
                  <i className='icon icon-pinterest'></i>
                </a>
              </div>
            </div>
            <div className='column footer__links'>
              <a href="mailto:help@guidedbride.com">Contact</a>
              <a href='/faqs'>FAQs</a>
              <a href='/terms_of_service' target="_blank">Terms of Service</a>
              <a href='/privacy_policy' target="_blank">Privacy Policy</a>
            </div>
          </div>
        </div>
      </footer>

    )
  }
}

Footer.propTypes = {

}
