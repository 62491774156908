import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormTextInput, Loading } from '../../forms/Helpers';
import { FormResponse, handleResponse, errorsFormatter } from '../../forms/Response';

export default class AccountSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      responseMessage: null,
      responseCode: null,
      showResponseMsg: false
    }
  }

  componentWillUnmount() {
    if (this.formResponse) {
      clearTimeout(this.formResponse);
    }
  }

  createAccount = (values) => {
    this.setState({ showResponseMsg: false });
    
    this.setState({ isSubmitting: true }, () => {
      let data = {
        user: {
          email: values.email,
          password: values.password,
          password_confirmation: values.passwordConfirmation
        }
      }

      let defaultHeaders = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.props.token,
        'Accept': 'application/json'
      }

      axios.post('/users', data,
        { headers: defaultHeaders }
      )
      .then((response) => {
        // window.location.reload();
        this.setState({ showResponseMsg: true, isSubmitting: false });
      })
      .catch((error) => {
        let message = errorsFormatter(error.response.data.errors) || 'There was a problem creating your account.';
        console.log(message, error.response)
        const msgConfirm = 'You have to confirm your email address before continuing.';
        if(message === msgConfirm || error.response.data.error === msgConfirm) {
          this.setState({ showResponseMsg: true, isSubmitting: false });
        } else {
          handleResponse(this, message, error.response.status, 4000)
        }
      })
    })
  }

  form = () => {
    return (
      <Formik
        initialValues={{
          email: '',
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .required('Please enter your email address')
            .email('Please enter a valid email address'),
          password: Yup.string()
            .required('Please enter a password')
            .min(8, 'Should be 8 chars minimum')
            .max(128, 'Should be 128 chars maximum'),
          passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
        })}
        onSubmit={(values, { setSubmitting }) => {
          this.createAccount(values)
        }}
      >
        <Form>
          <div className="field">
            <FormTextInput
              name="email"
              type="text"
              required
              placeholder="Email"
            />
          </div>

          <div className="field">
            <FormTextInput
              name="password"
              type="password"
              required
              placeholder="Password"
            />
          </div>

          <div className="field">
            <FormTextInput
              name="passwordConfirmation"
              type="password"
              required
              placeholder="Password confirmation"
            />
          </div>

          <div className="control">
            <div className="field">
              <button className="button button-primary is-fullwidth" type="submit">Sign Up</button>
            </div>

            <div className='register-notice'>
              <p>Have an account? <a href='/users/sign_in'>Log in here.</a></p>
            </div>

            <div className='divider'></div>

            <div className="field">
              <a className="button is-fullwidth oauth-button oauth-button--google link-without-styling"
                 href="/users/auth/google_oauth2"
                 data-method="POST"
                 rel="nofollow"
              >
                <i className="icon icon-google" />
                Log In With Google
              </a>
            </div>

            <div className="field">
              <a className="button is-fullwidth oauth-button oauth-button--facebook"
                 href="/users/auth/facebook"
                 data-method="POST"
                 rel="nofollow"
              >
                <i className="icon icon-facebook" />
                Log In With Facebook
              </a>
            </div>
            <div className="g-signin2" data-onsuccess="onSignIn"></div>
          </div>

        </Form>
      </Formik>
    );
  }

  render() {
    let { responseMessage, responseCode, isSubmitting, showResponseMsg } = this.state;
    return (
      <div className="column sessions__container">
        <div className="home__container has-text-centered	">
          <a className="logo-home" href="/" rel="up">
            <img src="/images/gb-logo.png" alt="guided bride logo" />
          </a>
          <h2 className="title is-2 is-spaced">Get Started for Free</h2>
          <h5 className="subtitle is-5 w720">Guided Brides are better prepared and more confident in their wedding choices, saving time, energy and money, every step of the way!</h5>
          { FormResponse(responseMessage, responseCode) }

          { showResponseMsg && <div className="response_msg" >Your wedding planning journey awaits you. Please check your email to confirm your account!</div>}
          { isSubmitting &&
            <Loading />
          }
          { !isSubmitting &&
            <div className='sessions__form'>
              { this.form() }
            </div>
          }
        </div>
      </div>
    )
  }
}

AccountSignup.propTypes = {
  token: PropTypes.string.isRequired
}
