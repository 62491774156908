import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Login from './Login';
import AccountSignup from './AccountSignup';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

const MODAL_STYLE = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem 2.5rem',
    background: 'transparent',
    border: 'none',
    width: '75%',
    height: '75%',
  },
  overlay: {
    background: 'rgb(61 61 61 / 31%)'
  }
}

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    if (typeof(window) !== 'undefined') {
      Modal.setAppElement('body')
    }
  }

  blockImage = (imageUrl) => {
    return {
      background: `url('${imageUrl}')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100%',
      height: '95%',
      backgroundPosition: 'center',
      borderRadius: '10px'
    }
  }

  render() {
    return (
      <div className='home'>
        <Header openAuthForm={this.openAuthForm} />
        <div className='home__hero'>
          <div className='home__intro'>
            <div>
              <h2 className='title is-2'>Hello, Lovely!</h2>
              <p>Ready to plan the perfect wedding? Save money and stay organized with Guided Bride.</p>
            </div>
            <a href='/users/sign_up' className='acts-as-button button button-primary button-signup'>Get Started</a>
          </div>
        </div>

        <div className='home__content'>
          <div className='home__tiles'>
            <h3 className='subtitle is-3 has-text-centered'>Every bride deserves to be a Guided Bride.</h3>
            <div className="tile is-ancestor">
              <div className='tile'>
                <i className='icon icon-tasklist'></i>
                <p>Curated task list to guide you every step of the way.</p>
              </div>
              <div className='tile'>
                <i className='icon icon-party'></i>
                <p>Keep track of vendors, venues, wedding party, guests, and more.</p>
              </div>
              <div className='tile'>
                <i className='icon icon-chart'></i>
                <p>Suggested budget and tools to track your expenses.</p>
              </div>
            </div>
          </div>

          <div className='columns home__welcome'>
            <div className='column  is-one-quarter-desktop is-half-tablet home__meg'>
              <div className='image-overlay'></div>
              <i className='icon icon-play clickable' onClick={() => this.setState({ modalIsOpen: true })}></i>
            </div>
            <div className='column  is-three-quarters-desktop is-half-tablet home__welcome-text'>
              <h3 className='is-3 subtitle'>Welcome to Guided Bride</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>

              <div className='home__signature'>
                <span>-Meg</span>
              </div>
            </div>
            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={() => this.setState({ modalIsOpen: false, modalContent: null })}
              style={MODAL_STYLE}>
              <div className='welcome-modal'>
                <i
                  onClick={() => this.setState({ modalIsOpen: false, modalContent: null })}
                  className='icon icon-close is-pulled-right'></i>
                  <ReactPlayer
                  width='100%'
                  height='100%'
                  url='https://vimeo.com/473942482'
                  controls={true} />
              </div>
            </Modal>
          </div>

          <div className='home__blocks'>
            <div className='block block--right'>
              <div className='block__text'>
                <h4 className='is-4 subtitle'>Organize your wedding party.</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  sed do eiusmod tempor.</p>
              </div>
              <div className='block__img'>
                <div style={this.blockImage('/images/home/wedding-party.png')}></div>
              </div>
            </div>


            <div className='block block--left'>
              <div className='block__img'>
                <div style={this.blockImage('/images/home/chat.jpg')}></div>
              </div>
              <div className='block__text'>
                <h4 className='is-4 subtitle'>Chat with a real wedding planner.</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor.</p>
              </div>
            </div>

            <div className='block block--right'>
              <div className='block__text'>
                <h4 className='is-4 subtitle'>Keep track of contracts.</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  sed do eiusmod tempor.</p>
              </div>
              <div className='block__img'>
                <div style={this.blockImage('/images/home/laptop-dashboard.png')}></div>
              </div>
            </div>
          </div>

        </div>
        <Footer />
      </div>
    )
  }
}

Home.propTypes = {
  token: PropTypes.string.isRequired
}
