import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Loading } from '../../../forms/Helpers';

export default class ListForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newListName: ''
    }
  }

  handleNameChange = (e) => {
    this.setState({ newListName: e.target.value })
  }

  clearAndCloseForm = () => {
    this.setState({ newListName: '' }, () => {
      this.props.toggleNewListForm();
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isSubmitting: true }, () => {
      axios.post('/api/v1/lists', {
        list: {
          name: this.state.newListName
        },
        authenticity_token: this.props.token
      })
      .then((result) => {
        this.setState({ isSubmitting: false, newListName: '' }, () => {
          this.props.addNewListToExisting(result.data.list)
        })
      })
      .catch((error) => {
        this.setState({ isSubmitting: false }, () => {
          alert('There was an error.')
        })
      })
    })
  }

  render() {
    if (this.state.isSubmitting) {
      return <Loading />
    } else {
      return (
        <div>
          <i
            className='icon icon-close clickable'
            style={{float: 'right'}}
            onClick={this.clearAndCloseForm}></i>
          <h4 className='title is-4 has-text-centered'>Add a new list</h4>
          <form onSubmit={this.handleSubmit}>
            <label className='label'>Name</label>
            <input className='input' type='text' onChange={this.handleNameChange}></input>
            <div className='mt-one-em'>
              <button
                className='button button-primary'
                type="submit"
                disabled={this.state.newListName.length < 1}>Submit</button>
              <button
                className='button'
                onClick={this.clearAndCloseForm}
                type="submit">Cancel
              </button>
            </div>
          </form>
        </div>
      )
    }
  }
}

ListForm.propTypes = {
  toggleNewListForm: PropTypes.func.isRequired
}
