import React from 'react';
import PropTypes from 'prop-types';

export default class VendorsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendorSearchTerm: '',
      vendorFilterSelection: '',
      filteredVendors: []
    }
  }

  sortByCompanyName = () => {
    return function(a,b) {
      if (a.company_name.toLowerCase() > b.company_name.toLowerCase()) { return 1 }
      else if (a.company_name.toLowerCase() < b.company_name.toLowerCase()) { return -1 }
      else { return 0 }
    }
  }

  vendorType = (vendor) => {
    if (vendor.contracts.length > 0) {
      let types = vendor.contracts[0].vendor_types;
      if (types.length === 1) {
        return (
          <span
            onClick={() => this.props.selectVendor(vendor)}
            className='vendors-list__category clickable'>Category: {types[0]['vendor_type']}</span>

        )
      } else {
        return (
          <span
            onClick={() => this.props.selectVendor(vendor)}
            className='vendors-list__category clickable'>Category: {types[0]['vendor_type']} + { types.length - 1} other</span>
        )
      }
    } else if (vendor.is_venue === true) {
      return (
        <span
          onClick={() => this.props.selectVendor(vendor)}
          style={{textTransform: 'capitalize'}}
          className='vendors-list__category clickable'
        >
          Venue Type: {vendor.venue_type || 'None'}
        </span>
      )
    }
  }

  vendorItem = (v) => {
    let { activeVendor } = this.props;
    return (
      <div key={v.id} className={'vendors-list__item ' + (activeVendor && activeVendor.id === v.id ? 'vendors-list__item--active' : '')}>
        <div className='vendors-list__left'>
          <p
            className='clickable has-text-weight-bold'
            onClick={() => this.props.selectVendor(v)}>
            {v.company_name}
          </p>
          { this.vendorType(v) }
          {this.contactsWithNumbers(v)}
        </div>
        <div className='vendors-list__right'>
          { this.openVendorChat(v)}
        </div>

      </div>
    )
  }

  setContactListOpenId = (vendorId) => {
    if (this.state.contactListOpenId === vendorId) {
      this.setState({ contactListOpenId: null })
    } else {
      this.setState({ contactListOpenId: vendorId })
    }
  }

  openVendorChat = (vendor) => {
    return (
      <div
        // onClick={() => this.props.setActiveVendorAndTab(vendor, 'Chat')}
        onClick={() => {vendor.contacts[0] ? window.location.href = `mailto:${vendor.contacts[0].email}` : false}}
      >
        <span
          data-tooltip={`Open email with ${vendor.company_name}.`}
          className="has-tooltip-top"
          style={{bottom:0}}
        >
          <i className="icon icon-envelope"></i>
        </span>
      </div>
    )
  }

  contactsWithNumbers = (vendor) => {
    let { user } = this.props;
    return vendor.contacts.map((c) => {
      return (
        <div className='vendors-list__contact' key={c.id}>
          <div className=' has-text-weight-light' onClick={() => this.props.selectVendor(vendor)}>
            <span className='clickable'>{c.first_name} {c.last_name}</span>
            <span className='clickable'>{c.phone_number}</span>
          </div>
        </div>
      )
    })
  }

  sortedVendors = (collection) => {
    let filtered;
    if (this.props.isVenue === true) {
      filtered = collection.filter(i => i.is_venue === true)
    }
    else {
      filtered = collection.filter(i => i.is_venue != true)
    }
    return filtered.sort(this.sortByCompanyName()).map((v) => {
      return this.vendorItem(v)
    })
  }

  vendorList = () => {
    let { filteredVendors, vendorSearchTerm } = this.state;
    if (filteredVendors.length > 0) {
      return this.sortedVendors(filteredVendors);
    } else if (vendorSearchTerm.length > 0) {
      if (this.guidedBrideInSearch() === false) {
        return (
          <p>No results for {vendorSearchTerm}. Please adjust your search criteria.</p>
        )
      } else { return null }
    } else {
      return this.sortedVendors(this.props.vendors);
    }
  }

  venuesWorkSheet = () => {
    if (this.props.isVenue === true) {
      return (
        <a className='worksheet-download mt-one-em mb-one-em' target='_blank' href='/downloads/venue-worksheet.pdf'>
          <i className='icon icon-file'></i>
          <span>Use the Guided Bride Venue Worksheet when shopping for venues.</span>
        </a>
      )
    }
  }

  vendorSearch = () => {
    if (this.hasItems(this.props.vendors)) {
      return (
        <div className='control has-icons-left is-expanded vendor-search'>
          <input
            className='input'
            type='text'
            value={this.state.vendorSearchTerm}
            onChange={this.handleVendorSearch}></input>
            <span className="icon is-left">
              <i className='icon icon-search'></i>
            </span>
        </div>
      )
    }
  }

  vendorFilterByType = () => {
    if (this.props.vendors.length > 0) {
      let options = [...new Set(this.props.vendors.map(v => v.vendor_type))].sort();

      return (
        <div className='control'>
          <label className='label'>Filter by Type </label>
          <select
            className='select'
            onChange={this.handleVendorFilter}
            value={this.state.vendorFilterSelection}>
              <option value=''></option>
              { options.map(opt =>
                  <option
                    key={opt}
                    value={opt}>{opt}
                  </option>
              )}
          </select>
        </div>
      )
    }
  }

  handleVendorFilter = (e) => {
    let filterTerm = e.target.value;
    this.setState({ vendorFilterSelection: filterTerm, vendorSearchTerm: '' }, () => {
      let filteredVendors;
      if (filterTerm === '') {
        filteredVendors = [];
      } else {
        filteredVendors = this.props.vendors.filter(v => v.vendor_type === filterTerm);
      }
      this.setState({ filteredVendors })
    })
  }

  handleVendorSearch = (e) => {
    let term = e.target.value;
    this.setState({ vendorSearchTerm: term, vendorFilterSelection: '' }, () => {
      if (term.length > 0) {
        let vendors = this.props.vendors.filter(v =>
          v.company_name.toLowerCase().includes(term.toLowerCase()) === true
        )
        this.setState({ filteredVendors: vendors })
      } else {
        this.setState({ filteredVendors: [] })
      }
    })
  }

  guidedBrideInSearch = () => {
    let { vendorSearchTerm } = this.state;
    return 'guided bride'.includes(vendorSearchTerm.toLowerCase())
  }

  guidedBrideDefaultVendor = () => {
    let { vendorSearchTerm } = this.state;
    if (this.props.isVenue != true && (vendorSearchTerm === '' || this.guidedBrideInSearch())) {
      return (
        <div className={'vendors-list__item ' + (this.props.guidedBrideVendorActive ? 'vendors-list__item--active' : '') }>
          <div
            className='vendors-list__left'
            onClick={this.props.showGuidedBrideVendor}
          >
            <p
              className='clickable has-text-weight-bold'>
              Guided Bride
            </p>
            <span
              className='vendors-list__category clickable'>Category: Wedding Planner</span>
          </div>
          <div
            className='vendors-list__right'
            // onClick={this.props.showGuidedBrideVendor}
            onClick={() => {window.location.href = "mailto:guidedbride@gmail.com"}}
          >
            <div>
              <span
                data-tooltip='Open email with Guided Bride.'
                className="has-tooltip-top"
                style={{bottom:0}}
              >
                <i className="icon icon-envelope" />
              </span>
            </div>
          </div>

        </div>
      )
    }
  }

  hasItems = (vendors) => {
    let { isVenue } = this.props;
    if (isVenue && vendors.filter(v => v.is_venue === true).length > 0) {
      return true;
    } else if (!isVenue && vendors.filter(v => v.is_venue != true).length > 0) {
      return true;
    } else { return false; }
  }

  render() {
    return (
      <div className='vendors-list'>
        <div className='field is-grouped vendors-list__search'>
          { this.vendorSearch() }
          {/* LEAVE THIS OUT FOR NOW! this.vendorFilterByType() */ }
        </div>

        {/* { this.venuesWorkSheet() } */}

        {/* { this.guidedBrideDefaultVendor() } */}
        { this.vendorList() }
      </div>
    )

  }
}

VendorsList.propTypes = {
  vendors: PropTypes.array.isRequired,
  selectVendor: PropTypes.func.isRequired,
  setActiveVendorAndTab: PropTypes.func.isRequired,
  showGuidedBrideVendor: PropTypes.func
}
