import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { FormTextInput, Loading } from '../../forms/Helpers';
import { FormResponse, handleResponse, errorsFormatter } from '../../forms/Response';
import Header from '../layout/Header';

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      isMuted: true,
      notEnded: true,
    }
  }

  componentWillUnmount() {
    if (this.formResponse) {
      clearTimeout(this.formResponse);
    }
  }

  videos = [
    {
      src: '/video/Guided-Bride-Reception-Slider.webm',
      type: 'video/webm',
    },
    {
      src: '/video/Guided-Bride-Reception-Slider.mp4',
      type: 'video/mp4',
    },
  ];

  sectionWeddingToolkitColumns = [
    {title:'ORGANIZE', text:"Quotes\nContracts\nPayments\nVIP’s"},
    {title:'MANAGE', text:"Budget\nShopping Lists\nDue Dates\nPasswords"},
    {title:'PLAN', text:"Intuitive tools to keep you\nOn track and in Budget"}
  ]

  sectionWhatPeopleAreSaying = [
    'Most amazing wedding planning tool ever!',
    'Every bride should be a Guided Bride!',
    'I wish I had this when I planned my wedding!',
    'Guided Bride was the best decision I made to plan my wedding. My mom loved it too!'
  ]

  createAccount = (values) => {
    this.setState({ isSubmitting: true }, () => {
      const data = {
        user: {
          email: values.email,
          password: values.password,
          password_confirmation: values.passwordConfirmation
        }
      }

      const defaultHeaders = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.props.token,
        'Accept': 'application/json'
      }

      axios.post('/users', data,
        { headers: defaultHeaders }
      )
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        let message = errorsFormatter(error.response.data.errors) || 'There was a problem creating your account.'
        handleResponse(this, message, error.response.status, 4000)
      })
    })
  }

  getStartedForm = () => {
    return (
      <Formik
        initialValues={{
          email: '',
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .required('Please enter your email address')
            .email('Please enter a valid email address'),
          password: Yup.string()
            .required('Please enter a password')
            .min(8, 'Should be 8 chars minimum')
            .max(128, 'Should be 128 chars maximum'),
          passwordConfirmation: Yup.string()
            .required('Please confirm password')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
        })}
        onSubmit={(values) => {
          this.createAccount(values)
        }}
      >
        <Form autoComplete={'off'} >
            <FormTextInput
              name="email"
              type="email"
              required
              placeholder="Email"
            />
            <FormTextInput
              name="password"
              type="password"
              required
              placeholder="Password"
            />
            <FormTextInput
              name="passwordConfirmation"
              type="password"
              required
              placeholder="Password confirmation"
            />
            <button type={'submit'}>GET STARTED</button>
            <p className="social-networks-oauth-p">
              <span>Sign up with </span>
              <a className="oauth-button oauth-button--google link-without-styling"
                href="/users/auth/google_oauth2"
                data-method="POST"
                rel="nofollow"
              >
                Google
              </a>
              <span> or </span>
              <a className="oauth-button oauth-button--facebook link-without-styling"
                href="/users/auth/facebook"
                data-method="POST"
                rel="nofollow"
              >
                Facebook
              </a>
            </p>
        </Form>
      </Formik>
    )
  }

  render() {
    const { responseMessage, responseCode, isSubmitting, isMuted, notEnded } = this.state;
    
    return (
      <>
      <header >
        <Header/>
          <ReactPlayer
            className={'home__logo'}
            url={this.videos}
            width={'auto'}
            height={'auto'}
            playing={true}
            preload={'auto'}
            muted={isMuted}
            autoPlay
            playsinline
            onEnded={() => this.setState({notEnded:false})}
          />
          { notEnded &&
            <div className={'sound'} onClick={() => this.setState({isMuted: !isMuted})}>
              <div className={isMuted ? 'mute' : 'un-mute'}/>
            </div>
          }
      </header>
      <main>
        <article className={'home__content'}>
          <section className={'home__section__wedding-toolkit'}>
            <h1 className={'home__title'}>
              &#xE03D;our complete wedding toolki&#xE053;
            </h1>

            <div className={'columns'}>
            {this.sectionWeddingToolkitColumns.map((column,i) => {
              return (
                <div key={i} className={'column'}>
                  <h4>{column.title}</h4>
                  <p>{column.text}</p>
                </div>
              )})
            }
            </div>
            <div className={'column get-started'}>
              <div className={'get-started__columns'}>
                <p>Try it free today!</p>
                { FormResponse(responseMessage, responseCode) }

                { isSubmitting &&
                  <Loading />
                }
                { !isSubmitting &&
                  this.getStartedForm()
                }
              </div>
              <div className={'get-started__columns'}/>
            </div>
          </section>
          <section className={'home__section__what-people-are-saying'}>
            <h3 className={'home__title'}>&#xE03B;hat people are sayin&#xE046;</h3>
            <blockquote>
              {
                this.sectionWhatPeopleAreSaying.map((comment,i) => {
                  return (
                    <p key={i}>&#8220;{comment}&#8221;</p>
                  )
                })
              }
            </blockquote>
          </section>
          <section className={'home__section__planning-on-all-your-devices'}>
            <h3 className={'home__title'}>&#xE034;lanning on all your device&#xE052;</h3>
            <div className={'devices'} />
            <div className={'column get-started'}>
              <div className={'get-started__columns'}>
                <p>Get instant access!</p>
                { FormResponse(responseMessage, responseCode) }

                { isSubmitting &&
                  <Loading />
                }
                { !isSubmitting &&
                  this.getStartedForm()
                }
              </div>
              <div className={'get-started__columns'}/>
            </div>
          </section>
        </article>
      </main>
      <footer>
        <div className={'footer__social'}>
          <a href="https://www.facebook.com/guidedbride" target="_blank">
          <i className="icon icon-facebook"></i>
          </a>
          <a href="https://www.instagram.com/guidedbride" target="_blank">
            <i className="icon icon-instagram"></i>
          </a>
          <a href="https://www.pinterest.com/guidedbride" target="_blank">
            <i className="icon icon-pinterest"></i>
          </a>
        </div>
        <div className='footer__links'>
          <a href="mailto:hello@guidedbride.com">Contact</a>
          <a href='/faqs'>FAQs</a>
          <a href='/terms_of_service' target="_blank">Terms of Service</a>
          <a href='/privacy_policy' target="_blank">Privacy Policy</a>
        </div>
      </footer>
      </>
    )
  }
}

Home.propTypes = {
  token: PropTypes.string.isRequired
}
