import React from 'react';
import PropTypes from 'prop-types';
import TaskCard from './TaskCard';
import { tasksByDate } from './TaskFunctions';

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
  'August', 'September', 'October', 'November', 'December']

export default class TaskList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.activeItemRef = React.createRef();
  }

  componentDidMount() {
    this.jumpToActiveTask(300);
  }

  jumpToActiveTask (ms) {
    if (this.props.activeTask && this.activeItemRef && this.activeItemRef.current) {
      setTimeout(() => this.activeItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' }), ms) 
    }
  }

  cardsByMonth = (monthsWithTasks) => {
    return Object.keys(monthsWithTasks).map((k) => {
      return (
        <div className="task-month" key={k}>
          <span className="task-month__heading">
            { MONTHS[parseInt(k.split('-')[1]) - 1]}
          </span>
          { this.cards(monthsWithTasks[k]) }
        </div>
      )
    })
  }

  cards = (tasks) => {
    let { activeTask } = this.props;
    return tasks.map((task, index) => {
      return (
        <div
          ref={activeTask && activeTask.id === task.id ? this.activeItemRef : null}
          className={
            'task-strip'
            + (activeTask && activeTask.id === task.id ? '' : ' task-strip--inactive')
            + (task.status === 2 ? ' task-status__complete' : '')
          }
          key={`${index}-${task.id}`}>
            <TaskCard
              key={task.id}
              task={task}
              makeActive={(activeTask) => {
                this.props.makeActive(activeTask);
                this.jumpToActiveTask(0);
              }}
              tagFilter={this.props.tagFilter}
              isActive={activeTask && activeTask.id === task.id} />
        </div>
      )
    })
  }

  tasksFilters = () => {
    let { taskStatuses, activeFilter } = this.props;
    return Object.keys(taskStatuses).map((k) => {
      return (
        <button
          key={k}
          className={'button ' + (activeFilter === k ? 'button--active' : '')}
          onClick={() => this.props.filterByStatus(k)}>
          {k.split('_').join(' ')}
        </button>
      )
    })
  }

  taskFilterSelectOptions = () => {
    let { taskStatuses } = this.props;
    return Object.keys(taskStatuses).map((k) => {
      return (
        <option key={k} value={k}>{k.split('_').join(' ')}</option>
      )
    })
  }

  filterByTag = () => {
    let { tagFilter } = this.props;
    if (tagFilter) {
      return (
        <div className='tag-filter'>
          <p>Filtering tasks with <span className='has-text-weight-bold'>{tagFilter}</span> tag.</p>
          <button
            className='button button-small'
            onClick={this.props.resetTasks}>X remove filter</button>
        </div>
      )
    }
  }

  tasksList = () => {
    const { taskStatuses, activeFilter } = this.props;
    const tasks = ['all', 'due_today'].includes(activeFilter) ?
      this.props.tasks.filter(t => {
        return ![taskStatuses['complete'], taskStatuses['n/a']].includes(t.status);
      }) : this.props.tasks;
    if (tasks.length === 0) {
      return (<div className='task-card'><p>No tasks!</p></div>)
    } else {
      const sorted = tasksByDate(tasks);
      const monthsWithTasks = {}
      sorted.map(t => {
        const yearAndMonth = t.due_date.split("-").slice(0,2).join("-");
        if (monthsWithTasks[yearAndMonth]) {
          monthsWithTasks[yearAndMonth].push(t)
        } else {
          monthsWithTasks[yearAndMonth] = [].concat(t)
        }
      })
      return this.cardsByMonth(monthsWithTasks);
    }
  }

  completedAndNaTasksList = () => {
    const { taskStatuses } = this.props;
    const tasks = this.props.tasks.filter(t => {
      return [taskStatuses['complete'], taskStatuses['n/a']].includes(t.status);
    })
    if (tasks.length > 0) {
      const sorted = tasksByDate(tasks);
      const monthsWithTasks = {}
      sorted.map(t => {
        const yearAndMonth = t.due_date.split("-").slice(0,2).join("-");
        if (monthsWithTasks[yearAndMonth]) {
          monthsWithTasks[yearAndMonth].push(t)
        } else {
          monthsWithTasks[yearAndMonth] = [].concat(t)
        }
      })
      return (
        <>
          <p className='title'>completed and N/A tasks</p>
          { this.cardsByMonth(monthsWithTasks) }
        </>
      )
    }
  }

  render() {
    const activeFilter = this.props.activeFilter;
    return (
      <div>
        <div className='task-filter-select'>
          <div>
            <span>Filter: </span>
            <select value={activeFilter} onChange={(e) => this.props.filterByStatus(e.target.value)}>
              <option value='all'>Show All</option>
              <option value='due_today'>Due Today</option>
              <option value='overdue'>Overdue</option>
              { this.taskFilterSelectOptions() }
            </select>
          </div>
        </div>

        { this.filterByTag() }
        <div className='task-list'>
          { this.tasksList() }
          { ['all', 'due_today'].includes(activeFilter) &&
            <div className='completed-and-na-task-list'>
              { this.completedAndNaTasksList() }
            </div>
          }
        </div>
      </div>
    )
  }
}

TaskList.propTypes = {
  tasks: PropTypes.array.isRequired,
  activeFilter: PropTypes.string.isRequired,
  taskStatuses: PropTypes.object.isRequired,
  activeTask: PropTypes.object,
  filterByStatus: PropTypes.func.isRequired,
  tagFilter: PropTypes.string,
  resetTasks: PropTypes.func.isRequired
}
