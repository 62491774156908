import React from 'react';
import PropTypes from 'prop-types';
import QuoteForm from './QuoteForm';
import axios from 'axios';
import { handleResponse, FormResponse, errorsFormatter } from '../../../forms/Response';
import { formatMoney } from '../../../HelperFunctions';
import GuideModal from '../GuideModal';
import CheckoutModal from './../../../adyen/CheckoutModal';

export default class QuotesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.sectionName = {
      'Venue Quotes':'Venue',
      'Vendor Quotes':'Vendor',
    }
    this.state = {
      quotes: this.props.quotes,
      modalIsOpen: false
    }
  }

  handleNewQuote = (quote) => {
    let quotes = [...this.state.quotes];
    quotes.push(quote);
    this.setState({ quotes, activeQuote: quote, showNewQuoteForm: false })
  }

  handleUpdatedQuote = (quote) => {
    let quotes = [...this.state.quotes];
    let quoteToReplace = quotes.filter(q => q.id === quote.id)[0];
    if (quoteToReplace) {
      quotes[quotes.indexOf(quoteToReplace)] = quote;
      this.setState({ quotes, activeQuote: quote, showNewQuoteForm: false })
    }
  }

  handleVenusWorkSheet = () => {
    if (this.props.user.has_active_subscription) {
      window.open('/downloads/venue-worksheet.pdf','_blank')
    } else {
      this.setState({ modalIsOpen: true })
    }
  }

  currentDashboardLeftClass = () => {
    if (this.state.activeQuote || this.state.showNewQuoteForm) {
      return 'dashboard__left--collapsed';
    } else { return 'dashboard__left--full'; }
  }

  currentDashboardRightClass = () => {
    if (this.state.activeQuote || this.state.showNewQuoteForm) {
      return 'dashboard__right--full';
    } else { return 'dashboard__right--collapsed'; }
  }

  vendorTypeOptions = () => {
    let { quotes } = this.state;
    let filtered = quotes.filter(q => q.vendor_type_id);
    let unique = [...new Set(filtered)];

    return filtered.map(f => {
      return (
        <option key={f.id} value={f.vendor_type_id}>
          {this.props.vendorTypes.filter(t => t[0] === f.vendor_type_id)[0][1]}
        </option>
      )
    })
  }

  quotesList = () => {
    return (
      <div className={'dashboard__left ' + (this.currentDashboardLeftClass())}>
        <div className='dashboard__container'>
          <div className='header-with-button'>
            <div className='header-with-swatch header-with-swatch--left-justified'>
                <h2 className='title dashboard__header'>{`${this.sectionName[this.props.page]} Quotes`}</h2>
            </div>

            { !this.state.showNewQuoteForm &&
              <button
                onClick={() => this.setState({ showNewQuoteForm: true, activeQuote: null })}
                className='button button-primary'>Add New</button>
            }
          </div>

          <div className='vendors-list vendors-list--flex'>
            { this.sectionName[this.props.page] === 'Venue' ? this.venuesWorkSheet() : ''}
            { this.state.quotes.length > 0 && this.props.page === 'Vendor Quotes' &&
              <div className='quotes__filter'>
                <h5 className='is-5'>Filter By Vendor Type</h5>
                <select onChange={this.filterQuotes}>
                  <option value=''>All</option>
                  { this.vendorTypeOptions() }
                </select>
              </div>
            }

            { this.allQuotes() }
          </div>
        </div>
      </div>
    )
  }

  venuesWorkSheet = () => {
    return (
      <>
        <button
          onClick={() => this.handleVenusWorkSheet()} 
          className='worksheet-download mt-one-em mb-one-em' >
            <i className='icon icon-file'></i>
            <span>Use the Guided Bride Venue Worksheet when shopping for venues.</span>
        </button>
        <CheckoutModal
          token={this.props.token}
          user={this.props.user}
          clientKey={this.props.clientKey}
          stripePublishableKey={this.props.stripePublishableKey}
          modalIsOpen={this.state.modalIsOpen}
          closeModal={() => this.setState({ modalIsOpen: false })} 
        />
      </>
    )
  }

  filterQuotes = (e) => {
    if (e.target.value === '') {
      this.setState({ filteredQuotes: null, activeQuote: null })
    } else {
      let $this = this;
      let filteredQuotes = this.state.quotes.filter(q => q.vendor_type_id === parseInt(e.target.value))
      this.setState({ filteredQuotes, activeQuote: null })
    }
  }

  vendorTypeName = (vendorId) => {
    let vt = this.props.vendorTypes.filter(t => t[0] === vendorId)[0];
    return vt ? vt[1] : 'None';
  }

  quotesWithActiveQuoteRemoved = () => {
    let quotes = [...this.state.quotes];
    quotes.splice(quotes.indexOf(this.state.activeQuote), 1);
    return quotes;
  }

  handleDeletedQuote = () => {
    let { activeQuote } = this.state;
    if (confirm('Are you sure you want to delete this quote?')) {
      this.setState({ isSubmitting: true }, () => {
        axios.delete(`/api/v1/quotes/${activeQuote.id}`, {
          headers: {
            'X-CSRF-Token': this.props.token,
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          let quotes = this.quotesWithActiveQuoteRemoved()
          this.setState({ isSubmitting: false, activeQuote: null, quotes, showNewQuoteForm: false })
        })
        .catch((error) => {
          this.setState({ isSubmitting: false }, () => {
            handleResponse(this, 'There was a problem deleting this quote.', error.response.status, 4000)
          })
        })
      })
    }
  }

  allQuotes = () => {
    const { page } = this.props;
    let quotes = this.state.quotes || [];
    if (this.state.filteredQuotes) {
      quotes = this.state.filteredQuotes;
    }

    console.log(this.props)
    return quotes.map(q => {
      return (
        <div
          className={'vendors-list__item ' + (this.state.activeQuote === q ? 'vendors-list__item--active' : '')}
          key={q.id}
          onClick={() => this.setState({ activeQuote: q, showNewQuoteForm: false })}>
          <div className='vendors-list__left quote-card' style={{width: '100%', marginRight: '0', display: 'block'}}>
            <div className='quote-card__left'>
              <p className='clickable has-text-weight-bold'>
                {q.company_name}
                <span style={{float: 'right'}}>Quote {formatMoney(q.amount)}</span>
              </p>
              {
                page === 'Venue Quotes' ? <>
                  <span className='vendors-list__category clickable venue_quotes__category'>Note: {
                    this.sectionName[page] === 'Venue'
                      ? q.note
                      : this.vendorTypeName(q.vendor_type_id)
                  }
                  </span>
                </> : <>
                  <span className='vendors-list__category clickable'>{this.sectionName[page]} Type: {
                  this.sectionName[page] === 'Venue'
                  ? this.props.quotes?.venue_type || 'None'
                  : this.vendorTypeName(q.vendor_type_id)
                  }
                </span>
              </>
              }
            </div>
            {/* <div className='quote-card__right'>
              <span className='vendors-list__category'>Quote {formatMoney(q.amount)}</span>
            </div> */}
          </div>
        </div>
      )
    })
  }

  individualQuote = () => {
    return (
      <div className={'dashboard__right ' + (this.currentDashboardRightClass())}>
        <div className='dashboard__container tasks-down'>
          <div className='active-card'>
            <div className='vendors__detail'>
              <>
                { this.activeCard() }
              </>
            </div>
          </div>
        </div>
      </div>
    )
  }

  exitButton = () => {
    return (
      <i
        onClick={() => this.setState({ activeQuote: null, showNewQuoteForm: false })}
        className='icon icon-arrow-left sm-only clickable'></i>
    )
  }

  convertToBookedVendor = () => {
    this.setState({ isSubmitting: true }, () => {
      axios.post('/api/v1/convert_to_vendor', {
        quote: {
          id: this.state.activeQuote.id,
          user_id: this.state.activeQuote.user_id
        },
        authenticity_token: this.props.token
      })
      .then((response) => {
        let quotes = this.quotesWithActiveQuoteRemoved();
        this.setState({
          isSubmitting: false, showModal: true, activeQuote: null,
          quotes, newVendorId: response.data.vendor.id, newVendorName: response.data.vendor.company_name
        })
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isSubmitting: false }, () => {
          handleResponse(this, 'There was a problem converting this quote.', 422, 4000)
        })
      })
    })
  }

  activeCard = () => {
    const { page } = this.props;
    if (this.state.showNewQuoteForm) {
      return (
        <>
          <QuoteForm
            page={this.props.page}
            token={this.props.token}
            user={this.props.user}
            handleNewQuote={this.handleNewQuote}
            handleUpdatedQuote={this.handleUpdatedQuote}
            quote={this.state.activeQuote}
            closeForm={() => this.setState({ showNewQuoteForm: false })}
            vendorTypes={this.props.vendorTypes}
            vendorCategories={this.props.vendorCategories}
          />
      </>

      )
    } else if (this.state.activeQuote) {
      let { activeQuote } = this.state;
      let { responseMessage, responseCode } = this.state;
      const { page } = this.props;
      return (
        <>
          { this.exitButton() }
          <h4 className='title is-4 is-spaced has-text-centered'>{activeQuote.company_name}</h4>
          { FormResponse(responseMessage, responseCode) }

          <div className='vendor-contact'>
            {page !== 'Venue Quotes' && <>
              <label>{this.sectionName[page]} Type</label>
              <h5 className='is-5 subtitle'>{
                this.sectionName[page] === 'Venue'
                  ? this.props.quotes?.venue_type || 'None'
                  : this.vendorTypeName(activeQuote.vendor_type_id)
                  }
              </h5></>
            }
            <label>Quote Amount</label>
            <h5 className='is-5 subtitle'>{formatMoney(activeQuote.amount)}</h5>
            <label>Note</label>
            <h5 className='is-5 subtitle'>{ activeQuote.note || 'None' }</h5>

            <button
              className='button button-small mt-one-em mb-one-em'
              onClick={() => this.setState({ showNewQuoteForm: true })}>
              edit quote
            </button>
            <div>
              <button
                onClick={this.convertToBookedVendor}
                className='button button-primary button-mb'
              >
                I've booked this {this.sectionName[page]}
              </button>
              <button
                className='button button-delete button-mb'
                onClick={this.handleDeletedQuote}
              >
                delete
              </button>
            </div>
          </div>
        </>
      )
    } else if (this.props.quotes.length > 0) {
      return (<h4 className='title is-4'>Select a quote for more information</h4>)
    } else {
      return (<h4 className='title is-4'>Add a quote to get started</h4>)
    }
  }

  render() {
    const { page } = this.props;
    const sectionName = `${this.sectionName[page]}s`.toLowerCase() // /vendors?id or /venues?id
    let guideOptions = [
      { link: `/${sectionName}?id=${this.state.newVendorId}&tab=contracts&action=create`, title: `Add contract details to ${this.state.newVendorName}`},
      { link: `/${sectionName}?id=${this.state.newVendorId}`, title: `View ${this.state.newVendorName}`},
      { link: '/tasks', title: 'See my tasks'},
      { link: '/', title: 'Go to dashboard' }
    ]
    return (
      <div className='standard-padding dashboard__inner'>
        { this.quotesList() }
        { this.individualQuote() }
        { this.state.showModal &&
          <GuideModal
            options={guideOptions}
            preText={'This quote has been converted to a booked vendor!'}
            closeModal={() => this.setState({ showModal: false })} />
        }

      </div>
    )
  }
}

QuotesContainer.propTypes = {
  quotes: PropTypes.array
}
