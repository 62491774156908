export const modalStyle = (width, modalBackground) => {
  return {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: `${ width > 992 ? '3rem 4rem' : '2rem 2.5rem'}`,
      background: `${modalBackground}`,
      border: 'none',
      width: `${ width > 992 ? '70%' : width > 776 ? '80%' : '90%'}`,
      minHeight: '50%',
      maxHeight: '95%',
    }
  }
}
