import React from 'react';
import PropTypes from 'prop-types';
import Countdown from './Countdown';
import TaskOverview from './TaskOverview';
import QuickLinks from './QuickLinks';
import Modal from 'react-modal';
import { modalStyle } from '../ModalStyle';
import ActiveTask from '../tasks/ActiveTask';
import ActiveLinks from '../vendors/active_vendor/contracts/ActiveLinks';
import BudgetOverview from './BudgetOverview';
import RelatedTaskModal from '../tasks/RelatedTaskModal';
import NonContractPaymentForm from '../payments/NonContractPaymentForm';
import CheckoutModal from '../../../adyen/CheckoutModal';
import * as UserActions from '../actions';

export default class UserOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      modalIsOpen: false,
      modalContent: null,
      showDateForm: false,
      showDescriptionForm: false,
      width: window.innerWidth,
      taskClass: null,      
    }
  }

  componentDidMount() {
    if (typeof(window) !== 'undefined') {
      Modal.setAppElement('body')
    }
    if (window.location.href.includes('payment_error=')) {
      let error = window.location.href.split('payment_error=')[1];
      this.setState({ modalIsOpen: true, modalContent: 'upgrade', redirectPaymentError: decodeURIComponent(error).replace(/\+/g, ' ') })
      setTimeout(() => this.setState({ redirectPaymentError: null }), 4000);
    }
    window.addEventListener('resize', this.updateWidth);
  }

  updateWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  modalBackground = () => {
    let { taskClass } = this.state;
    if (!taskClass) {
      return '#F3F1EE';
    } else if (taskClass.includes('overdue')) {
      return '#a1adb5';
    } else if (taskClass.includes('today')) {
      return '#384245';
    } else {
      return '#F3F1EE';
    }
  }

  userCopy = () => {
    return Object.assign({}, this.state.user);
  }

  handleNewLink = (link) => {
    let user = this.userCopy();
    user.quick_links.unshift(link);
    this.setState({ user })
  }

  handleDeletedLink = (link) => {
    let user = this.userCopy();
    user.quick_links.splice(user.quick_links.indexOf(link), 1);
    this.setState({ user })
  }

  handleUpdatedLink = (link) => {
    let user = this.userCopy();
    let linkToUpdate = user.quick_links.filter(l => l.id === link.id)[0]
    if (linkToUpdate) {
      user.quick_links[user.quick_links.indexOf(linkToUpdate)] = link;
      this.setState({ user });
    }
  }

  weddingHeader(user) {
    return `${user.first_name} and ${user.partner_first_name}'s Wedding`;
  }

  addCeremonyLocationToUser = (location) => {
    let user = this.userCopy();
    user.locations.push(location);
    this.setState({ user })
  }

  openTask = (activeTask, taskClass) => {
    this.setState({ modalIsOpen: true, modalContent: 'task', activeTask, taskClass })
  }

  openContractLink = (activeContractLink) => {
    this.setState({ modalIsOpen: true, modalContent: 'contractLinks', activeContractLink })
  }

  handleFieldUpdate = (field, val, submit = false) => {
    let activeTask = Object.assign({}, this.state.activeTask);
    activeTask[`${field}`] = val;
    if (submit === true) {
      const isShowingRelatedTaskModal = (field === 'status' && val === 'complete' && activeTask.tags.length)
      this.setState({ activeTask }, () => this.handleUpdateSubmit(isShowingRelatedTaskModal));
    } else {
      this.setState({ activeTask })
    }
  }

  handleUpdateSubmit = (isShowingRelatedTaskModal) => {
    UserActions.updateUserTask(this.state.activeTask, this.props.token)
      .then((response) => {
        let user = this.userCopy();
        let item = user.tasks.filter((t) => t.id === response.data.item.id)[0];
        user.tasks[user.tasks.indexOf(item)] = response.data.item;

        isShowingRelatedTaskModal
        ? this.setState({ activeTask: response.data.item, user, showDateForm: false, showDescriptionForm: false, modalContent:'relatedTaskModal', taskClass: null })
        : this.setState({ activeTask: response.data.item, user, showDateForm: false, showDescriptionForm: false })
      })
      .catch((error) => {
        this.setState({ submitError: true }, () => {
          setTimeout(() => this.setState({ submitError: null }), 2000);
        })
      })
  }

  toggleForm = (formName, isPaywalled = false) => {
    if (isPaywalled === true && this.props.user.has_active_subscription === false) {
      this.setState({ modalIsOpen: true, modalContent: 'upgrade', taskClass: null })
    } else {
      this.setState({ [`show${formName}`]: !this.state[`show${formName}`]})
    }
  }

  deleteExpense = () => {
    let user = Object.assign({}, this.props.user);
    user.non_contract_payments.splice(user.non_contract_payments.indexOf(this.state.item), 1);
    this.setState({ user }, () => { this.toggleModal() })
  }

  modalContent = () => {
    const { modalIsOpen, modalContent, activeTask } = this.state;
    if (modalIsOpen) {
      if (modalContent === 'task') {
        return (
          <ActiveTask
            user={this.props.user}
            token={this.props.token}
            toggleForm={this.toggleForm}
            task={activeTask}
            tagDisabled={true}
            submitError={this.state.submitError}
            showTasksLink={true}
            showDescriptionForm={this.state.showDescriptionForm}
            showDateForm={this.state.showDateForm}
            removeActiveTask={this.toggleModal}
            handleUpdateSubmit={this.handleUpdateSubmit}
            handleFieldUpdate={this.handleFieldUpdate}
          />
        )
      } else if (modalContent === 'relatedTaskModal') {
          return (
            <RelatedTaskModal
              tasks={this.props.user.tasks}
              activeTask={activeTask}
              token={this.props.token}
              handleUpdateSubmit={this.handleUpdateSubmit}
              closeModal={this.toggleModal}
              isModalOpen={true}
              isOnTasks={false}
            />
          )
      } else if (modalContent === 'expense') {
        if (this.props.user.has_active_subscription != true && this.state.dollarsPrice !== null) {
          return (
            <CheckoutModal
              modalIsOpen={true}
              closeModal={this.toggleModal}
              user={this.props.user}
              token={this.props.token}
              clientKey={this.props.clientKey}
              stripePublishableKey={this.props.stripePublishableKey}
              redirectPaymentError={this.state.redirectPaymentError}
            />
          )
        } else {
          return (
            <NonContractPaymentForm
              toggleModal={this.toggleModal}
              toggleModalAndAddPayment={this.toggleModalAndAddPayment}
              deleteExpense={this.deleteExpense}
              {...this.props}
            />
          )
        }
      } else if (modalContent === 'upgrade') {
        return (
          <CheckoutModal
            modalIsOpen={true}
            closeModal={this.toggleModal}
            user={this.props.user}
            token={this.props.token}
            clientKey={this.props.clientKey}
            stripePublishableKey={this.props.stripePublishableKey}
            redirectPaymentError={this.state.redirectPaymentError} 
          />
        )
      } else if (modalContent === 'contractLinks') {
        return (
          <ActiveLinks
            link={this.state.activeContractLink}
            token={this.props.token}
            clientKey={this.props.clientKey}
            stripePublishableKey={this.props.stripePublishableKey}
            user={this.props.user} 
            toggleModal={this.toggleModal}
          />
        )
      }
    }
  }

  toggleModal = () => {
    this.setState({ modalIsOpen: false, modalContent: null, activeTask: null, taskClass: null })
  }

  toggleModalAndAddPayment = (payment) => {
    let user = this.userCopy();
    user.non_contract_payments.push(payment);
    this.setState({ user }, () => {
      this.toggleModal();
    })
  }

  toggleExpenseModal = () => {
    this.setState({ modalIsOpen: true, modalContent: 'expense' })
  }

  render() {
    let { user } = this.state;
    return (
      <>
        <div className='overview'>
          <div className='standard-padding'>
            <div className='columns'>
              <div className='column is-half is-full-mobile section-column'>
                <article>
                  <Countdown
                    user={user}
                    token={this.props.token}
                    addCeremonyLocationToUser={this.addCeremonyLocationToUser}
                    />
                </article>
              </div>
              <div className='column is-half is-full-mobile section-column'>
                <article>
                  <TaskOverview
                    user={user}
                    tasks={this.props.user.tasks}
                    openTask={this.openTask} />
                </article>
              </div>
            </div>
            <div className='columns columns--bottom'>
              <div className='column is-half is-full-mobile section-column'>
                <article>
                  <QuickLinks
                    user={user}
                    token={this.props.token}
                    handleNewLink={this.handleNewLink}
                    handleUpdatedLink={this.handleUpdatedLink}
                    handleDeletedLink={this.handleDeletedLink}
                    openContractLink={this.openContractLink} 
                  />
                </article>
              </div>

              <div className='column is-half is-full-mobile section-column'>
                <article>
                  <BudgetOverview
                    user={user}
                    showButtons={true}
                    header={'Budget'}
                    vendorTypes={this.props.vendorTypes}
                    toggleExpenseModal={this.toggleExpenseModal} />
                </article>
              </div>
            </div>

            <Modal
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.toggleModal}
              style={modalStyle(this.state.width, this.modalBackground())}
            >
              <i
                className='icon icon-close clickable'
                style={{
                  position:'absolute',
                  right:'1em',
                  top:'1em'
                }}
                onClick={this.toggleModal}
              />
              <div className='dashboard-modal'>
                { this.modalContent()}
              </div>
            </Modal>
          </div>
        </div>
      </>
    )
  }
}

UserOverview.propTypes = {
  user: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  clientKey: PropTypes.string.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
}
