import React from 'react';
import PropTypes from 'prop-types';

export default class Questionnaires extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div className='dashboard__questionnaire'>
        <div className='dashboard__container'>
          <div className='header-with-button'>
            <div className='header-with-swatch header-with-swatch--left-justified'>
              <h2 className="title dashboard__header">Wedding Details</h2>
            </div>
          </div>

          <div className='wedding-details-list'>
            <div className='lists__all'>
              <a href='/questionnaires/wedding_party_members' className='lists__card'>
                <span>Wedding Party</span>
              </a>
              <a href='/questionnaires/family_members' className='lists__card'>
                <span>Family Members</span>
              </a>
            </div>
            <div className='lists__all'>
              <a href='/questionnaires/photo_hours' className='lists__card'>
                <span >Photo Hours</span>
              </a>
              <a href='/questionnaires/flowers' className='lists__card'>
                <span >Flowers</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Questionnaires.propTypes = {

}
