import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from '../../../../../HelperFunctions';

export default class IndividualContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  notesField(contract) {
    if (contract.notes && contract.notes.length > 0) {
      return (
        <>
          <label>Notes</label>
          <h6 className='is-6 subtitle'>{contract.notes}</h6>
        </>
      )
    }
  }

  linkField(contract) {
    if (contract.link_to_document && contract.link_to_document.length > 0) {
      return (
        <>
        <div className='contracts-overview__item'>
          <label>Link</label>
          <h6 className='is-6 subtitle'>
            <a target='_blank' href={contract.link_to_document}>{contract.link_to_document}</a>
          </h6>
        </div>
        </>
      )
    }
  }

  loginField(contract) {
    if (contract.login && contract.login.length > 0) {
      return (
        <>
        <div className='contracts-overview__item'>
          <label>Login</label>
          <h6 className='is-6 subtitle'>
            <p>{contract.login}</p>
          </h6>
        </div>
        </>
      )
    }
  }

  passwordField(contract) {
    if (contract.password && contract.password.length > 0) {
      return (
        <>
        <div className='contracts-overview__item'>
          <label>Password</label>
          <h6 className='is-6 subtitle'>
            <p>{contract.password}</p>
          </h6>
        </div>
        </>
      )
    }
  }

  noteField(contract) {
    if (contract.note_to_contract && contract.note_to_contract.length > 0) {
      return (
        <>
        <div className='contracts-overview__item'>
          <label>Note</label>
          <h6 className='is-6 subtitle'>
            <p>{contract.note_to_contract}</p>
          </h6>
        </div>
        </>
      )
    }
  }

  paymentInstallments(contract) {
    if (contract.vendor_payments && contract.vendor_payments.length > 0) {
      return (
        <div className='contracts-overview__item'>
          <label>Payments</label>
          { this.paymentList(contract.vendor_payments) }
        </div>
      )
    }
  }

  budgetTypeBreakdown(contract) {
    if (contract.vendor_types && contract.vendor_types.length > 0) {
      return (
        <div className='contracts-overview__item'>
          <label>Budget Categories</label>
          { this.budgetCategoriesList(contract) }
        </div>
      )
    }
  }

  budgetCategoriesList(contract) {
    return contract.vendor_types.map((c) => {
      return (
        <h6 className='subtitle is-6' key={c.id}>
          <span>{c.vendor_type}: { formatMoney(c.amount) }</span>
        </h6>
      )
    })
  }

  paymentList(payments) {
    return payments.map((p) => {
      return (
        <h6 className='subtitle is-6' key={p.id}>
            { p.status === 'fully_paid' &&
              <span>{formatMoney(p.total_due)} paid</span>
            }
            { p.status != 'fully_paid' &&
              <span>
                {formatMoney(p.total_due)} {p.due_date ? `due on ${this.formattedDate(p.due_date)} `: 'due'} | <span className='payment-status'>{p.status ? p.status.replace('_', ' ') : ''}</span>
              </span>
            }
        </h6>
      )
    })
  }

  formattedDate(d) {
    if (d) {
      let splitDate = d.split('-')
      return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`
    }
  }

  formattedPrice(price) {
    return price.split('.')[1] === '0' ? price.split('.')[0] : price;
  }

  render() {
    let { contract } = this.props;
    return (
        <li>
          <label>Total Amount</label>
          <h5 className='is-5 subtitle'>{formatMoney(contract.total_price)}</h5>
          { this.linkField(contract) }
          { this.noteField(contract) }
          { this.loginField(contract) }
          { this.passwordField(contract) }
          { this.budgetTypeBreakdown(contract) }
          <div className='contracts-overview__item'>
            <label>Deposit</label>
            <h6 className='subtitle is-6'>{formatMoney(contract.deposit_amount)}</h6>
          </div>
          { this.paymentInstallments(contract) }
          { this.notesField(contract) }

          <span
            className='clickable button acts-as-button button-small'
            onClick={() => this.props.openContractForm(contract)}
            >edit</span>
        </li>
      )

  }
}

IndividualContract.propTypes = {
  contract: PropTypes.object.isRequired,
  openContractForm: PropTypes.func.isRequired
}
