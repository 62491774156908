import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Loading } from '../../../forms/Helpers';
import Modal from 'react-modal';
import { modalStyle } from '../ModalStyle';
import { FormResponse, handleResponse, errorsFormatter } from '../../../forms/Response';

export default class ActiveList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newListItemName: '',
      listName: this.props.list.name,
      modalIsOpen: false,
      width: window.innerWidth,
      isSubmitting: false,
      checkedItems: []
    }
  }

  componentDidMount() {
    if (typeof(window) !== 'undefined') {
      Modal.setAppElement('body')
    }
    window.addEventListener('resize', this.updateWidth);
  }

  updateWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  componentDidUpdate(prevProps, snapshot) {
    if (prevProps.list.name != this.props.list.name) {
      this.setState({ listName: this.props.list.name })
    }
  }

  handleStatusChange = (item) => {
    this.setState({ isSubmitting: true, archivedItem: item, editedName: null, editingItemId: null }, () => {
      this.submitStatusChange();
    })
  }

  submitStatusChange = () => {
    let { archivedItem } = this.state;
    axios.patch(`/api/v1/list_items/${archivedItem.id}`, {
      list_item: {
        is_active: !archivedItem.is_active
      },
      authenticity_token: this.props.token
    })
    .then((response) => {
      this.setState({ isSubmitting: false }, () => {
        this.props.handleUpdatedListItem(response.data.list_item)
      })
    })
    .catch((error) => {
      this.setState({ isSubmitting: false });
      handleResponse(this, 'There was a problem updating this item.', 422, 4000);
    })
  }

  handleNameChange = (e) => {
    e.preventDefault();

    let item = this.props.list.items.filter(i => i.id === this.state.editingItemId)[0];
    if (item) {
      this.setState({ isSubmitting: true}, () => {
        axios.patch(`/api/v1/list_items/${item.id}`, {
          list_item: {
            name: this.state.editedName
          },
          authenticity_token: this.props.token
        })
        .then((response) => {
          this.setState({ isSubmitting: false, editedName: null, editingItemId: null }, () => {
            this.props.handleUpdatedListItem(response.data.list_item)
          })
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });
          handleResponse(this, 'There was a problem updating this item.', 422, 4000);
        })
      })
    }

  }

  deleteItem = (id) => {
    axios.delete(`/api/v1/list_items/${id}`, {
      headers: {
        'X-CSRF-Token': this.props.token,
        'Content-Type': 'application/json'
      }
    })
    .then((result) => {
      this.setState({ isSubmitting: false, archivedItem: null }, () => {
        this.props.removeListItem(result.data.list_item);
      })
    })
    .catch((error) => {
      this.setState({ isSubmitting: false }, () => {
        let formattedErrors = errorsFormatter(error.response.data.errors);
        let errorMessage = error.response.data.message;
        let message = formattedErrors || errorMessage || 'There was a problem adding this item.';
        handleResponse(this, message, error.response.status, 4000);
      })
    })
  }

  restoreItems = (id) => {
    this.setState({ isSubmitting: true }, () => {
      const checkedItems = this.state.checkedItems;
      let data = [];
      if (id) {
        data = [id];
      } else {
        data = [...checkedItems];
      }
      axios.post(`/api/v1/list_items_restore`, {list_item:data}, {
        headers: {
          'X-CSRF-Token': this.props.token,
          'Content-Type': 'application/json'
        }
      })
      .then((result) => {
        this.setState({ isSubmitting: false, archivedItem: null },() => {
          this.props.restoreListItem(result.data.list_item);
        })
      })
      .catch((error) => {
        this.setState({ isSubmitting: false }, () => {
          let formattedErrors = errorsFormatter(error.response.data.errors);
          let errorMessage = error.response.data.message;
          let message = formattedErrors || errorMessage || 'There was a problem restore this item.';
          handleResponse(this, message, error.response.status, 4000);
        })
      })
    })
  }

  toggleModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen, checkedItems: [] })
  }

  checkRestoreItem = (id) => {
    const checkedItems = [...this.state.checkedItems];
    if (checkedItems.includes(id)) {
      checkedItems.splice(checkedItems.indexOf(id),1);
    } else {
      checkedItems.push(id)
    }
    this.setState({checkedItems});
  }

  modalContent = (list) => {
    const items = [...list.items.filter(l => l.isDeleted === true)];
    const checkedItems = this.state.checkedItems;
    return (
      <>
      <div
        className={'list-items__item field'}
        style={{display: 'block'}}
      >
        <i
          disabled={this.state.isSubmitting}
          onClick={() => this.setState({checkedItems: checkedItems.length === items.length ? [] : items.map(i => i.id)})}
          className={'icon icon-checkbox-' + ( checkedItems.length !== 0 && checkedItems.length === items.length ? 'checked ': 'unchecked')}
        />
        Check All
      </div>
      {items.map((i) => {
        return (
          <div key={i.id} className={'list-items__item'} style={{borderBottom: '1px dashed rgba(132,7,5,0.28)'}}>
            <div style={{textAlign: 'left'}}>
              <i
                disabled={this.state.isSubmitting}
                onClick={() => this.checkRestoreItem(i.id)}
                className={'icon icon-checkbox-' + ( checkedItems.includes(i.id) ? 'checked ': 'unchecked')}></i>
              { this.listName(i) }
            </div>

            <span
              className='list-items__remove clickable'
              onClick={() => this.restoreItems(i.id)}
            >
              add back to list
            </span>
          </div>
        )
      })}
      </>
      );
  }

  listName = (item) => {
    if (item.is_active != true || this.state.editingItemId != item.id) {
      return <span onClick={() => this.setState({editingItemId: item.id, editedName: item.name})} key={item.id}>{item.name}</span>
    } else {
      return (
        <form style={{display:'inline'}} onSubmit={(e) => this.handleNameChange(e)}>
          <input key={item.id} value={this.state.editedName} onChange={this.updateEditedName} />
        </form>
      )
    }
  }

  updateEditedName = () => {
    this.setState({ editedName: event.target.value });
  }

  changePackListStatus = (item) => {
    if (!this.state.isSubmitting) {
      this.setState({ isSubmitting: true }, () => {
        axios.patch(`/api/v1/list_items/${item.id}`, {
          list_item: {
            pack_list_checked_off: !item.pack_list_checked_off
          },
          authenticity_token: this.props.token
        })
        .then((response) => {
          this.setState({ isSubmitting: false }, () => {
            this.props.handleUpdatedListItem(response.data.list_item)
          })
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });
          handleResponse(this, 'There was a problem updating this item.', 422, 4000);
        })
      })
    }
  }

  packListItems = (list) => {
    let items = list.items.filter(i => i.is_active != true);
    if (items.length > 0) {
      return (
        <div className='list-items'>
          { items.sort((a,b) => a.pack_list_checked_off - b.pack_list_checked_off).map((i) => {
            return (
              <div className='list-items__item' key={i.id}>
                <div>
                  <i
                    onClick={() => this.changePackListStatus(i)}
                    className={'icon icon-' + (i.pack_list_checked_off === true ? 'checkbox-checked' : 'checkbox-unchecked' )}></i>
                  <span>{ i.name }</span>
                </div>
              </div>
            )
          })}
        </div>
      )
    }
  }

  listOfListItems = (list, status) => {
    const items = list.items.filter(l => l.is_active === status && l.isDeleted !== true);
    if (items.length > 0) {
      return (
        <div className={'list-items ' + (status === false ? 'list-items--finished' : '')}>
          { items.map((i) => {
            return (
              <div
                key={i.id}
                className={'list-items__item ' + (this.state.archivedItem === i && this.state.showPackListOption === true ? 'list-items__item--with-options' : '') }>
                <div style={{textAlign: 'left'}}>
                  <i
                    disabled={this.state.isSubmitting}
                    onClick={() => this.handleStatusChange(i, status)}
                    className={'icon icon-checkbox-' + ( status === false ? 'checked ': 'unchecked')}></i>
                  { this.listName(i) }
                </div>

              { status === true &&
                <span className='list-items__remove clickable'
                  onClick={() => this.deleteItem(i.id)}>does not apply</span>
              }
              </div>
            )
          }) }
        </div>
        )
      }
    }


  addListItem = (e) => {
    e.preventDefault();
    if (this.state.isSubmitting != true && this.state.newListItemName.length > 0) {
      this.setState({ isSubmitting: true }, () => {
        axios.post('/api/v1/list_items', {
          list_item: {
            name: this.state.newListItemName,
            list_id: this.props.list.id
          },
          authenticity_token: this.props.token
        })
        .then((response) => {
          this.setState({ newListItemName: '', isSubmitting: false }, () => {
            this.props.addListItemToList(response.data.list_item);
          })
        })
        .catch((error) => {
          this.setState({ isSubmitting: false }, () => {
            let formattedErrors = errorsFormatter(error.response.data.errors);
            let errorMessage = error.response.data.message;
            let message = formattedErrors || errorMessage || 'There was a problem adding this item.'
            handleResponse(this, message, error.response.status, 4000);
          })
        })
      })
    }
  }

  listItem = (item) => {
    return <span key={item.id}>{item.name}</span>
  }

  handleChange = (e) => {
    this.setState({ newListItemName: e.target.value })
  }

  newListItem = (list) => {
    return (
      <form onSubmit={this.addListItem}>
        <div className='field is-grouped input-with-grouped-buttons input-with-grouped-buttons--sm'>
            <input
              value={this.state.newListItemName}
              onChange={this.handleChange}
              className="input" type="text"
              placeholder="Add item to list" />
            <button
              disabled={this.state.newListItemName.length < 2}
              className='button button-primary'
              type='submit'>submit</button>
        </div>

      </form>
    )
  }

  toggleListEdit = () => {
    this.setState({ listEditOpen: !this.state.listEditOpen });
  }

  handleListNameChange = (e) => {
    this.setState({ listName: e.target.value });
  }

  submitListNameChange = (e) => {
    e.preventDefault();
    axios.patch(`/api/v1/lists/${this.props.list.id}`, {
      list: {
        name: this.state.listName
      },
      authenticity_token: this.props.token
    })
    .then((response) => {
      this.props.updateList(response.data.list);
      this.setState({ listEditOpen: false });
    })
    .catch((error) => {
      handleResponse(this, 'There was a problem updating this list', 422, 4000);
    })
  }

  listHeader = () => {
    if (this.state.listEditOpen != true) {
      return (
        <h4
          onClick={this.toggleListEdit}
          className='title is-4 clickable'>{ this.props.list.name }</h4>)
    } else {
      return (
        <form className='has-text-centered' onSubmit={this.submitListNameChange}>
          <div className="field is-grouped input-with-grouped-buttons">
            <input className='input input-large has-text-centered' type='text' value={this.state.listName} onChange={this.handleListNameChange}></input>
            <button type='submit' className='button button-primary'>submit</button>
            <button
              onClick={this.toggleListEdit}
              className='button'
              type='button'>cancel</button>
          </div>
        </form>
      )
    }
  }

  deleteList = () => {
    if (confirm('Are you sure you want to delete this list and all of its items?')) {
      let { list } = this.props;
      axios.delete(`/api/v1/lists/${list.id}`, {
        headers: {
          'X-CSRF-Token': this.props.token,
          'Content-Type': 'application/json'
        }
      })
      .then((result) => {
        this.setState({ isSubmitting: false }, () => {
          this.props.removeList(result.data.list);
        });
      })
      .catch((error) => {
        this.setState({ isSubmitting: false }, () => {
          let formattedErrors = errorsFormatter(error.response.data.errors);
          let errorMessage = error.response.data.message;
          let message = formattedErrors || errorMessage || 'There was a problem deleting this list.';
          handleResponse(this, message, error.response.status, 4000);
        })
      })
    }
  }

  duplicateList = (e) => {
    e.preventDefault();
    if (confirm('Would you like to duplicate this list?')) {
      axios.post(`/api/v1/lists/${this.props.list.id}/copy`, {
        authenticity_token: this.props.token
      })
      .then((response) => {
        this.props.addNewListToExisting(response.data.list)
      })
      .catch((error) => {
        handleResponse(this, 'There was a problem duplicating this list. Lists can only be duplicated once.', 422, 4000);
      })
    }
  }

  listOptionsByType = (list) => {
    let { responseMessage, responseCode } = this.state;

    if (this.props.activeTab === 'pack') {
      return (
        <div>
          { FormResponse(responseMessage, responseCode) }
          {this.packListItems(list)}
        </div>
      )
    } else {
      return (
        <div>
          { FormResponse(responseMessage, responseCode) }
          { this.newListItem(list) }
          { this.listOfListItems(list, true) }
          { this.listOfListItems(list, false) }
          <div className='list-edit'>
            <button
              onClick={this.deleteList}
              className='button button-delete button-small'>delete list</button>
          </div>
          <div style={{textAlign: 'left', marginTop: 10}}>
            <span
              onClick={this.toggleModal}
              className='list-items__remove clickable'
            >
              see removed items
            </span>
          </div>
        </div>
      )
    }
  }

  formatDate = (d) => {
    const listDate = new Date(d);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return listDate.toLocaleDateString('en-US', options)
  }

  render() {
    const list = this.props.list;
    return (
      <div className='active-card__detail has-text-centered'>
        { this.state.listEditOpen != true &&
          <>
            <i className="icon icon-arrow-left sm-only is-pulled-left clickable" onClick={this.props.clearActiveList}></i>
            <a className='is-pulled-right' href={`/lists/${list.id}.pdf?list_type=${this.props.activeTab === 'my' ? 'checklist' : 'packlist'}`} target='_blank'>
              <i className='icon icon-printer'></i>
            </a>
            <i
              onClick={(e) => this.duplicateList(e)}
              className='icon icon-copy clickable is-pulled-right has-text-weight-bold'></i>
          </>
        }


        <div className='mb-one-em'>
          { this.listHeader() }
          { list.created_at &&
            <span style={{fontSize:'0.85em'}}>Created on { this.formatDate(list.created_at) }</span>
          }
        </div>

        { this.listOptionsByType(list) }
        {this.state.modalIsOpen &&
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.toggleModal}
          style={modalStyle(this.state.width, '#F3F1EE')}>
          <div className='dashboard-modal'>
            <i style={{float: 'right', cursor: 'pointer'}} className='icon icon-close' onClick={this.toggleModal}></i>
            <h4 style={{clear:'both'}} className='title is-4'>Removed items in {this.props.list.name} list</h4>
            <div className='lists list-items__item'>
              { this.modalContent(list) }
            </div>
            <div className='row-buttons'>
              <button
                className='button'
                type='button'
                onClick={this.toggleModal}>cancel</button>
              <button
                disabled={this.state.isSubmitting}
                className='button button-primary'
                type='button'
                onClick={() => this.restoreItems()}>add back to list</button>
            </div>
          </div>
        </Modal>}
      </div>
    )
  }
}

ActiveList.propTypes = {
  addListItemToList: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  removeListItem: PropTypes.func.isRequired,
  removeList: PropTypes.func.isRequired,
  updateList: PropTypes.func.isRequired,
  clearActiveList: PropTypes.func.isRequired,
  addNewListToExisting: PropTypes.func.isRequired
}
