import React from 'react';
import PropTypes from 'prop-types';

export default class IndividualQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  label = (label) => {
    // TODO - this is quick and dirty - refactor this!
    let customized = [
      'Getting Ready Photos for INSERT(first_name)?',
      'Getting Ready Photos for INSERT(partner_first_name)?',
      'INSERT(first_name) and INSERT(partner_first_name) getting ready at same property?',
      'Travel time from getting ready venue to ceremony venue? (in minutes)',
      'Drive time from ceremony to reception? (in minutes)',
      'Pre-dinner dancing?',
    ];

    const helpers = {
      DriveTime:'For same location where no transportation is needed, enter 0',
      PreDinner:'Many cultural weddings will dance before dinner. This is not recommend for a reception that is shorter than 5 hours.',
    }
    
    const lineSeparator = (label, help, separator = '(in minutes)') => {
      const indexOfSeparation = label.indexOf(separator)
      return (
        <>
        <div>
          {!!separator ? label.slice(0, indexOfSeparation) : label}
          {!!help
          && <span data-tooltip={helpers[help]}
                  className="has-tooltip-multiline has-tooltip-left sm-tooltip"><i className='icon icon-question'></i></span>}
          </div>
        {!!separator && <div style={{fontSize:'0.8em'}}>{separator}</div>}
        </>
      );
    }

    if (customized.includes(label)) {
      switch (customized.indexOf(label)) {
      case 0:
        return `Getting Ready Photos for ${this.props.user.first_name}?`;
      case 1:
        return `Getting Ready Photos for ${this.props.user.partner_first_name}?`;
      case 2:
        return `${this.props.user.first_name} and ${this.props.user.partner_first_name} getting ready at same property?`
      case 3:
        return lineSeparator(label);
      case 4:
        return lineSeparator(label, 'DriveTime');
      case 5:
        return lineSeparator(label, 'PreDinner', '');
      }
    } else {
      return label;
    }
  }

  radio = (question) => {
    return question.options.responses.map((r) => {
      return (
        <div
          key={r.label}
          className='quiz__radio'
          style={this.props.isView && !(this.props.values[`${question.field}`] === r.database_value) ? {display: 'none'} : {}}
        >
          <fieldset>
          <div className="quiz__inner">
            <input type="radio"
              checked={this.props.values[`${question.field}`] === r.database_value}
              onChange={() => this.props.handleInputResponse(question, r.database_value)} />

            <label
              className={"quiz__label " + (this.props.values[`${question.field}`] === r.database_value ? "quiz__label--selected" : "")}
              htmlFor={r.label}
              onClick={() => this.props.handleInputResponse(question, r.database_value, true)}>
                <span className="" onClick={() => this.props.handleInputResponse(question, r.database_value, true)}>
                  {r.label}
                </span>
            </label>
          </div>
          </fieldset>
        </div>
      )
    })
  }

  numberInput = (question) => {
    return (
      <>
      <div className="control">
        <label className="label" htmlFor={question.field}></label>
        <input
          value={this.props.values[`${question.field}`]}
          onChange={this.validateNumber}
          className="text-input input"
          type='number'
          min='0'/>
      </div>
        { this.props.hasNext === true &&
          <div className='has-text-centered'>
            <button
              className='button button-primary button-full-on-sm mt-one-em button-half-width'
              disabled={this.props.values[`${question.field}`] === '' }
              onClick={() => this.props.changeQuestion('next')}>next</button>
          </div>
        }
      </>
    )
  }

  timeInput = (question) => {
    let val = this.props.values[`${question.field}`];
    let formattedTime = '';
    if (val && val.length > 5) {
      // here, the value is a datetime from the db - just extract the time
       formattedTime = val.substr(11,5)
    } else if (val) {
      formattedTime = val;
    }
    return (
      <>
      <div className='control'>
        <label className="label" htmlFor={question.field}></label>
          <input
            value={formattedTime}
            onChange={() => this.props.handleInputResponse(question, event.target.value)}
            className="text-input input"
            type='time' />
      </div>

      { this.props.hasNext === true &&
        <div className='has-text-centered'>
          <button
            className='button button-primary button-full-on-sm mt-one-em button-half-width'
            disabled={this.props.values[`${question.field}`] === '' }
            onClick={() => this.props.changeQuestion('next')}>next</button>
        </div>
      }
      </>
    )
  }

  validateNumber = (e) => {
    let { question } = this.props;
    if (isNaN(e.target.value) || e.target.value === '') {
      this.setState({ hasError: true, error: 'Input must be a number.' }, () => {
        this.props.deleteInputResponse(question)
      })
    } else {
      this.props.handleInputResponse(question, parseInt(e.target.value))
    }
  }

  inputChecked = (question, item) => {
    return this.props.values[`${question.field}`] && this.props.values[`${question.field}`].includes(item)
  }



  multipleInput = (question) => {
    return (
      <>
      <fieldset
        className='control'>
        <span className='has-text-centered is-block mb-one-em'>Select all that apply.</span>
        { question.options.responses.map((r) => {
          return (
            <div
              className='quiz__checkbox'
              key={r.label}
              >
              <input
                type='checkbox'
                value={r.label}
                key={r.label}
                onChange={() => this.props.handleMultipleInputResponse(question, r.label)}
                checked={this.inputChecked(question, r.label)} />
              <label
                style={this.props.isView && !(this.inputChecked(question, r.label)) ? {display: 'none'} : {}}
                className={'quiz__label ' + ( this.inputChecked(question, r.label) ? 'quiz__label--selected' : '')}
                onClick={() => this.props.handleMultipleInputResponse(question, r.label)}>
                { this.inputChecked(question, r.label) &&
                  <i className='icon icon-checkmark-outline'></i>
                }
                {r.label}
              </label>
            </div>
          )
        }) }
      </fieldset>

      { this.props.hasNext === true &&
        <div className='has-text-centered'>
          <button
            className='button button-primary button-full-on-sm mt-one-em button-half-width'
            disabled={this.props.values[`${question.field}`] === '' }
            onClick={() => this.props.changeQuestion('next')}>next</button>
        </div>
      }
      </>
    )
  }

  input = (question) => {
    switch(question.options.response_type) {
      case 'single':
        return this.radio(question);
      case 'number':
        return this.numberInput(question);
      case 'time':
        return this.timeInput(question);
      case 'multiple':
        return this.multipleInput(question);
      default:
        return this.radio(question);
    }
  }

  progress = () => {
    if (this.props.showAll === false) {
      let nums = this.props.questionNumber.split(' / ');
      let percentage = (parseInt(nums[0]) / parseInt(nums[1]) * 100);
      return (
        <div className='questionnaire__progress'>
          <progress id="file" max="100" value={percentage}> {percentage}% </progress>
        </div>
      )
    }
  }

  

  render() {
    const { question } = this.props;
    return (
      <div className='photo-hours__question quiz__question'>
        { this.progress() }
        <div className='active-card active-card--centered'>
          <div className="field">
            <h4 className='is-4 title has-text-centered quiz__header'>
              {this.label(question.label)}
            </h4>
            { question.options && question.options.explanation_text &&
              <p className='quiz__explanation'>{ question.options.explanation_text}</p>
            }
            { this.input(question) }
          </div>

          { this.props.hasPrevious &&
            <div className='has-text-centered'>
              <button
                onClick={() => this.props.changeQuestion('previous')}
                className='button button-full-on-sm button-no-styling'>
                back
              </button>
            </div>
          }

          { this.props.hasNext === false && !this.props.showAll &&
            <div className='has-text-centered'>
              <button
                className='button button-primary button-full-on-sm'
                disabled={this.props.values[`${question.field}`] === '' }
                onClick={this.props.submitForm}>submit</button>
            </div>
          }
        </div>
      </div>

    )
  }
}

IndividualQuestion.propTypes = {
  handleInputResponse: PropTypes.func.isRequired,
  deleteInputResponse: PropTypes.func.isRequired,
  handleMultipleInputResponse: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  showAll: PropTypes.bool.isRequired
}
