export const ADYEN_PAYMENT_METHODS = {
  "groups":
    [
      { "name":"Credit Card",
        "types":["visa", "mc", "amex", "discover", "diners"]}],
        "paymentMethods":[
          {
            "brands":["visa", "mc", "amex", "discover", "diners"],
            "details":[
              {"key":"encryptedCardNumber", "type":"cardToken"},
              {"key":"encryptedSecurityCode", "type":"cardToken"},
              {"key":"encryptedExpiryMonth", "type":"cardToken"},
              {"key":"encryptedExpiryYear", "type":"cardToken"},
              {"key":"holderName", "optional":true, "type":"text"}
            ],
            "name":"Credit Card",
            "type":"scheme"
          },
      ]
  }
