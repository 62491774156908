import React from 'react';
import PropTypes from 'prop-types';
import Talk from "talkjs";

const userTypes = {
  user: 'user',
  vendor: 'vendor'
}

export default class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.talkjsContainer = React.createRef();
    this.state = {}
  }

  componentDidMount() {
    this.initializeChat();
  }

  componentDidUpdate() {
    window.talkSession.destroy();
    this.initializeChat();
  }

  initializeChat = () => {
    const { userType } = this.props;
    Talk.ready.then(() => {
       let me = this.meUserInstance();

       window.talkSession = new Talk.Session({
          appId: "tPmDVED8",
          me: me
       });

      let other = this.otherUserInstance();

      let conversation = window.talkSession.getOrCreateConversation(Talk.oneOnOneId(me, other))
      conversation.setParticipant(me);
      conversation.setParticipant(other);
      //let inbox = window.talkSession.createInbox({selected: conversation});
      let chat = window.talkSession.createChatbox(conversation);
      chat.mount(this.talkjsContainer.current);
    })
  }

  meUserInstance = () => {
    let { userType, vendor } = this.props;
    if (userType === userTypes.user) {
      return this.userInstance(this.props.user);
    } else if (userType === userTypes.vendor) {
      return this.vendorInstance(vendor.chat_id, vendor.first_name, vendor);
    }
  }

  otherUserInstance = () => {
    let { userType } = this.props;
    if (userType === userTypes.user) {
      let { vendor } = this.props;
      return this.vendorInstance(vendor.chat_id, vendor.company_name, vendor.contacts[0]);
    } else if (userType === userTypes.vendor) {
      return this.userInstance(this.props.user);
    }
  }

  userInstance = (user) => {
    return new Talk.User({
        id: `user_${user.unique_identifier}`,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        role: 'user'
    });
  }

  vendorInstance = (vendorChatId, vendorName, vendorContact) => {
    return new Talk.User({
      id: `vendor_${vendorChatId}`,
      name: vendorName,
      email: vendorContact.email,
      role: 'vendor'
    });
  }

  render() {
    return (
      <div>
        <div className='vendors__chat'
              ref={this.talkjsContainer}></div>
      </div>

    )
  }
}

Chat.propTypes = {
  user: PropTypes.object,
  userType: PropTypes.string.isRequired,
  vendor: PropTypes.object
}
