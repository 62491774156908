import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormTextInput, Loading } from '../../forms/Helpers';
import { FormResponse, handleResponse } from '../../forms/Response';

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      responseMessage: null,
      responseCode: null
    }
  }

  componentWillUnmount() {
    if (this.formResponse) {
      clearTimeout(this.formResponse);
    }
  }

  handleLogin = (values, setSubmitting) => {
    this.setState({ isSubmitting: true }, () => {
      let { token } = this.props;
      let data = JSON.stringify({
        user: {
          email: values.loginEmail,
          password: values.loginPassword
        }
      })
      axios.post('/users/sign_in', data, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': token,
          'Accept': 'application/json'
        }
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        this.setState({ isSubmitting: false }, () => {
          let message = error.response.data.error || 'Incorrect email and password combination.'
          handleResponse(this, message, error.response.status, 4000);
        })
      })
    })
  }

  form = () => {
    return (
      <Formik
        initialValues={{
          loginEmail: '',
          loginPassword: ''
        }}
        validationSchema={Yup.object({
          loginEmail: Yup.string()
            .required('Please enter your email address')
            .email('Please enter a valid email address'),
          loginPassword: Yup.string()
            .required('Please enter a password')
            .min(8, 'Should be 8 chars minimum')
            .max(128, 'Should be 128 chars maximum')
        })}
        onSubmit={(values, { setSubmitting }) => {
          this.handleLogin(values, setSubmitting)
        }}
      >
        <Form>
          <div className="field">
            <FormTextInput
              name="loginEmail"
              type="text"
              required
              placeholder="Email"
            />
          </div>
          <div className="field">
            <FormTextInput
              name="loginPassword"
              type="password"
              required
              placeholder="Password"
            />
          </div>

          <div className='forgot-password'>
            <a href="/users/password/new">Forgot your password?</a>
          </div>

          <div className="control">
            <div className="field">
              <button className="button button-primary is-fullwidth" type="submit">Log In</button>
            </div>

            <div className='register-notice'>
              <p>Don't have an account? <a href='/users/sign_up'>Sign up here.</a></p>
            </div>

            <div className='divider'></div>

            <div className="field">
              <a className="button is-fullwidth oauth-button oauth-button--google link-without-styling"
                 href="/users/auth/google_oauth2"
                 data-method="POST"
                 rel="nofollow"
              >
                <i className="icon icon-google" />
                Log In With Google
              </a>
            </div>

            <div className="field">
              <a className="button is-fullwidth oauth-button oauth-button--facebook"
                 href="/users/auth/facebook"
                 data-method="POST"
                 rel="nofollow"
              >
                <i className="icon icon-facebook" />
                Log In With Facebook
              </a>
            </div>
            <div className="g-signin2" data-onsuccess="onSignIn"></div>
          </div>
        </Form>
      </Formik>
    )
  }

  render() {
    let { responseMessage, responseCode, isSubmitting } = this.state;

    return (
      <div className="column sessions__container">
        <div className="home__container has-text-centered	">
          <a className="logo-home" href="/" rel="up">
            <img src="/images/gb-logo.png" alt="guided bride logo" />
          </a>
          <h2 className="title is-2">Log In</h2>
          { isSubmitting &&
            <Loading />
          }

          { !isSubmitting &&
            <div className='sessions__form'>
              { FormResponse(responseMessage, responseCode) }
              { this.form() }
            </div>
          }
        </div>
      </div>
    )
  }
}

Login.propTypes = {
  token: PropTypes.string.isRequired
}
