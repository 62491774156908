import React from 'react';
import PropTypes from "prop-types"
import CurrentVideo from './CurrentVideo';
import VideosOptions from './VideosOptions';

export default class VideosContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeVideo: this.props.videos[0]
    }
  }

  render() {
    let { videos } = this.props;
    if (videos.length === 0) {
      return (<h4>There are no videos uploaded yet.</h4>)
    } else {
      return (
        <div>
          <CurrentVideo
            video={this.state.activeVideo} />
          <VideosOptions
            videos={this.props.videos}
            setActiveVideo={(activeVideo) => this.setState({ activeVideo })}
            activeVideo={this.state.activeVideo}/>
        </div>
      )
    }
  }
}


VideosContainer.propTypes = {
  videos: PropTypes.array.isRequired
};
