import React from 'react';
import dateFormat, { i18n } from 'dateformat';
import * as CONSTANTS from '../../../Constants'

const TASK_STATUS = {
  0: 'incomplete',
  1: 'in progress',
  2: 'complete',
  3: 'n/a'
};

const DAYS = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];

i18n.dayNames = [
  "Sun",
  "Mon",
  "Tues",
  "Wed",
  "Thurs",
  "Fri",
  "Sat",
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function formatDate(d) {
  let date = new Date(d);
  let dayName = date.getDay();
  let dayNumber = dateFormat(d, 'UTC:d');
  let monthNumber = date.getMonth();
  return (
    <>
      <div className="task-date">
        <div className="task-date__circle">
          <div className="task-date__day">{dateFormat(d, 'UTC:ddd')}</div>
          <div className="task-date__date">{dayNumber}</div>
        </div>
      </div>
    </>

  )
}

function checkIfDueToday(task) {
  let taskDate = new Date(task.due_date);
  let today = new Date();
  taskDate.setHours(0,0,0,0);
  today.setHours(0,0,0,0);
  return taskDate.getTime() === today.getTime()
}

function bottomDate(task) {
  let splitDate = task.due_date.split('-');
  return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`
}

function taskType (task) {
  if (task.vendor_payment_id) {
    return (<span className='task-payment-reminder'>Payment Reminder</span>)
  } else if (!task.admin_generated) {
    return (<span className='task-my-custom-task'>My Custom Task</span>)
  }
}

function TaskCard(props) {
  let { task, isActive, makeActive, tagFilter } = props;
  let dueToday = checkIfDueToday(task)
  return (
    <>
    { dueToday === true &&
      <span className='due-notice '>Due today</span>
    }
    <div className={"task-card " + (isActive ? "task-card--active" : "") + (dueToday ? " due-today" : "")}
        onClick={() => makeActive(task)}>
      { formatDate(task.due_date) }
      <div className="task-card__body">
        {taskType(task)}
        <div className="task-card__description">
          <p>{ task.title}</p>
        </div>
        <div className="task-card__bottom">
          <p>{TASK_STATUS[task.status]}</p>
          <span className='divider'>|</span>
          <p>Due Date: {bottomDate(task)}</p>
        </div>
      </div>
    </div>
    { tagFilter && !!task.tags.length &&
      <div className='task-card__tags'>
        <span>Tags: {tagFormatter(task, tagFilter)}</span>
      </div>
    }
    </>
  )
}

function tagFormatter(task, tagFilter) {
  return task.tags.map((t) => {
    return <span key={t} className={'task-card__tag ' + (t === tagFilter ? 'has-text-weight-bold' : '')}>{t}</span>
  })
}

export default TaskCard;
