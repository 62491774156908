import React from 'react';
import PropTypes from 'prop-types';
import VendorsList from './list/VendorsList';
import VendorsDetail from './active_vendor/VendorsDetail';
import AddVendor from './active_vendor/actions/AddVendor';
import EditVendor from './active_vendor/actions/EditVendor';
import GuideModal from '../GuideModal';
import GuidedBrideVendor  from './GuidedBrideVendor';

const TABS = ['Info', 'Contracts', 'Chat']

export default class VendorsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewVendorForm: false,
      showEditVendorForm: false,
      vendors: this.props.vendors,
      activeTab: TABS[0],
      modalContent: null
    }
  }

  componentDidMount() {
    let link = window.location.href;
    let showNewVendorForm = false;
    let activeVendor = null;
    let activeTab = TABS[0];
    let splitPath = link.includes('vendors') ? link.split('/vendors') : link.split('/venues');

    if (splitPath.length > 1 && splitPath[1].includes('action=new')) {
      showNewVendorForm = true
    }
    let vendorById = link.split('?id=')
    if (vendorById.length > 1) {
      let id = vendorById[1].split('&')[0]
      activeVendor = this.props.vendors.filter(v => v.id === parseInt(id))[0]
    }
    let tabFromLink = link.split('&tab=');
    if (tabFromLink.length > 1) {
      let tab = tabFromLink[1].split('&')[0];
      tab = tab.charAt(0).toUpperCase() + tab.slice(1).toLowerCase();
      activeTab = TABS.includes(tab) ? tab : TABS[0];
    }
    this.setState({ showNewVendorForm, activeVendor, activeTab})
  }

  handleNewVendor = (vendor) => {
    let vendors = [...this.state.vendors];
    vendors.push(vendor);
    this.setState({
      vendors, activeVendor: vendor, showNewVendorForm: false,
      activeTab: 'Contracts'
    })
      //modalContent: 'GuideModal', modalText: `This ${this.props.isVenue ? 'venue' : 'vendor'} has been saved!` })
  }

  handleEditedVendor = (vendor) => {
    let vendors = [...this.state.vendors];
    let vendorToUpdate = vendors.filter(v => v.id === vendor.id)[0];
    if (vendorToUpdate) {
      vendors[vendors.indexOf(vendorToUpdate)] = vendor;
      this.setState({
        vendors, activeVendor: vendor, showEditVendorForm: false,
        modalContent: 'GuideModal', modalText: `This ${this.props.isVenue ? 'venue' : 'vendor'} has been updated!`
      })
    }
  }

  handleDeletedVendor = (vendorId) => {
    let vendors = [...this.state.vendors];
    let vendorToRemove = vendors.filter(v => v.id === vendorId)[0];
    if (vendorToRemove) {
      vendors.splice(vendors.indexOf(vendorToRemove), 1);
      this.setState({ vendors, activeVendor: null, showEditVendorForm: false });
    }
  }

  handleNewVendorPayment = (payment) => {
    let vendors = [...this.state.vendors];
    let vendorToUpdate = vendors.filter(v => v.id === this.state.activeVendor.id)[0];

    if (vendorToUpdate) {
      let updatedVendor = Object.assign({}, vendorToUpdate);
      let updatedContract = updatedVendor.contracts.filter(c => parseInt(c.id) === parseInt(payment.vendor_contract_id))[0];

      if (updatedContract) {
        updatedContract.vendor_payments.push(payment);
        vendors[vendors.indexOf(vendorToUpdate)] = updatedVendor;
        this.setState({
          vendors, activeVendor: updatedVendor, showEditVendorForm: false
        })
      }

    }
  }

  activeTabContent = () => {
    if (this.state.showNewVendorForm === true) {
      return (
        <AddVendor
          isVenue={this.props.isVenue}
          vendorTypes={this.props.vendorTypes}
          handleNewVendor={this.handleNewVendor}
          user={this.props.user}
          closeForm={() => this.setState({ showNewVendorForm: false })}
          token={this.props.token} />
      )
    } else if (this.state.showEditVendorForm === true) {
      return (
        <EditVendor
          isVenue={this.props.isVenue}
          vendor={this.state.activeVendor}
          vendorTypes={this.props.vendorTypes}
          handleEditedVendor={this.handleEditedVendor}
          handleDeletedVendor={this.handleDeletedVendor}
          user={this.props.user}
          closeForm={() => this.setState({ showEditVendorForm: false })}
          token={this.props.token} />
      )
    } else if (this.state.showGuidedBrideVendor === true) {
      return (
        <GuidedBrideVendor
          vendor={this.props.guidedBrideDefaultVendor}
          closeGuidedBrideVendor={() => this.setState({ showGuidedBrideVendor: false })}
          user={this.props.user}
          token={this.props.token}
          clientKey={this.props.clientKey}
          stripePublishableKey={this.props.stripePublishableKey}
          />
      )
    }
      else {
      return (
        <VendorsDetail
          isVenue={this.props.isVenue}
          vendor={this.state.activeVendor}
          allVendorsLength={this.state.vendors.length}
          user={this.props.user}
          token={this.props.token}
          clientKey={this.props.clientKey}
          stripePublishableKey={this.props.stripePublishableKey}
          addOrUpdateContract={this.addOrUpdateContract}
          handleNewVendorPayment={this.handleNewVendorPayment}
          handleDeletedVendor={this.handleDeletedVendor}
          activeTab={this.state.activeTab}
          removeDeletedContract={this.removeDeletedContract}
          tabs={TABS}
          vendorTypes={this.props.vendorTypes}
          vendorCategories={this.props.vendorCategories}
          setActiveTab={(activeTab) => this.setState({ activeTab }) }
          closeVendorDetail={() => this.setState({ activeVendor: null })}
          toggleEditVendorForm={() => this.setState({ showEditVendorForm: !this.state.showEditVendorForm })} />
      )
    }
  }

  addOrUpdateContract = (contract) => {
    let vendor = Object.assign({}, this.state.activeVendor);
    let existingContract = vendor.contracts.filter(c => c.id === contract.id)[0];
    if (existingContract) {
      vendor.contracts[vendor.contracts.indexOf(existingContract)] = contract;
    } else {
      vendor.contracts.push(contract);
    }
    this.setState({ activeVendor: vendor, modalContent: 'GuideModal',
      modalText: `This contract has been saved!`
    })
  }

  removeDeletedContract = (contractId) => {
    let vendor = Object.assign({}, this.state.activeVendor);
    let existingContract = vendor.contracts.filter(c => c.id === contractId)[0];
    if (existingContract) {
      vendor.contracts.splice(vendor.contracts.indexOf(existingContract), 1);
      this.setState({ activeVendor: vendor })
    }
  }

  selectVendor = (activeVendor) => {
    if (window.location.href.includes('?')) {
      window.history.pushState({}, 'Vendors', '/vendors');
    }
    this.setState({ activeVendor, showNewVendorForm: false, showEditVendorForm: false, activeTab: TABS[0], showGuidedBrideVendor: false  })
  }

  addNewVendorButton = () => {
    if (!this.state.showNewVendorForm) {
      return (
        <button
          className='button button-primary'
          onClick={() => this.setState({ showNewVendorForm: true, activeVendor: null, showGuidedBrideVendor: false })}>
          add new
        </button>
      )
    }
  }

  setActiveVendorAndTab = (activeVendor, activeTab) => {
    this.setState({ activeVendor, activeTab })
  }

  modalContent = () => {
    if (this.state.modalContent === 'GuideModal') {
      let options = [
        { link: '/tasks', title: 'See my tasks'},
        { link: '/', title: 'Go to dashboard' },
        { link: 'btnClose', title: 'Close window' }
      ];
      if (this.props.isVenue) {
        options.unshift({link: '/vendors?action=new', title: 'Add vendors'})
        options.unshift({link: '/venues?action=new', title: 'Add another venue'})
      } else {
        options.unshift({link: '/vendors?action=new', title: 'Add another vendor'})
      }

      return <GuideModal
                options={options}
                preText={this.state.modalText}
                closeModal={this.closeModal} />
    }
  }

  closeModal = () => {
    this.setState({ modalContent: null })
  }

  currentDashboardLeftClass = () => {
    if (this.state.activeVendor || this.state.showEditVendorForm || this.state.showNewVendorForm || this.state.showGuidedBrideVendor) {
      return 'dashboard__left--collapsed';
    } else { return 'dashboard__left--full'; }
  }

  currentDashboardRightClass = () => {
    if (this.state.activeVendor || this.state.showEditVendorForm || this.state.showNewVendorForm || this.state.showGuidedBrideVendor) {
      return 'dashboard__right--full';
    } else { return 'dashboard__right--collapsed'; }
  }

  showGuidedBrideVendor = () => {
    this.setState({
      showGuidedBrideVendor: true, activeVendor: null, showNewVendorForm: false,
      showEditVendorForm: false
    })
  }

  render() {
    return (
      <div className='standard-padding dashboard__inner'>
        <div className={'dashboard__left ' + (this.currentDashboardLeftClass())}>
          <div className='dashboard__container'>
            <div className='header-with-button'>
              <div className='header-with-swatch header-with-swatch--left-justified'>
                  <h2 className='title dashboard__header'>{this.props.isVenue ? 'Venues' : 'Vendors'}</h2>
              </div>
              { this.addNewVendorButton() }
            </div>

            <VendorsList
              isVenue={this.props.isVenue}
              vendors={this.state.vendors || this.props.vendors}
              showGuidedBrideVendor={this.showGuidedBrideVendor}
              guidedBrideVendorActive={this.state.showGuidedBrideVendor}
              user={this.props.user}
              addNew={() => this.setState({ showNewVendorForm: true })}
              selectVendor={this.selectVendor}
              activeVendor={this.state.activeVendor}
              setActiveVendorAndTab={this.setActiveVendorAndTab} />
          </div>
        </div>
        <div className={'dashboard__right ' + (this.currentDashboardRightClass())}>
          <div className='dashboard__container tasks-down'>
            <div className='active-card'>
              { this.activeTabContent() }
            </div>
          </div>
        </div>
        { this.modalContent() }
      </div>
    )
  }
}

VendorsContainer.propTypes = {
  vendors: PropTypes.array.isRequired,
  vendorTypes: PropTypes.array,
  token: PropTypes.string.isRequired,
  clientKey: PropTypes.string.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
}
