import React from 'react';
import PropTypes from 'prop-types';
import DashboardNav from './DashboardNav';
import Account from './Account';
import IntroForm from './IntroForm';
import Tasks from './tasks/Tasks';
import Budget from './Budget';
import SuggestedBudget from './SuggestedBudget'
import QuotesContainer from './quotes/QuotesContainer';
import UserOverview from './overview/UserOverview';
import VendorsContainer from './vendors/VendorsContainer';
import ListsContainer from './lists/ListsContainer';
import Questionnaires from './questionnaires/Questionnaires';
import PhotoHours from './questionnaires/PhotoHours/PhotoHoursContainer';
import FlowerQuestionnaire from './questionnaires/Flowers/FlowerQuestionnaire';
import WeddingPartyContainer from './questionnaires/WeddingParty/WeddingPartyContainer';

export default class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  dashboardPage = () => {
    if (this.props.user.completed_intro_information != true) {
      return <IntroForm 
                token={this.props.token} 
                user={this.props.user} 
                clientKey={this.props.clientKey}
                stripePublishableKey={this.props.stripePublishableKey}
              />
    } else {
      switch (this.props.page.toLocaleLowerCase()) {
      case 'account':
          return <Account token={this.props.token} user={this.props.user} clientKey={this.props.clientKey} stripePublishableKey={this.props.stripePublishableKey} />
      case 'tasks':
        return (
          <Tasks
            user={this.props.user}
            token={this.props.token}
            clientKey={this.props.clientKey}
            stripePublishableKey={this.props.stripePublishableKey}
          />
        )
      case 'my budget':
        return (
          <Budget
            {...this.props} />
        )
      case 'suggested budget':
        return (
          <SuggestedBudget
            {...this.props} />
        )
      case 'home':
        return (
          <UserOverview
            { ...this.props } />
        )
      case 'vendor quotes':
        return (
          <QuotesContainer
            {...this.props} />
        )
      case 'booked venues':
        return (
          <VendorsContainer
            user={this.props.user}
            vendors={this.props.venues}
            token={this.props.token}
            clientKey={this.props.clientKey}
            stripePublishableKey={this.props.stripePublishableKey}
            vendorTypes={this.props.vendorTypes}
            vendorCategories={this.props.vendorCategories}
            isVenue={true}
          />
        )
      case 'venue quotes':
        return (
          <QuotesContainer
            {...this.props} />
        )
      case 'booked vendors':
        return (
          <VendorsContainer
            user={this.props.user}
            guidedBrideDefaultVendor={this.props.guided_bride_default_vendor}
            vendors={this.props.vendors}
            token={this.props.token}
            clientKey={this.props.clientKey}
            stripePublishableKey={this.props.stripePublishableKey}
            vendorTypes={this.props.vendorTypes}
            vendorCategories={this.props.vendorCategories}/>
        )
      case 'lists':
          return <ListsContainer
            user={this.props.user}
            clientKey={this.props.clientKey}
            stripePublishableKey={this.props.stripePublishableKey}
            lists={this.props.lists}
            adminLists={this.props.adminLists}
            token={this.props.token} />
      case 'wedding details':
        if (this.props.subItem === 'photo_hours') {
          return (
            <PhotoHours
              content={this.props.content}
              token={this.props.token}
              user={this.props.user}
              answers={this.props.answers}
              answerTotal={this.props.answerTotal} />
          )
        } else if (this.props.subItem === 'flowers') {
          return (
            <FlowerQuestionnaire
              token={this.props.token}
              user={this.props.user}
              flowerRequest={this.props.flowerRequest} />
          )
        } else if (this.props.subItem === 'wedding_party_members') {
          return (
            <WeddingPartyContainer
              memberType='party'
              {...this.props} />
          )
        } else if (this.props.subItem === 'family_members') {
          return (
            <WeddingPartyContainer
              memberType='family'
              {...this.props} />
          )
        }
        else {
          return <Questionnaires {...this.props} />
        }
    default:
        return (
          <UserOverview
            { ...this.props } />
        )
      }
    }
  }

  render() {
    return (
      <div className='dashboard'>
        <DashboardNav
          page={this.props.page}
          user={this.props.user}
          token={this.props.token}
          clientKey={this.props.clientKey}
          stripePublishableKey={this.props.stripePublishableKey}
        />
        <div className='dashboard__content'>
          { this.dashboardPage() }
        </div>

      </div>
    )
  }
}

DashboardContainer.propTypes = {
  page: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  clientKey: PropTypes.string.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
}
