import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, useField, FieldArray } from 'formik';
import * as Yup from 'yup';
import { FormTextInput, FormSelect, FormPhoneInput } from '../../../../../forms/Helpers';
import { FormatPhone } from '../../../../../forms/PhoneFormatting';
import * as CONSTANTS from '../../../../../Constants'


export default class VendorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  venueTypeByUrl = () => {
    let venueLink = window.location.href;
    let splitPath = venueLink.split('/venues');
    if (splitPath.length > 1 && splitPath[1].includes('venue_type=')) {
      return splitPath[1].split('&venue_type=')[1]
    } else {
      return '';
    }
  }

  contactFieldArray = (values) => {
    return (
      <FieldArray
        name='contacts'
        render={arrayHelpers => (
          <div>
            {values.contacts &&
                values.contacts.length > 0 &&
                values.contacts.map((contact, index) => (
                    <div key={index} className='vendor-contact__add vendor-form__nested'>
                      { this.contactFields(index) }
                      { index > 0 &&
                        <button
                          className='mt-one-em vendor-types__btn vendor-types__btn--delete'
                          type='button'
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <i className="icon icon-minus-circle"></i>
                        </button>
                      }

                    </div>
                  ))}

                <button
                  className='vendor-types__btn vendor-types__btn--add mb-one-em'
                  type="button"
                  onClick={() => arrayHelpers.push({first_name: '', last_night: '', phone_number: '', email: '', contact_type: ''})}>
                  <i className='icon icon-plus-circle'></i>
                </button>
              </div>
            )}
          />
        )
    }

    venueLocation = (values) => {
      return (
        <div>
          <div className='field'>
            <FormTextInput
              name="location[street_address]"
              type="text"
              label="Street Address"
              placeholder="Street Address"
            />
          </div>

          <div className='field'>
            <FormTextInput
              name='location[city]'
              type='text'
              label='City'
              placeholder='City'
            />
          </div>
          <div className='field is-grouped'>
          {  values.location['country'] === 'United States' &&
            <FormSelect label='State' name='location[us_state]'>
                <option></option>
                 { CONSTANTS.AmericanStates().map((s, index) => {
                   return <option key={index} value={s.name}>{ s.name} </option>
                 })}
              </FormSelect>
          }
          <FormTextInput
            name="location[zip_code]"
            type="text"
            placeholder="Zip Code"
            label='Zip Code'
          />
          </div>

          <div className="field">
            <FormSelect label='Country' name='location[country]'>
                 { CONSTANTS.Countries().map((c, index) => {
                   return <option key={index} value={c.name}>{ c.name} </option>
                 })}
              </FormSelect>
          </div>
          <div className='field'>
            <FormPhoneInput
                label="Venue Phone Number"
                name='location[phone_number]'
                type="text"
                placeholder="Phone"
              />
          </div>
        </div>
      )
    }


    contactFields = (index) => {
      return (
        <div>
          <div className='field is-grouped'>
            <FormTextInput
              label="Contact First Name"
              name={`contacts[${index}][first_name]`}
              type="text"
              placeholder="First Name"
            />
            <FormTextInput
              label="Contact Last Name"
              name={`contacts[${index}][last_name]`}
              type="text"
              placeholder="Last Name"
            />
          </div>

          <div className='field is-grouped'>
            <FormTextInput
              label="Contact Email"
              name={`contacts[${index}][email]`}
              type="text"
              placeholder="Email"
            />
          <FormPhoneInput
              label="Contact Phone"
              name={`contacts[${index}][phone_number]`}
              type="text"
              placeholder="Phone"
            />
        </div>

          <div className='field'>
            <FormSelect label="Contact Type" name={`contacts[${index}][contact_type]`}>
                <option value=""></option>
                <option value={'in_office'}>In Office</option>
                <option value={'on_site'}>On Site</option>
            </FormSelect>
          </div>
        </div>
      )
  }

  vendorLocation = () => {
    let { isVenue, vendor } = this.props;
    if (isVenue && vendor && vendor.location) {
      return (
        { id: vendor.location.id,
          street_address: vendor.location.street_address,
          us_state: vendor.location.us_state,
          country: vendor.location.country,
          zip_code: vendor.location.zip_code,
          city: vendor.location.city,
          phone_number: vendor.location.phone_number,
          isVenue: true
        }
      )
    } else if (isVenue) {
      return (
        { street_address: '', us_state: '', country: 'United States', zip_code: '', city: '', phone_number: '', isVenue: true}
      )
    } else {
      return {};
    }
  }

  initialValues = () => {
    let { vendor } = this.props;
    if (vendor) {
      let contacts = vendor.contacts.map((c) => {
        return { first_name: c.first_name, last_name: c.last_name, email: c.email, contact_type: c.contact_type, phone_number: c.phone_number, id: c.id }
      })
      return (
        {
          vendorName: vendor.company_name,
          venue_type: vendor.venue_type,
          contacts: contacts,
          location: this.vendorLocation(),
        }
      )
    } else {
      return (
        {
          vendorName: '',
          venue_type: this.venueTypeByUrl(),
          vendorType: '',
          contacts: [{ first_name: '', last_name: '', email: '', contact_type: '', phone_number: ''}],
          location: this.vendorLocation(),
        }
      )
    }
  }

  locationSchema = () => {
    if (this.props.isVenue) {
      return Yup.object().shape({
          street_address: Yup.string().required('Required'),
          country: Yup.string().required(),
        })
    } else {
      return Yup.object()
    }
  }

  form = () => {
    let { isVenue } = this.props;
    return (
      <Formik
        initialValues={this.initialValues()}
        validationSchema={Yup.object({
          vendorName: Yup.string()
            .required('Required'),
          location: Yup.object().shape({
            street_address: Yup.string()
              .when('isVenue', {
                is: true,
                then: Yup.string().required('Required')
              }),
            country: Yup.string()
            .when('isVenue', {
              is: true,
              then: Yup.string().required('Required')
            })
          }),
          contacts: Yup.array()
            .of(Yup.object().shape({
              first_name: Yup.string().required('Required'),
              email: Yup.string()
                .email('Please enter a valid email address')
                .required('Required'),
              phone_number: Yup.string().required('Required')
            })
          )
        })}
        onSubmit={(values, { setSubmitting }) => {
            this.props.handleSubmit(values)
        }}
        render={({ values }) => (
          <Form>
            <div className='field'>
              <FormTextInput
                label='Name'
                name="vendorName"
                type="text"
                placeholder={this.props.isVenue ? 'Venue Name' : "Company Name"}
              />
            </div>

            { this.props.isVenue &&
              <>
              <div className='field'>
                <FormSelect label="Venue Type" name='venue_type'>
                    <option value=""></option>
                    <option value="ceremony">Ceremony</option>
                    <option value='reception'>Reception</option>
                    <option value='ceremony and reception'>Ceremony and Reception</option>
                </FormSelect>
              </div>
              { this.venueLocation(values) }
              </>
            }

            <div>
              { this.contactFieldArray(values) }
            </div>

            <button className='button button-primary' type="submit">Submit</button>
            <button
              className='button'
              onClick={this.props.closeForm}
              type="submit">Cancel
            </button>

            { this.props.vendor && this.props.vendor.id &&
              <button
                className='button button-delete'
                type='button'
                onClick={this.props.deleteVendor}>Delete
              </button>
            }
          </Form>
        )}
      >
      </Formik>
    );
  };

  render() {
    return (
      this.form()
    )
  }
}

VendorForm.propTypes = {
  vendorTypes: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired
}
