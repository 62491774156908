import React from 'react';
import PropTypes from 'prop-types';
import ActiveCustomer from './ActiveCustomer';

export default class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCustomer: null,
      searchTerm: ''
    }
  }

  customerList = () => {
    let { vendor } = this.props;
    let users = vendor.users;
    if (this.state.searchTerm.length > 1) {
      let term = this.state.searchTerm.toLocaleLowerCase();
      users = users.filter(u => `${u.first_name.toLocaleLowerCase()} ${u.last_name.toLocaleLowerCase()}`.includes(term))
    }

    return users.map((c) => {
      return (
        <div className='lists__card' key={c.id}>
          <p onClick={() => this.setState({ activeCustomer: c})}>{ c.first_name} {c.last_name}</p>
        </div>
      )
    })
  }

  render() {
    return (
      <div className='standard-padding dashboard__inner'>
        <div className='dashboard__left'>
          <div className='dashboard__container'>
            <h2 className='title is-2'>Clients</h2>
            <div className='field is-grouped vendors-list__search'>
              <div className='control has-icons-left is-expanded vendor-search'>
                <input
                  className='input'
                  type='text'
                  onChange={(e) => this.setState({ searchTerm: e.target.value, activeCustomer: null })}
                  value={this.state.searchTerm}></input>
                <span className='icon is-left'>
                  <i className='icon icon-search'></i>
                </span>
              </div>
            </div>
            <div className='lists__all'>
              { this.customerList() }
            </div>
            </div>
          </div>
        <div className='dashboard__right'>
          <div className='dashboard__container tasks-down'>
            <ActiveCustomer
              customer={this.state.activeCustomer}
              vendor={this.props.vendor}
              closeActiveCustomer={() => this.setState({ activeCustomer: null })} />
          </div>
        </div>
      </div>
    )
  }
}

CustomerList.propTypes = {
  vendor: PropTypes.object.isRequired
}
