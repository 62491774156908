import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Loading } from '../../../forms/Helpers';

export default class QuickLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      link: '',
      formOpen: false,
      editingLinkId: null,
      isNew: true,
      myLinksNumberToShow: 4,
      contractsNumberToShow: 4,
    }
  }

  linksList = (isContract, stateVariable) => {
    let { user } = this.props;
    let links = [];

    if (isContract) {
      links = user.quick_links.filter((l) => l.vendor_contract_id != null)
      .sort((a, b) => {
        if (a.name === b.name) {
          return 1;
        }
        return a.name > b.name ? 1 : -1;
      });

    } else {
      links = user.quick_links.filter((l) => l.vendor_contract_id === null);
    }
    if (links.length > 0) {
      let n = this.state[stateVariable];
      return (
        <>
          { links.slice(0,n).map((l) => {
              return this.linkContentToShow(l, isContract)
            })
          }
          { links.length > 4 && this.state[stateVariable] === 4 &&
            <a className='toggle-list' onClick={() => this.setState({ [stateVariable]: links.length })}>see all</a>
          }
          { links.length > 4 && this.state[stateVariable] > 4 &&
            <a className='toggle-list' onClick={() => this.setState({ [stateVariable]: 4 })}>close</a>
          }
        </>
      )
    } else {
      if (isContract) {
        return <p>You haven't added any contract links yet.</p>
      }
    }
  }

  linkContentToShow = (link, isContract) => {
    return (
      <div
        key={link.id}
        className='quick-links__item'
      >
        { isContract &&
          <p
            className='pointer'
            onClick={() => this.props.openContractLink(link)}
          >
            {link.name ? link.name : link.link}
          </p>
        }
        
        { !isContract &&
          <>
            <a
              target='blank'
              href={link.link}>{link.name ? link.name : link.link}
            </a>
            <div className='quick-links__actions'>
              <i
                className='icon icon-pencil clickable'
                onClick={() => this.setState({
                  isNew: false,
                  editingLinkId: link.id,
                  name: link.name,
                  link: link.link,
                  formOpen: !this.state.formOpen
                })}
              />
            </div>
          </>
        }
      </div>
    )
  }

  handleNewLinkChange = (field, value) => {
    this.setState({ [field]: value });
  }

  linkWithProtocol = () => {
    let { link } = this.state;
    if (link.includes('http://') || link.includes('https://')) {
      return link;
    } else {
      return `http://${link}`;
    }
  }

  handleSubmit = (e, isNew) => {
    e.preventDefault();
    this.setState({ isSubmitting: true }, () => {
      let url = '/api/v1/quick_links';
      let method = 'POST';
      if (isNew === false) {
        method = 'PATCH';
        url = `/api/v1/quick_links/${this.state.editingLinkId}`;
      }
      let linkWithProtocol = this.linkWithProtocol()
      axios({
        method: method,
        url: url,
        data: {
          quick_link: {
            name: this.state.name,
            link: linkWithProtocol
          },
          authenticity_token: this.props.token
        }
      })
      .then((result) => {
        this.setState({ isSubmitting: false, formOpen: false, name: '', link: '', editingLinkId: null }, () => {
          isNew === true ? this.props.handleNewLink(result.data.link) : this.props.handleUpdatedLink(result.data.link);
        })
      })
      .catch((error) => {
        this.setState({ isSubmitting: false })
      })
    })
  }

  deleteLink = () => {
    let { editingLinkId } = this.state;
    let link = this.props.user.quick_links.filter(l => l.id === editingLinkId)[0];
    let linkText = link.name && link.name.length > 0 ? `${link.name} link` : `link to ${link.link}`;
    if (confirm(`Are you sure you want to delete the ${linkText}?`)) {
      axios.delete(`/api/v1/quick_links/${link.id}`, {
        headers: {
          'X-CSRF-Token': this.props.token,
          'Content-Type': 'application/json'
        }
      })
      .then((result) => {
        this.props.handleDeletedLink(link);
        this.resetAndCloseForm();
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }

  resetAndCloseForm = () => {
    this.setState({ name: '', link: '', formOpen: false, editingLinkId: null, isNew: null })
  }

  formDisabled = () => {
    let { name, link } = this.state;
    if (!name || !link || name.length === 0 || link.length === 0) {
      return true;
    } else { return false }
  }

  linkForm = (isNew) => {
    if (this.state.isSubmitting) {
      return <Loading />
    } else if (this.state.formOpen){
      return (
        <div>
          <form onSubmit={(e) => this.handleSubmit(e, isNew)}>
            <div className='control'>
              <div className='field'>
                <input
                  className='input'
                  placeholder='Nickname'
                  value={this.state.name}
                  onChange={(e) => this.handleNewLinkChange('name', e.target.value)}></input>
              </div>
              <div className='field'>
                <input
                    className='input'
                    placeholder='URL'
                    value={this.state.link}
                    onChange={(e) => this.handleNewLinkChange('link', e.target.value)}></input>
              </div>
            </div>
            <div className='row-buttons row-buttons--nowrap'>
              <button
                type='button'
                className='button fifty'
                onClick={this.resetAndCloseForm}>cancel</button>
              <button
                type='submit'
                disabled={this.formDisabled()}
                className='button button-primary fifty'>submit</button>
            </div>
          </form>
          { isNew != true &&
            <span
              className='small-form-action small-form-action--delete'
              onClick={this.deleteLink}>delete</span>
          }

        </div>
      )
    }
  }


  render() {
    return (
      <div className='quick-links'>

        <article>
          <h4 className='subtitle is-4 has-text-centered section-header'>
            My Links
            {!this.state.isSubmitting && !this.state.formOpen &&
              <button
                style={{fontSize: '.75em', cursor: 'pointer'}}
                className='vendor-types__btn vendor-types__btn--add'
                type="button"
                onClick={() => this.setState({ formOpen: true, isNew: true })}>
                <i className='icon icon-plus-circle'></i>
              </button>
            }
          </h4>
          { !this.state.formOpen &&
            <div className='quick-links__list my-links'>
              { this.linksList(false, 'myLinksNumberToShow') }
            </div>
          }
          { this.linkForm(this.state.isNew) }
        </article>

        <article className="">
          <h4 className='subtitle is-4 has-text-centered section-header'>
            Contract Links
            <span data-tooltip="Contract links are added in the contract tab within a vendor page."
                  className="has-tooltip-multiline has-tooltip-left sm-tooltip"><i className='icon icon-question'></i></span>
          </h4>
          <div className='quick-links__list contract-links'>
            { this.linksList(true, 'contractsNumberToShow') }
          </div>
        </article>

      </div>
    )
  }
}

QuickLinks.propTypes = {

}
