import React from 'react';
import axios from 'axios';
import { Loading } from '../../../forms/Helpers';

export default class RelatedTaskModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: this.props.tasks,
      activeTask: this.props.activeTask,
      isSubmitting: false,
      batchSaveError:false,
      batchUpdatePrompt: false,
      statusForPatch: null,
      relatedTasksToCheckOff: [],
      relatedIdsToUpdate: [],
      relatedTagsToTasksObject: {},
    }
  }

  componentDidMount() {
    const { activeTask } = this.state;
    const relatedTasksToCheckOff = this.state.tasks.filter(t =>
      t.id != activeTask.id &&
      t.status != 'complete' &&
      t.tags.some(r => activeTask.tags.includes(r))
    );
    this.setState({ relatedTasksToCheckOff }, () => this.fillingTags());
  }

  closeModal = () => {
    this.setState(
      {
        batchUpdatePrompt: false, 
        isSubmitting: false, relatedTasksToCheckOff: [],
        relatedIdsToUpdate: [], relatedTagsToTasksObject: {},
      }, () => this.props.closeModal())
  }

  fillingTags = () => {
    const tagsSet = new Set();
    this.state.relatedTasksToCheckOff.forEach((i) => i.tags.forEach((t) => tagsSet.add(t) ));
    const uniqueTags = Array.from(tagsSet);
    const relatedTagsToTasksObject = {};
    uniqueTags.forEach( tag => {
      if(this.props.activeTask.tags.includes(tag)) {
        relatedTagsToTasksObject[tag] = []
      }
    });
    for ( let tag in relatedTagsToTasksObject) {
      this.state.relatedTasksToCheckOff.forEach(task => task.tags.forEach(t => {
        if (t === tag && this.props.activeTask.tags.includes(tag)) {
          relatedTagsToTasksObject[tag].push(task);
        }
      }))
    }
    this.setState({relatedTagsToTasksObject})
  }

  handleRelatedIds = (id) => {
    let relatedIdsToUpdate = [...this.state.relatedIdsToUpdate];
    if (relatedIdsToUpdate.includes(id) ){
      relatedIdsToUpdate.splice(relatedIdsToUpdate.indexOf(id), 1);
    } else {
      relatedIdsToUpdate.push(id);
    }
    this.setState({ relatedIdsToUpdate })
  }

  handleRelatedTasksCheckoff = (activeTask) => {
    let relatedTasksToCheckOff = this.props.tasks.filter(t => 
      t.id != activeTask.id &&
      t.status != 'complete' &&
      t.tags.some(r => activeTask.tags.includes(r)))
    if (relatedTasksToCheckOff.length > 0) {
      this.setState({ relatedTasksToCheckOff, relatedTaskModalOpen: true }, () => {
        this.props.handleUpdateSubmit();
        this.fillingTags();
      })
    } else {
      this.setState({ showProgressPrompt }, () => {
        this.props.handleUpdateSubmit();
        this.fillingTags();
      })
    }
  }

  handleMarkTasks = () => {
    const relatedIdsToUpdate = [...this.state.relatedIdsToUpdate];
    const relatedTasksToCheckOff = [...this.state.relatedTasksToCheckOff];
    if (relatedTasksToCheckOff.length === relatedIdsToUpdate.length) {
      this.setState({ relatedIdsToUpdate: [] })
    } else {
      this.setState({ relatedIdsToUpdate: relatedTasksToCheckOff.map((t) => t.id) });
    }
  }

  handleFieldUpdate = (field, val, submit = false) => {
    let activeTask = Object.assign({}, this.props.activeTask);
    activeTask[`${field}`] = val;
    let showProgressPrompt = field === 'status' && val === 'in_progress';
    if (submit === true) {
      if (activeTask.tags && activeTask.tags.length > 0 && val === 'complete') {
        this.handleRelatedTasksCheckoff(activeTask, showProgressPrompt)
      } else {
        this.setState({ showProgressPrompt }, () => {
          this.props.handleUpdateSubmit();
        })
      }

    } else {
      this.setState({ showProgressPrompt })
    }
  }

  handleRelatedIdsSubmit = (statusForPatch) => {
    this.setState({ isSubmitting: true, statusForPatch}, () => {
      axios.post('/api/v1/batch_update_users_checklist_items', {
        user_checklist_item_ids: this.state.relatedIdsToUpdate,
        authenticity_token: this.props.token,
        new_status: statusForPatch
      })
      .then((result) => {
        let tasks = [...this.props.tasks];
        let updatedTasks = tasks.filter(t => this.state.relatedIdsToUpdate.includes(t.id));
        if (updatedTasks.length > 0) {
          updatedTasks.forEach(t => t.status = statusForPatch);
        } else { updatedTasks = this.props.tasks }

        this.setState({ batchUpdatePrompt: true, isSubmitting: false })
      })
      .catch((error) => {
        this.setState({ batchSaveError: true, isSubmitting: false }, () => {
          setTimeout(() => this.closeModal(), 2000)
        })
      })
    })
  }

  titlesMarkedAsComplete = () => {
    let tasks = this.state.tasks.filter(i => this.state.relatedIdsToUpdate.includes(i.id))
    let titles = tasks.map(t => t.title)
    if (titles.length === 0) {
      return <p>{'You\'ve checked off:'}</p>
    }else if (titles.length === 1) {
      return <p>{`You've checked off: ${titles[0]}`}</p>
    } else if (titles.length === 2) {
      return <p>{`You've checked off: ${titles.join(' and ')}`}</p>
    } else {
      let lastItem = titles.pop();
      return <p>{`You've checked off: ${titles.join(', ')}, and ${lastItem}.`}</p>
    }
  }

  taskTitle = () => {
    if (this.props.activeTask != null) {
      return this.props.activeTask.title;
    }
  }

  renderCheckbox = () => {
    return (
      <div className='field'>
        <input
          checked={
            this.state.relatedTasksToCheckOff.length === this.state.relatedIdsToUpdate.length
          }
          style={{marginRight: '5px'}}
          onChange={this.handleMarkTasks}
          type='checkbox'
          id='select_all'
          name='Select all'
        />
        <label htmlFor='select_all'>
          Select all
        </label>
      </div>
    );
  }

  render() {
    return (
      <>
        { this.state.isSubmitting &&
          <Loading />
        }
        { this.state.batchUpdatePrompt &&
          <div>
            <h5 className='is-5 subtitle' style={{marginBottom: '0.5em'}}>
              { `Congratulations, you\'ve ${this.state.statusForPatch === 2
                ? 'completed more tasks.'
                : 'done more tasks as not applicable.'}`
              }
            </h5>
            { this.titlesMarkedAsComplete() }
            <p className='mb-one-em mt-one-em'>What would you like to do next?</p>
            <a className='modal-link' style={{color: '#9d7188'}}href='/vendors'>Add a vendor</a>
            {!this.props.isOnTasks
            ? <a href='/tasks' className='modal-link'>See my tasks</a>
            : <a
                onClick={this.closeModal}
                className='modal-link'
              >
                  See my tasks
              </a>
            }
            <a className='modal-link' href='/'>Go to dashboard</a>
            <a className='modal-link' onClick={this.closeModal}>Close window</a>
          </div>
        }
        { !this.state.isSubmitting && !this.state.batchUpdatePrompt &&
          <div className='dashboard-modal'>
            { this.state.batchSaveError &&
              <p className='error'>There was a problem updating these items. Please try again later.</p>
            }
            <h5 className='is-5 subtitle'>{ `Completed task: ${this.taskTitle()}` }</h5>
            <h5 className='is-5 subtitle'>Would you like to check off any of these related items?</h5>
            { this.renderCheckbox() }
            { Object.entries(this.state.relatedTagsToTasksObject).map(([tag, tasks], index) => {
              return (
                <React.Fragment key={tag + index}>
                  <h5>{tag}</h5>
                  { tasks.map((task, index) => {
                    return (
                      <div key={tag + task.id + index}>
                        <input
                          checked={this.state.relatedIdsToUpdate.includes(task.id)}
                          style={{marginRight: '5px'}}
                          onChange={() => this.handleRelatedIds(task.id)}
                          type='checkbox'
                          id={task.id}
                          name={task.title}
                        />
                        <label htmlFor={task.id}>{task.title}</label>
                      </div>
                    )
                  })}
                </React.Fragment>
              )
            })
            }
            <div
              className='mt-one-em pb-one-em'
            >
              <button
                type='button'
                onClick={() => this.handleRelatedIdsSubmit(2)}
                className='button mb-1em  button-primary'
              >
                submit as completed
              </button>

              <button
                type='button'
                onClick={() => this.handleRelatedIdsSubmit(3)}
                className='button mb-1em  button-primary'
              >
                submit as not applicable
              </button>

              <button
                type='button'
                onClick={this.closeModal}
                className='button mb-1em '
              >
                no, thank you
              </button>
            </div>
          </div>
        }
      </>
    )
  }
}
