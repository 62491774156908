import React from 'react';

export const FormResponse = (message, code) => {
  if (message && code) {
    return (
        <span className={"form-response form-response--" + (code === 200 ? 'success' : 'failure') }>
          { message }
        </span>
    )
  } else {
    return null;
  }
};

export function handleResponse($this, responseMessage, responseCode, timeoutLength) {
  $this.setState({ responseMessage, responseCode, isSubmitting: false }, () => {
    $this.formResponse = setTimeout(() => {
      $this.setState({ responseMessage: null, responseCode: null});
    }, timeoutLength)
  });
}

export function errorsFormatter(errorsArray) {
  if (Array.isArray(errorsArray) && errorsArray && errorsArray.length > 0) {
    let messages = [];
    errorsArray.map((err) => {
      let fieldName = err.field.split('_').join(' ')
      fieldName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
      messages.push(`${fieldName} ${err.message.split('_').join(' ')}.`)
    })
    return messages.join(" ");
  }
}
