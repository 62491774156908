import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Loading } from '../../../forms/Helpers';
import { FormResponse, handleResponse } from '../../../forms/Response';

export default class AdminListToCopy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false
    }
  }

  addToMyLists = () => {
    this.setState({ isSubmitting: true }, () => {
      axios.post('/api/v1/copy_admin_list_to_user_list', {
        admin_generated_list_id: this.props.list.id,
        user_id: this.props.user.id,
        authenticity_token: this.props.token
      })
      .then((response) => {
        this.props.handleCopiedList(response.data.list)
      })
      .catch((error) => {
        this.setState({ isSubmitting: false }, () => {
          handleResponse(this, 'There was a problem copying this list.', 422, 4000);
        })
      })
    })

  }


  render() {
    let { list } = this.props;
    return (
      <div className='active-card'>
        { this.state.isSubmitting &&
          <Loading />
        }
        { !this.state.isSubmitting &&
          <>
            <i className="icon icon-close is-pulled-right clickable" onClick={this.props.close}></i>
            <h4 className='title is-4 clickable'>{ list.name }</h4>
            { FormResponse(this.state.responseMessage, this.state.responseCode) }

            { list.items && list.items.sort((a, b) => (a.name > b.name) - (a.name < b.name)).map(i => {
              return (
                <div className='list-items__item list-items__item--disabled' key={i.id}>
                  <div>
                    <span>{i.name}</span>
                  </div>
                </div>
              )
            }) }

            <div className='mt-one-em'>
              <button
                onClick={this.props.close}
                className='button'>cancel</button>
              <button
                className='button button-primary'
                onClick={this.addToMyLists}>add to my lists</button>
            </div>
          </>
        }
      </div>
    )
  }
}

AdminListToCopy.propTypes = {
  close: PropTypes.func.isRequired,
  handleCopiedList: PropTypes.func.isRequired
}
