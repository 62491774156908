import React from 'react';

export function tasksByDate(tasks) {
  tasks.sort((a, b) => {
    return Date.parse(a.due_date) > Date.parse(b.due_date) ? 1 : Date.parse(a.due_date) < Date.parse(b.due_date) ? -1 : 0
  })
  return tasks;
}

export const TASK_STATUSES = {
  'incomplete': 0,
  'in_progress': 1,
  'complete': 2,
  'n/a': 3,
}
export const UNFINISHED_STATUSES = [TASK_STATUSES.incomplete, TASK_STATUSES.in_progress];
