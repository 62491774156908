import React from 'react';
import PropTypes from 'prop-types';
import { FormResponse } from '../../../../../forms/Response';
import CheckoutModal from '../../../../../adyen/CheckoutModal';

export default class ActiveLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false
    }
  }

  linkWithProtocol = (link) => {
    if ( link ){
      if ( link.includes('http://') || link.includes('https://')) {
      return link;
    } 
    } else {
      return `http://${link}`;
    }
  }

  render() {
    const link = this.props.link;
    if (link) {
      return (
        <div className='active-card'>
          <h4 className='title is-4 has-text-centered'>{ link.name }</h4>
          <div className='has-text-centered'>
            { (link.password || link.login) && 
              <div className='contract-doc-auth'>
                <div className='field'>
                  <label className="label" htmlFor="login">Login</label>
                  <input
                    disabled
                    className="text-input input pointer"
                    name="login"
                    type="text"
                    placeholder="Login"
                    value={link.login}
                  />
                </div>

                <div className='field'>
                  <label className="label" htmlFor="password">Password</label>
                  <input
                    disabled
                    className="text-input input pointer"
                    name="password"
                    type="text"
                    placeholder="Password"
                    value={link.password}
                  />
                </div>
              </div>
            }
            { link.note_to_contract &&
              <div className='field'>
                <label className="label" htmlFor="note">Note</label>
                  <input
                    disabled
                    className="text-input input pointer"
                    name="note"
                    type="text"
                    placeholder="Note"
                    value={link.note_to_contract}
                  />
              </div>
            }
            <div className='field'>
              <a
                target='blank'
                href={ this.linkWithProtocol(link.link) }
              >
                link to contract
              </a>
            </div>
          </div>
          <CheckoutModal
            token={this.props.token}
            clientKey={this.props.clientKey}
            stripePublishableKey={this.props.stripePublishableKey}
            user={this.props.user}
            modalIsOpen={this.state.modalIsOpen}
            closeModal={() => this.setState({ modalIsOpen: false })} />
        </div>
      )
    } else {
      return (
        <div className='active-card'>
          <h4 className='title is-4 has-text-centered'>No link selected.</h4>
        </div>
      )
    }

  }
}

ActiveLinks.propTypes = {
  link: PropTypes.object,
  user: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  clientKey: PropTypes.string.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
}
