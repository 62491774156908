import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Modal from 'react-modal';
import CheckoutModal from '../../adyen/CheckoutModal';
import { FormResponse, handleResponse, errorsFormatter } from '../../forms/Response';
import { formatMoney } from '../../HelperFunctions';


export default class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      openField: null,
      email: props.user.email,
      first_name: props.user.first_name,
      last_name: props.user.last_name,
      partner_first_name: props.user.partner_first_name,
      partner_last_name: props.user.partner_last_name,
      wedding_date: props.user.wedding_date,
      budget: props.user.budget,
      number_of_guests: props.user.number_of_guests,
      current_password: '',
      password: '',
      password_confirmation: '',
      passwordToDelete: '',
      isConfirmedWeddingDate: false,
      weddingDateConfirmationModal: false,
      width: window.innerWidth,
      modalIsOpen: false,
      renewSubscription: false,
    }
    this.accountTop = React.createRef();
  }

  componentDidMount() {
    if (typeof(window) !== 'undefined') {
      Modal.setAppElement('body')
    }
    window.addEventListener('resize', this.updateWidth);
  }

  updateWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  disabledUserFields = () => {
    let fields = [
      { label: 'email', value: this.state.email },
      { label: 'first_name', value: this.state.first_name},
      { label: 'last_name', value: this.state.last_name},
      { label: 'partner_first_name', value: this.state.partner_first_name },
      { label: 'partner_last_name', value: this.state.partner_last_name },
      { label: 'wedding_date', value: this.state.wedding_date },
      { label: 'budget', value: this.state.budget },
      { label: 'number_of_guests', value: this.state.number_of_guests }
    ]
    return fields.map(f => this.disabledField(f.label, f.value));
  }

  disabledField = (label, value) => {
    return (
      <div key={label} className="field is-horizontal account__field">
        <div className="field-label is-normal">
          <label className="label">{label.split('_').join(' ')}</label>
        </div>
        <div className={"field-body control " + (this.state.isSubmitting && this.state.openField === label ? 'is-loading' : '')}>
          <div className="field">
            { this.inputToShow(label, value) }
          </div>
        </div>
      </div>
    )
  }

  submitFieldChange = () => {
    let { openField, isSubmitting, isConfirmedWeddingDate } = this.state;
    if (openField === 'wedding_date' && !isConfirmedWeddingDate) {
      this.setState({weddingDateConfirmationModal: true});
      return;
    }
    if (isSubmitting != true) {
      this.setState({ isSubmitting: true }, () => {
        let user = { id: this.props.user.id }
        user[`${openField}`] = this.state[openField];
        axios.patch(`/api/v1/users/${this.props.user.id}`, {
          user,
          authenticity_token: this.props.token
        })
        .then((result) => {
          let user = Object.assign({}, this.state.user);
          user[openField] = this.state[openField];
          this.setState({ isSubmitting: false, isConfirmedWeddingDate: false, weddingDateConfirmationModal: false,
            user, checkedField: openField, openField: null },
            () => {setTimeout(() => this.setState({ checkedField: null }), 2000)
          })
        })
        .catch((error) => {
          let errorResp;
          if (error.response && error.response.data && error.response.data.errors) {
            errorResp = error.response.data.errors;
          } else { errorResp = 'There was a problem updating this information.' }
          let message = errorsFormatter(errorResp) || 'There was a problem updating this information.'
          handleResponse(this, message, 422, 4000)
        })
      })
    }
  }

  passwordChangeResponse = () => {
    if (this.state.passwordResponseSuccess) {
      return <p className='success'>Succesfully changed your password.</p>
    } else if (this.state.passwordResponseError) {
      return <p className='error'>There was a problem changing your password. { this.state.passwordResponseError }</p>
    }
  }

  submitPasswordChange = () => {
    if (this.state.isSubmitting != true) {
      this.setState({ isSubmitting: true }, () => {
        axios.patch(`/api/v1/users/${this.props.user.id}/password`, {
          user: {
            id: this.props.user.id,
            password: this.state.password,
            password_confirmation: this.state.password_confirmation,
            current_password: this.state.current_password
          },
          authenticity_token: this.props.token
        })
        .then((response) => {
          this.setState({ isSubmitting: false, passwordResponseSuccess: true }, () => {
            setTimeout(() =>
              this.setState({ passwordResponseSuccess: null }), 2000
            )
          })
        })
        .catch((error) => {
          this.accountTop.current.scrollIntoView({ behavior: 'smooth' });

          let message = '';
          if (error.response && error.response.data && error.response.data.error) {
            message = error.response.data.error;
          }
          this.setState({ isSubmitting: false, current_password: '', passwordResponseError: message }, () => {
            setTimeout(() => this.setState({ passwordResponseError: null }), 3000)
          })
        })
      })
    }
  }

  handleFieldChange = (e, label) => {
    this.setState({ [label]: e.target.value });
  }

  inputToShow = (label, value) => {
    if (this.state.openField === label) {
      return (
        <>
          <input
            className='input' type={label === 'wedding_date' ? 'date' : 'text'}
            value={value}
            onChange={(e) => this.handleFieldChange(e, label)}></input>
          <div className='row-buttons'>
            <button
              onClick={() => this.setState({ openField: null, [label]: this.state.user[label] })}
              className='button button-small'>cancel</button>
            <button
              disabled={this.state[label] === this.state.user[label]}
              onClick={this.submitFieldChange}
              className='button button-primary button-small'>save</button>
          </div>
        </>
      )
    } else {
      return (
        <p className="control has-icons-right">
          <span className="input">
            { label === 'wedding_date'
              ? this.formattedDate(value)
              : label === 'budget'
                ? formatMoney(value)
                : value }
          </span>
          <span className="icon is-small is-right">
            <i
              className={"icon icon-" + (this.state.checkedField === label ? 'checkmark-outline' : 'pencil')}
              onClick={() => this.toggleFieldOpen(label)}></i>
          </span>
        </p>
      )
    }
  }

  formattedDate = (d) => {
    let splitDate = d.split('-');
    return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
  }

  toggleFieldOpen = (label) => {
    this.setState({ openField: label })
  }

  passwordFields = () => {
    let fields = ['current_password', 'password', 'password_confirmation'];
    return fields.map((field) => {
      return (
        <div className="field is-horizontal">
          <div className="field-label is-normal" style={{textAlign: 'left'}}>
            <label className="label">{field === 'password' ? 'New Password' : field.split('_').join(' ')}</label>
          </div>
          <div className="field-body">
            <div className="field">
              <input
                onBlur={field === 'current_password' ? null : this.validatePasswordConfirmation }
                className='input' type='password'
                onChange={(e) => this.setState({ [field]: e.target.value })}
                value={this.state[field]}></input>
              { field === 'password' &&
                <span className='is-size-7'>(min 8 characters)</span>
              }
            </div>
          </div>
        </div>
      )
    })
  }

  validatePasswordConfirmation = () => {
    let { password, password_confirmation } = this.state;
    if (password.length >= 8 && password_confirmation.length > 0 && password != password_confirmation) {
      this.setState({ passwordError: 'Password and password confirmation do not match' })
    } else if (password.length > 0 && password.length < 8) {
      this.setState({ passwordError: 'Password must be at least 8 characters.' })
    }
      else {
      this.setState({ passwordError: null })
    }
  }

  passwordErrorText = () => {
    let { passwordError } = this.state;
    if (passwordError) {
      return <p className='error'>{ passwordError }</p>
    }
  }

  changePassword = () => {
    if (this.state.openField === 'password') {
      return (
        <div className='acts-as-form account__password-change'>
           { this.passwordChangeResponse() }
           { this.passwordFields() }
           { this.passwordErrorText() }
          <div className='row-buttons'>
            <button
              onClick={() => this.setState({ openField: null, password: '', password_confirmation: '', current_password: '' })}
              className='button button-small'>cancel</button>
            <button
              onClick={this.submitPasswordChange}
              disabled={this.passwordReady()}
              className='button button-primary button-small'>save</button>
          </div>
        </div>
      )
    } else {
      return (
        <span
          className='account__password-prompt'
          onClick={() => this.toggleFieldOpen('password')}>Change password</span>
      )
    }
  }

  passwordReady = () => {
    let { password, password_confirmation, current_password } = this.state;
    if (current_password.length > 0 && password.length >= 8 && password_confirmation === password ) {
      return false;
    } else {
      return true;
    }
  }

  cancelSubscription = () => {
    if (confirm('Are you sure you want to cancel your subscription? This cannot be undone.')) {
      axios.post('/cancel_subscription', {
        user: {
          id: this.props.user.id
        },
        authenticity_token: this.props.token
      })
      .then((response) => {
        this.setState({
          user: response.data.user,
          openField: null
        })
      })
      .catch((error) => {
        console.log(error);
      })
    } else {
      this.setState({ openField: null })
    }
  }

  subscriptionInputToShow = (activeSubscription) => {
    if (this.state.openField === 'subscription') {
      return (
        <>
          <div className='row-buttons'>
            <button
              onClick={this.cancelSubscription}
              className='button button-primary button-small'>cancel subscription</button>
              <button
                onClick={() => this.setState({ openField: null })}
                className='button button-small'>close</button>
          </div>
        </>
      )
    } else if (activeSubscription.next_charge_date) {
      return (
        <p className="control has-icons-right">
          <span className="input">Next Charge on { this.formattedDate(activeSubscription.next_charge_date)}</span>
          <span className="icon is-small is-right">
            <i
              className="icon icon-pencil"
              onClick={() => this.toggleFieldOpen('subscription')}></i>
          </span>
        </p>
      )
    } else if (activeSubscription.next_charge_date === null && activeSubscription.cancelled_date) {
      return (
        <p className="control has-icons-right">
          <span className="input">Your subscription will end on {this.formattedDate(activeSubscription.cancelled_date)}</span>
          <button
            className='button button-small button-primary'
            onClick={() => this.setState({ modalIsOpen: true, renewSubscription: true })}>Renew your subscription</button>
        </p>
      )
    }
  }

  noSubscriptionsDisplay = (user) => {
    let inactiveWithTimeLeft = false;
    let inactiveWithoutTimePresent = false;
    let expiresDate;
    let expiredDate;
    if (user.subscriptions.length > 0) {
      let cancelled = user.subscriptions.filter(s => s.is_active === false);
      cancelled.map(c => {
        if (Date.parse(c.next_charge_date) > new Date()) {
          expiresDate = c.next_charge_date;
          inactiveWithTimeLeft = true;
        } else if (c.cancelled_date) {
          inactiveWithoutTimePresent = true;
          expiredDate = c.cancelled_date;
        }
      })
    }

    if (inactiveWithTimeLeft) {
      return (
        <div className='mb-one-em mt-one-em'>
          <p className='mb-quarter-em'>Your subscription will end on { this.formattedDate(expiresDate) }. </p>
          <button className='button button-small button-primary'>Renew Your Subscription</button>
        </div>
      )
    } else if(inactiveWithoutTimePresent) {
      return (
        <div className='mb-one-em mt-one-em'>
          <p className='mb-quarter-em'>Your subscription ended on { this.formattedDate(expiredDate) }. </p>
          <button
            className='button button-small button-primary'
            onClick={() => this.setState({ modalIsOpen: true, renewSubscription: false})}>Upgrade</button>
        </div>
      )
    }

    return (
      <>
        <button
          className='button button-small button-primary'
          onClick={() => this.setState({ modalIsOpen: true, renewSubscription: false})}>Upgrade</button>
      </>
    )
  }

  subscription = () => {
    let { user } = this.state;
    let activeSubscription = user.subscriptions.filter(s => s.is_active === true && s.is_cancelled != true);
    return (
      <>
      <div className="field is-horizontal account__field">
        <div className="field-label is-normal">
          <label className="label">Subscription</label>
        </div>
        <div className="field-body control">
          <div className="field">
            { activeSubscription.length > 0 &&
                this.subscriptionInputToShow(activeSubscription[activeSubscription.length - 1])
            }
            { activeSubscription.length === 0 &&
              this.noSubscriptionsDisplay(user)
            }
          </div>
        </div>
      </div>
      </>
    )
  }

  deleteAccount = () => {
    if (confirm('Are you sure you want to delete your account?')) {
      axios({
        url: '/users',
        method: 'DELETE',
        data: {password: this.state.passwordToDelete},
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.props.token,
          'Accept': 'application/json'
        }
      })
      .then((response) => {
        window.location.replace('/');
      })
      .catch((error) => {
        this.setState({ passwordToDelete: '' }, () => {
          this.accountTop.current.scrollIntoView({ behavior: 'smooth' });
          let msg;
          if (error.response && error.response.data && error.response.data.errors) {
            msg = error.response.data.errors;
          } else { msg = 'There was a problem deleting your account.' }
          let message = errorsFormatter(msg) || 'There was a problem deleting your account.'
          handleResponse(this, message, 422, 4000);
        })
      })
    }
  }

  deleteAccountPrompt = () => {
    if (this.state.showDeletePrompt != true) {
      return (
        <div>
          <span
            onClick={() => this.setState({ showDeletePrompt: true })}
            style={{padding: '5px 10px'}}
            className='error clickable account__act-prompt'>Delete my account</span>
        </div>
      )
    } else {
      return (
        <div>
          <p className='mb-one-em has-text-weight-bold error'>Deleting your account is permanent and will delete all of your data.</p>
          { !this.props.user.provider &&
            <div>
              <p className='mb-quarter-em'>Please enter your password to delete your account.</p>
              <div className='field'>
                <input
                  className='input mb-one-em'
                  type='password'
                  value={this.state.passwordToDelete}
                  onChange={(e) => this.setState({ passwordToDelete: e.target.value })}></input>
              </div>
            </div>
          }
          <button
            onClick={() => this.setState({ showDeletePrompt: false, passwordToDelete: null})}
            className='button button-small'>Cancel</button>
          <button
            disabled={this.state.passwordToDelete.length < 5 && !this.props.user.provider}
            onClick={this.deleteAccount}
            className='button button-small button-delete'>Delete My Account</button>
        </div>
      )
    }
  }


  render() {
    let { user, responseMessage, responseCode, isSubmitting, isConfirmedWeddingDate } = this.state;
    const confirmationModalStyle = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '1rem 2rem',
        background: 'white',
        border: 'solid 3px',
        borderRadius: 0,
        width: `${ this.state.width > 992 ? '50%' : this.state.width > 776 ? '80%' : '90%'}`,
        fontWeight: 'bold',
      }
    }
    const confirmationModalClose = () => this.setState({ isConfirmedWeddingDate: false, weddingDateConfirmationModal: false });
    return (
      <div className='account' ref={this.accountTop}>
        <div className='standard-padding'>
          <h3 className='title is-3 has-text-centered'>Account Settings</h3>

          <div className='mb-one-em'>
            { FormResponse(responseMessage, responseCode) }
          </div>

          <div className='account__edit'>
            { this.disabledUserFields() }
            { this.subscription() }
            { !user.provider &&
              this.changePassword()
            }
            { this.deleteAccountPrompt() }

            <CheckoutModal
              user={this.props.user}
              token={this.props.token}
              clientKey={this.props.clientKey}
              stripePublishableKey={this.props.stripePublishableKey}
              modalIsOpen={this.state.modalIsOpen}
              closeModal={() => this.setState({ modalIsOpen: false, renewSubscription: false })}
              renewSubscription={this.state.renewSubscription}
              paywallText="With a Guided Bride subscription, you can..."
              paywallHeader="Unlock all of Guided Bride's Features."
            />
            <Modal
              isOpen={this.state.weddingDateConfirmationModal}
              onRequestClose={confirmationModalClose}
              style={confirmationModalStyle}>
              <div className='dashboard-modal'>
                <p className='field'>
                Changing the wedding date will update all of your Guided Bride task due dates.
                </p>
                <p className='field'>
                  If you have manually entered due dates or contracts with payment reminders, you will need to update those manually.
                  They will not be automatically updated.
                </p>
                <div className='field'>
                  <label className='checkbox'>
                    <input
                      onChange={(e) => this.setState({ isConfirmedWeddingDate: e.target.checked })}
                      value={isConfirmedWeddingDate}
                      type='checkbox'
                      style={{marginRight: '10px'}} />
                    I agree
                  </label>
                </div>
                <div className='has-text-centered'>
                  <button
                    onClick={confirmationModalClose}
                    className="button button-secondary button-full-on-sm mt-one-em"
                  >Cancel</button>
                  <button
                    onClick={this.submitFieldChange}
                    disabled={!isConfirmedWeddingDate || isSubmitting}
                    className="button button-primary button-full-on-sm mt-one-em"
                  >Submit</button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    )
  }
}

Account.propTypes = {
  clientKey: PropTypes.string.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
}
