import React from 'react';
import PropTypes from "prop-types"

export default class VideosOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  videosList = () => {
    return this.props.videos.map((v) => {
      return (
        <div key={v.id}>
          { v.thumbnail &&
            <img
              onClick={() => this.props.setActiveVideo(v)}
              src={v.thumbnail} />
          }
          <span onClick={() => this.props.setActiveVideo(v)}>{ v.title }</span>
        </div>
      )
    })
  }

  render() {
    return (
      <div>
        <h4>select a video to play</h4>
        { this.videosList() }
      </div>

    )
  }
}

VideosOptions.propTypes = {
  videos: PropTypes.array.isRequired,
  setActiveVideo: PropTypes.func.isRequired,
  activeVideo: PropTypes.object.isRequired
};
