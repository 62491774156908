import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { modalStyle } from './ModalStyle';

export default class GuideModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWidth);
  }

  updateWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  render() {
    let defaultText = 'What would you like to do next?'
    let text = this.props.preText ? `${this.props.preText} ${defaultText}` : defaultText;
    if (typeof(window) !== 'undefined') {
      Modal.setAppElement('body')
    }
    return (
      <Modal
        isOpen={true}
        onRequestClose={this.props.closeModal}
        style={modalStyle(this.state.width, '#F3F1EE')}>
        <div className='dashboard-modal'>
          <i
            className='icon icon-close clickable'
            style={{float: 'right'}}
            onClick={this.props.closeModal}></i>
          <h3 className='is-3 title'>{text}</h3>
          <div className='guide__options'>
            { this.props.options.map((o, i) => {
              if (o.link === 'btnClose') {
                return (
                  <div key={i} className='mb-one-em'>
                    <a style={{color: '#916950' ,fontSize: '1.2em'}} onClick={this.props.closeModal}>{o.title}</a>
                  </div>
                )
              }
              return (
                <div key={i}  className='mb-one-em'>
                  <a style={{fontSize: '1.2em'}} href={o.link}>{o.title}</a>
                </div>
              )
            })}
          </div>
        </div>
      </Modal>


    )
  }
}

GuideModal.propTypes = {
  options: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  preText: PropTypes.string
}
