import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FormResponse, handleResponse, errorsFormatter } from '../../../../forms/Response';
import WeddingPartyMemberForm from './WeddingPartyMemberForm';

export default class WeddingPartyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      people: this.props.people,
      showForm: false
    }
  }

  handleSubmit = (values) => {
    let isFamily = this.isFamily();
    let url = isFamily ? '/questionnaires/family_members' : '/questionnaires/wedding_party_members';
    let method = 'POST';
    let id = null;
    if (this.state.activePerson) {
      url = `${url}/${this.state.activePerson.id}`;
      id = this.state.activePerson.id;
      method = 'PATCH';
    }

    axios({
      method: method,
      url: url,
      data: {
        [`${isFamily ? 'family_member' : 'wedding_party_member'}`]: {
          id: id,
          user_id: this.props.user.id,
          name: values.name,
          title: values.title,
          wedding_party_side: values.wedding_party_side
        },
        authenticity_token: this.props.token
      }
    })
    .then((response) => {
      let people = [...this.state.people];
      if (this.state.activePerson) {
        people[people.indexOf(this.state.activePerson)] = response.data.person
      } else {
        people.push(response.data.person)
      }
      this.setState({ people, showForm: false, activePerson: null })
    })
    .catch((error) => {
      console.log(error);
    })
  }

  deletePerson = () => {
    if (confirm(`Are you sure you want to delete ${this.state.activePerson.name} from your wedding party?`)) {
      let url = `/questionnaires/${this.isFamily() ? 'family_members' : 'wedding_party_members'}/${this.state.activePerson.id}`
      axios.delete(url, {
        headers: {
          'X-CSRF-Token': this.props.token,
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        let people = [...this.state.people];
        people.splice(people.indexOf(this.state.activePerson), 1);
        this.setState({ people, showForm: false, activePerson: null })
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }

  isFamily = () => {
    return this.props.memberType === 'family';
  }

  weddingPartyListBySide = (side) => {
    return this.state.people.filter(w => w.wedding_party_side === side).map((m) => {
      return (
        <div
          className='lists__card questionnaires__card'
          key={m.id}
          onClick={() => this.setState({ activePerson: m, showForm: true })}>
            <span>{m.name}</span> <span className='divider' style={{margin: '0 10px'}}>|</span><span style={{fontSize: '0.8em'}}>{m.title}</span>
        </div>
      )
    })
  }

  weddingPartyList = () => {
    let { people } = this.state;
    let columnSize = this.props.memberType === 'party' ? 'is-one-third' : 'is-half'
    if (people && people.length > 0) {
      return (
        <div className='wedding-party__container'>
          <div className='columns family-members'>
            <div className={'column has-text-centered wedding-party__col ' + columnSize}>
              <h5 className='is-5 is-subtitle'>{ `${this.props.user.first_name}'s` } Side</h5>
              { this.weddingPartyListBySide(1) }
            </div>
            <div className={'column has-text-centered wedding-party__col ' + columnSize}>
              <h5 className='is-5 is-subtitle'>{ `${this.props.user.partner_first_name}'s` } Side</h5>
              { this.weddingPartyListBySide(2) }
            </div>
            { this.props.memberType === 'party' &&
              <div className='column is-one-third has-text-centered wedding-party__col'>
                <h5 className='is-5 is-subtitle'>Neither Side</h5>
                { this.weddingPartyListBySide(3) }
              </div>
            }
          </div>
        </div>
      )
    } else {
      return (
        <p className='is-5 title mt-one-em'>Get Started By Adding Your First { this.isFamily() ? 'Family Member' : 'Wedding Party Member'}!</p>
      )
    }
  }

  render() {
    return (
      <div className='dashboard__container'>
        { !this.state.showForm &&
          <div className='wedding-party__list'>
            <a className='link-back' href='/questionnaires'>Back to Wedding Details</a>
              { this.isFamily()
                ? <div className='header-with-tooltip'>
                  <h4 className='is-4 title mb-one-em'>Your VIP Family Members</h4>
                  <span data-tooltip='Include family members that will either be walking down the aisle or will be honored by wearing flowers provided by your florist.'
                        className="has-text-weight-normal has-tooltip-multiline has-tooltip-right"><i className='icon icon-question'></i></span>
                  </div>
                : <h4 className='is-4 title mb-one-em'>Wedding Party</h4>

              }

              <div className='mt-one-em'>
                  <button
                    className='button button-primary'
                    onClick={() => this.setState({ showForm: true })}>Add {this.isFamily() ? 'Family Member' : 'Party Member'}</button>
                </div>
            { this.weddingPartyList() }
          </div>
        }
        { this.state.showForm &&
          <WeddingPartyMemberForm
            user={this.props.user}
            isFamily={this.isFamily()}
            deletePerson={this.deletePerson}
            person={this.state.activePerson}
            handleSubmit={this.handleSubmit}
            closeForm={() => this.setState({ showForm: false, activePerson: null })} />
        }
      </div>
    )
  }
}

WeddingPartyContainer.propTypes = {

}
