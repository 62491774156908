import React from 'react';

export function weddingStyle(isView, $this) {
  let options = [
    'whimsical',
    'bohemian',
    'classic',
    'symmetrical',
    'uniformed',
    'beachy',
    'glamorous',
    'minimalist',
    'romantic',
    'rustic',
    'vintage'
  ];

  return (
    <div className='field active-card active-card--centered'>
      <h4 className="is-4 title has-text-centered quiz__header">Wedding Style</h4>
      <fieldset className="control">
      { !isView && <span className="has-text-centered is-block mb-one-em">Select all that apply.</span> }
      { checkboxOptions(isView, $this, 'wedding_style', options) }
      </fieldset>
    </div>
  )
}

export function flowersForBrideAndGroom(isView, $this, field, personName) {
  let options = ['Bouquet', 'boutonnière', 'flower crown', 'hair flowers (loose)', 'hair flowers (comb)', 'pin corsage', 'wrist corsage'];
  return (
    <div className='field active-card active-card--centered'>
      <h4 className="is-4 title has-text-centered quiz__header">
        Flowers for {personName}
      </h4>
      <fieldset className="control">
        { !isView && <span className="has-text-centered is-block mb-one-em">Select all that apply.</span> }
        { checkboxOptions(isView, $this, field, options) }
      </fieldset>
    </div>
  )
}

export function checkboxOptions(isView, $this, field, options, nestedObjectAttribute=null,objectIndex=null) {
  if (isView) {
    options = $this.state[field]
  }
  return options.map((option) => {
    const isChecked = () => {
      return (nestedObjectAttribute ?
        $this.state[field][objectIndex][nestedObjectAttribute] && $this.state[field][objectIndex][nestedObjectAttribute].includes(option) :
        $this.state[field] && $this.state[field].includes(option));
    }
    return (
      <div className='quiz__checkbox' key={option}>
        <input
          type='checkbox'
          value={option}
          onChange={!isView ? () => $this.handleCheckboxChange(field, option, nestedObjectAttribute, objectIndex) : () => false}
          checked={isChecked()}
        />
        <label
          className={'quiz__label ' + ( isChecked() ? 'quiz__label--selected' : '')}
          onClick={!isView ? () => $this.handleCheckboxChange(field, option, nestedObjectAttribute, objectIndex) : () => false}
        >
          { isChecked() &&
            <i className='icon icon-checkmark-outline'></i>
          }
          {option}
        </label>
      </div>
    )
  })
}

export function standardTextInput($this, field, label) {
  return (
    <div className='field active-card active-card--centered'>
      <h4 className="is-4 title has-text-centered quiz__header">{label}</h4>
      <input
        className='input'
        type='text'
        onChange={(e) => $this.handleTextInputChange(e.target.value, field)}
        value={$this.state[`${field}`]} />
    </div>
  )
}

export function flowerOptionsByTitle(person) {
  if (['Ringbearer', 'Flowergirl'].includes(person.title)) {
    return ['Bouquet', 'Boutonnière', 'Flower Crown',  'Flower Hoop', 'Flower Wand',  'Hair Flowers', 'Moss Ring Pillow', 'Pin Corsage', 'Wrist Corsage'];
  } else {
    return ['Bouquet', 'Boutonnière', 'Nosegay/Posie (tiny bouquet)', 'Petals & Basket', 'Pin Corsage', 'Ring Pillow', 'Wrist Corsage']
  }
}

export function flowerSelections($this, personableType, left = false) {
  let members = personableType === 'FamilyMember' ? $this.props.user.family_members : $this.props.user.wedding_party_members;
  if (members.length > 0) {
    let flowerSelections = $this.state.flower_selections || [];
    let people = [...members];
    return (
      <div
        className='df-fdc-aic field active-card active-card--centered'
        style={left ? {alignItems: 'flex-start'} : {}}
      >
        <h4 className='is-4 title has-text-centered quiz__header'>{personableType === 'FamilyMember' ? 'Family' : 'Wedding Party'}</h4>
        { people.map(p => {
          let isOpen = $this.state.openFlowerSelections.includes(`${personableType}${p.id}`);
          let existing = flowerSelections.filter(fs => fs.personable_id === p.id && fs.personable_type === personableType)[0]
          return (
            <div className='field mb-one-em' key={`${personableType}${p.id}`}>
                <>
                <div className='control select is-multiple'>
                  <label className='label' htmlFor={p.name} onClick={() => $this.handleOpenFlowerSelection(`${personableType}${p.id}`)}>
                    {p.name} | {p.title} <i className={`icon icon-${isOpen ? 'up' : 'down'}`}></i>
                  { existing && <span style={{fontSize:'0.8em', fontWeight: 'normal'}}>({existing.flower_type.length} selected)</span> }
                  </label>

                { isOpen &&
                    <select
                      value={existing ? flowerSelections[flowerSelections.indexOf(existing)].flower_type : [] }
                      multiple
                      onChange={(e) => $this.handleFlowerSelection(e, p, personableType)}>
                      { flowerOptionsByTitle(p).map((o) => {
                          return <option value={o} key={o}>{o}</option>
                        })
                      }
                    </select>
                  }
                </div>
                </>
            </div>
          )
        })}
      </div>
    )
  } else {
    let isFamily = personableType === 'FamilyMember';
    return (
      <div>
        <h5 className='subtitle is-5'>{isFamily ? 'Family' :  'Wedding Party'}</h5>
        { isFamily &&
          <p className='mb-one-em'>You haven't added any family members yet. You can add family members <a href='/questionnaires/family_members'>here</a>.</p>
        }
        { !isFamily &&
          <p className='mb-one-em'>You haven't added any wedding party members yet. You can add wedding party members <a href='/questionnaires/wedding_party_members'>here</a>.</p>
        }
      </div>
    )
  }
}

export function tableCenterpieces(isView, $this) {
  return (
    <div className='field active-card active-card--centered'>
      <h4 className='is-4 title has-text-centered quiz__header'>Table Types & Centerpieces</h4>
      <div>
        <div className='field'>
          <input
            placeholder='Description'
            value={$this.state.centerpiece.description}
            onChange={(e) => $this.setTableCenterpiece(e.target.value, 'description')}
            className='input'
            type='text' />
        </div>
        <div className='field'>
          <input
            placeholder='Table Size'
            value={$this.state.centerpiece.table_size}
            onChange={(e) => $this.setTableCenterpiece(e.target.value, 'table_size')}
            className='input'
            type='text' />
        </div>

        <div className='field active-card active-card--centered'>
          <fieldset className="control">
            <h4 className='is-4 title has-text-centered quiz__header'>Flower Style</h4>
            { !isView && <span className="has-text-centered is-block mb-one-em">Select all that apply.</span> }
            { centerpieceOptions(isView, $this) }
          </fieldset>
        </div>
      </div>
    </div>
  )
}

export function centerpieceOptions(isView, $this) {
  let options = [
      'tall', 'low', 'long', 'round',
      'alternating arrangement styles',
      'bud vases', 'small flower clusters',
      'single arrangement', 'multiple arrangements',
      'candles', 'greenery'
    ];
    if (isView) {
      options = $this.state.centerpiece.flower_style
    }
  return options.map((option) => {
    return (
      <div className='quiz__checkbox' key={option}>
        <input
          type='checkbox'
          value={option}
          onChange={() => $this.handleCenterpieceFlowerStyle(option)}
          checked={$this.state.centerpiece.flower_style.includes(option)}
        />
        <label
          className={'quiz__label ' + ( $this.state.centerpiece.flower_style.includes(option) ? 'quiz__label--selected' : '')}
          onClick={() => $this.handleCenterpieceFlowerStyle(option)}
        >
          { $this.state.centerpiece.flower_style.includes(option) &&
            <i className='icon icon-checkmark-outline'></i>
          }
          {option}
        </label>
      </div>
    )
  })
}

export function ceremonyFlourishes(isView, $this) {
  let options = ['altar arrangements', 'aisle side chair flowers', 'aisle petals', 'aisle entrance arrangements',
  'arch/arbor flowers', 'arch/arbor draping', 'arrangements along last row of chairs', 'ceremony welcome table'];

  return (
    <div className='field active-card active-card--centered'>
      <h4 className="is-4 title has-text-centered quiz__header">Ceremony Flourishes</h4>
      <fieldset className="control">
        { !isView && <span className="has-text-centered is-block mb-one-em">Select all that apply.</span> }
        { checkboxOptions(isView, $this, 'ceremony_flourishes', options, null, null) }
      </fieldset>
    </div>
  )
}

export function additionalFlourishes(isView, $this) {
  let options = ['Welcome table', 'welcome sign', 'escort table', 'assignment board', 'cocktail arrangements', 'bars', 'cocktail tables', 'gift/card table'];
  return (
    <div className='field active-card active-card--centered'>
      <h4 className="is-4 title has-text-centered quiz__header">Additional Flourishes</h4>
      <fieldset className="control">
        { !isView && <span className="has-text-centered is-block mb-one-em">Select all that apply.</span> }
        { checkboxOptions(isView, $this, 'additional_flourishes', options, null, null) }
      </fieldset>
    </div>
  )
}

export function additionalServices(isView, $this) {
  let options = ['ceremony flip to reception', 'same night strike/clean-up', 'brunch flowers', 'welcome dinner flowers', 'rehearsal dinner flowers'];
  return (
    <div className='field active-card active-card--centered'>
      <h4 className="is-4 title has-text-centered quiz__header">Additional Services</h4>
      <fieldset className="control">
        { !isView && <span className="has-text-centered is-block mb-one-em">Select all that apply.</span> }
        { checkboxOptions(isView, $this, 'additional_services', options, null, null) }
      </fieldset>
    </div>
  )
}
