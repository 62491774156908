import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FormTextInput, FormSelect, Loading } from '../../../../forms/Helpers';
import { FormResponse, handleResponse, errorsFormatter } from '../../../../forms/Response';
import * as Questions from './FlowerQuestions';

const NUMBER_OF_QUESTIONS = 10;

export default class FlowerQuestionnaire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      currentQuestion: 0,
      openFlowerSelections: [],
      wedding_style: [],
      flower_likes: '',
      flower_dislikes: '',
      user_flowers: [],
      partner_flowers: [],
      flower_selections: [],
      ceremony_flourishes: [],
      additional_flourishes: [],
      additional_services: [],
      flower_selections: [],
      centerpiece: { description: '', table_size: '', flower_style: [] }
    }
  }

  componentDidMount() {
    if (this.props.flowerRequest) {
      this.setStateFromExisting(this.props.flowerRequest);
    }
  }

  setStateFromExisting = (flowerRequest) => {
    if (flowerRequest) {
      this.setState({
        id: flowerRequest.id,
        wedding_style: flowerRequest.wedding_style,
        flower_likes: flowerRequest.flower_likes,
        flower_dislikes: flowerRequest.flower_dislikes,
        user_flowers: flowerRequest.user_flowers || [],
        partner_flowers: flowerRequest.partner_flowers || [],
        ceremony_flourishes: flowerRequest.ceremony_flourishes || [],
        additional_flourishes: flowerRequest.additional_flourishes || [],
        additional_services: flowerRequest.additional_services || [],
        flower_selections: flowerRequest.flower_selections || [],
        centerpiece: flowerRequest.centerpieces[0] || this.emptyCenterpiece()
      })
    } else {
      this.setState({
        id: null,
        wedding_style: [],
        flower_likes: '',
        flower_dislikes: '',
        user_flowers: [],
        partner_flowers: [],
        ceremony_flourishes: [],
        additional_flourishes: [],
        additional_services: [],
        flower_selections: [],
        centerpiece: this.emptyCenterpiece()
      })
    }
  }

  emptyCenterpiece = () => {
    return { description: '', table_size: '', flower_style: [] }
  }

  handleOpenFlowerSelection = (uid) => {
    let openFlowerSelections = [...this.state.openFlowerSelections];
    let currentId = openFlowerSelections.indexOf(uid);
    if (currentId >= 0) {
      openFlowerSelections.splice(currentId, 1);
    } else {
      openFlowerSelections.push(uid);
    }
    this.setState({ openFlowerSelections });
  }

  submitQuestionnaire = () => {
    let { wedding_party_users, family } = this.state;
    this.setState({ isSubmitting: true }, () => {
      let url = '/questionnaires/flower_requests';
      let method = 'POST';
      if (this.state.id) {
        url = `/questionnaires/flower_requests/${this.state.id}`;
        method = 'PATCH';
      }
      axios({
        method: method,
        url: url,
        data: {
          flower_request: {
            user_flowers: this.state.user_flowers,
            partner_flowers: this.state.partner_flowers,
            wedding_style: this.state.wedding_style,
            flower_likes: this.state.flower_likes,
            flower_dislikes: this.state.flower_dislikes,
            ceremony_flourishes: this.state.ceremony_flourishes,
            additional_flourishes: this.state.additional_flourishes,
            additional_services: this.state.additional_services,
            flower_selections_attributes: this.state.flower_selections,
            flower_centerpieces_attributes: [].concat(this.state.centerpiece)
          },
          authenticity_token: this.props.token
        }
      })
      .then((response) => {
        this.setState({ isSubmitting: false, viewResults: false, editResults: false }, () => {
          this.setStateFromExisting(response.data.result);
        })
      })
      .catch((error) => {
        this.setState({ isSubmitting: false }, () => {
          console.log(error)
        })
      })
    })
  }

  deleteAnswers = () => {
    if (confirm('Are you sure you want to delete your answers? This cannot be undone.')) {
      this.setState({ isSubmitting: true }, () => {
        axios.delete(`/questionnaires/flower_requests/${this.state.id}`, {
          headers: {
            'X-CSRF-Token': this.props.token,
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          this.setState({ isSubmitting: false, currentQuestion: 0, formOpen: false }, () => {
            this.setStateFromExisting(null);
          })
        })
        .catch((error) => {
          this.setState({ isSubmitting: false }, () => {
            console.log(error);
          })
        })
      })
    }
  }

  progressBar = () => {
    let percentage = (this.state.currentQuestion / NUMBER_OF_QUESTIONS) * 100
    return (
      <div className='questionnaire__progress'>
        <progress id="file" max="100" value={percentage}> {percentage}% </progress>
      </div>
    )
  }

  currentQuestion = () => {
    switch(this.state.currentQuestion) {
      case 0:
        return Questions.weddingStyle(false, this);
      case 1:
        return Questions.standardTextInput(this, 'flower_likes', 'Specific Flower Likes?');
      case 2:
        return Questions.standardTextInput(this, 'flower_dislikes', 'Specific Flower Dislikes?');
      case 3:
        return Questions.flowersForBrideAndGroom(false, this, 'user_flowers', this.props.user.first_name);
      case 4:
        return Questions.flowersForBrideAndGroom(false, this, 'partner_flowers', this.props.user.partner_first_name);
      case 5:
        return Questions.flowerSelections(this, 'WeddingPartyMember');
      case 6:
        return Questions.flowerSelections(this, 'FamilyMember');
      case 7:
        return Questions.tableCenterpieces(false, this);
      case 8:
        return Questions.ceremonyFlourishes(false, this);
      case 9:
        return Questions.additionalFlourishes(false, this);
      case 10:
        return Questions.additionalServices(false, this);
    }
  }

  handleCheckboxChange = (field, selection, nestedObjectAttribute = null, objectIndex = null) => {
    let arr = [...this.state[`${field}`]];
    if (nestedObjectAttribute) {
      let obj = Object.assign({}, arr[objectIndex])
      if (obj[nestedObjectAttribute].includes(selection)) {
        obj[nestedObjectAttribute].splice(obj[nestedObjectAttribute].indexOf(selection), 1);
      } else {
        obj[nestedObjectAttribute].push(selection);
      }
      arr[objectIndex] = obj;
      this.setState({[`${field}`]: arr} )
    } else {
      if (arr.includes(selection)) {
        arr.splice(arr.indexOf(selection), 1);
      } else {
        arr.push(selection);
      }
    }

    this.setState({[`${field}`]: arr});
  }

  handleFlowerSelection = (e, person, type) => {
    let selected = Array.from(e.target.selectedOptions, option => option.value);
    let flower_selections = [...this.state.flower_selections];
    let existingSelection = flower_selections.filter(fs => fs.personable_id === person.id && fs.personable_type === type)[0]
    if (existingSelection) {
      flower_selections[flower_selections.indexOf(existingSelection)].flower_type = selected;
    } else {
      flower_selections.push(
        { personable_id: person.id, personable_type: type, flower_type: selected, flower_request_id: this.state.id }
      )
    }
    this.setState({ flower_selections })
  }

  handleTextInputChange = (value, field) => {
    this.setState({ [`${field}`]: value });
  }

  addFlowerUser = (field) => {
    let newUser = { name: '', title: '', wedding_party_side: '', flower_type: [], flower_user_category: field };
    let arr = [...this.state[`${field}`]];
    arr.push(newUser)
    this.setState({ [`${field}`]: arr })
  }

  allQuestionsHelper = (isView) => {
    return (
      <div className='acts-as-form six-hundred photo-hours__text-left'>
        { Questions.weddingStyle(isView, this) }
        { Questions.standardTextInput(this, 'flower_likes', 'Specific Flower Likes?')}
        { Questions.standardTextInput(this, 'flower_dislikes', 'Specific Flower Dislikes?')}
        { Questions.flowersForBrideAndGroom(isView, this, 'user_flowers', this.props.user.first_name) }
        { Questions.flowersForBrideAndGroom(isView, this, 'partner_flowers', this.props.user.partner_first_name) }
        { Questions.flowerSelections(this, 'WeddingPartyMember', true) }
        { Questions.flowerSelections(this, 'FamilyMember', true) }
        { Questions.tableCenterpieces(isView, this) }
        { Questions.ceremonyFlourishes(isView, this) }
        { Questions.additionalFlourishes(isView, this) }
        { Questions.additionalServices(isView, this) }
      </div>
    );
  }

  toggleEditResponses = () => {
    this.setState({ editResults: !this.state.editResults })
  }

  toggleViewResponses = () => {
    this.setState({ viewResults: !this.state.viewResults })
  }

  allQuestions = () => {
    if (this.state.editResults && !this.state.viewResults) {
      return (
        <div className='photo-hours photo-hours__full-form'>
          <a className='link-back' href='/questionnaires'>Back to Wedding Details</a>

          <div className='photo-hours__question quiz__question'>
            <div className='field'>
              <i style={{float: 'right', cursor: 'pointer'}}
                className='icon icon-close'
                onClick={() => this.setState({ viewResults: false, editResults: false })} 
              />
              {this.allQuestionsHelper(false)}
              {this.updateNav()}
            </div>
          </div>
        </div>
      )
    } else if (!this.state.editResults && this.state.viewResults) {
      return (
        <div className='photo-hours photo-hours__full-form'>
          <a className='link-back' href='/questionnaires'>Back to Wedding Details</a>

          <div className='photo-hours__question quiz__question'>
            <div className='field'>
              <i style={{float: 'right', cursor: 'pointer'}}
                className='icon icon-close'
                onClick={() => this.setState({ viewResults: false, editResults: false })} 
              />
              {this.allQuestionsHelper(true)}
              {this.updateNav()}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="dashboard__container photo-hours-center">
          <div className='wedding-party__list photo-hours-center'>
            <a className='link-back' href='/questionnaires'>Back to Wedding Details</a>
            <div className='photo-hours__question quiz__question'>
              <h3 className='title is-3'>Your Flower Information Has Been Saved!</h3>
              <div className="grouped-buttons">
                <button className='button button-primary' onClick={this.toggleViewResponses}>view responses</button>
                <button className='button button-primary' onClick={this.toggleEditResponses}>edit responses</button>
                <button className='button button-cancel' onClick={this.deleteAnswers}>delete responses</button>
              </div>
            </div>
          </div>
        </div>
      )
    }

  }

  incrementQuestion = () => {
    this.setState({ currentQuestion: this.state.currentQuestion + 1 })
  }

  decrementQuestion = () => {
    this.setState({ currentQuestion: this.state.currentQuestion - 1 })
  }

  setFlowerUserValue = (val, field, attr, userIndex) => {
    let item = Object.assign({}, this.state[field][userIndex]);
    item[attr] = val
    let allItems = [...this.state[field]];
    allItems[userIndex] = item;
    this.setState({ [field]: allItems })
  }

  setTableCenterpiece = (val, attr) => {
    let item = Object.assign({}, this.state.centerpiece);
    item[attr] = val;
    this.setState({ centerpiece: item });
  }

  handleCenterpieceFlowerStyle = (val) => {
    let item = Object.assign({}, this.state.centerpiece);
    let $this = this;
    if (item.flower_style.includes(val)) {
      item.flower_style.splice(item.flower_style.indexOf(val), 1);
    } else {
      item.flower_style.push(val);
    }
    this.setState({ centerpiece: item })
  }

  nav = () => {
    if (this.state.currentQuestion === 0) {
      return (
        <div className='has-text-centered'>
          <button onClick={this.incrementQuestion} className='button button-primary button-full-on-sm mt-one-em button-half-width'>next</button>
        </div>
      )
    } else if (this.state.currentQuestion === NUMBER_OF_QUESTIONS) {
      return (
        <div className='has-text-centered'>
          <button className='button button-full-on-sm' onClick={this.decrementQuestion}>previous</button>
          <button className='button button-primary button-full-on-sm' onClick={this.submitQuestionnaire}>submit</button>
        </div>
      )
    } else {
      return (
        <div className='has-text-centered'>
          <button className='button button-full-on-sm' onClick={this.decrementQuestion}>previous</button>
          <button className='button button-primary button-full-on-sm' onClick={this.incrementQuestion}>next</button>
      </div>
      )
    }
  }

  updateNav = () => {
    return (
      <div className='photo-hours__edit'>
        {this.state.editResults &&
        <>
            <button
              className='button button-primary button-half-width'
              onClick={this.submitQuestionnaire}
            >
              submit
            </button>
            <button
            className='button cancel-button button-half-width'
            onClick={() => this.setState({ viewResults: false, editResults: false })}
          >
            cancel
          </button>
        </>
        }
        {this.state.viewResults &&
          <button
            className='button cancel-button button-half-width'
            onClick={() => this.setState({ viewResults: false, editResults: false })}
          >
            back
          </button>}
      </div>
    )
  }

  render() {
    if (this.state.isSubmitting) {
      return (
        <div className='dashboard__container'>
          <Loading />
        </div>
      )
    } else if (this.state.id) {
      return (
          this.allQuestions()
      )
    } else if (this.state.formOpen) {
      return (
        <div className='flowers-questionnaire dashboard__container'>
          <a className='link-back' href='/questionnaires'>Back to Wedding Details</a>

          <div className='acts-as-form six-hundred quiz__container'>
            <div className='photo-hours__question quiz__question'>
              { this.progressBar() }
              <div className='active-card active-card--centered'>
                { this.currentQuestion() }
                { this.nav() }
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className='flowers-questionnaire dashboard__container'>
          <div className='wedding-party__list'>
            <a className='link-back' href='/questionnaires'>Back to Wedding Details</a>

            <h5
              className='title is-5 weight-normal'
              style={{ marginBottom: 10 }}
            >Need help determining what flowers you need for your big day?</h5>
            <h6
                className='title is-6 weight-normal'
                style={{ fontSize: '1.15rem' }}>Take our quiz to find out!</h6>
            <button
              onClick={() => this.setState({ formOpen: true })}
              className='button button-primary'>Start the Quiz</button>
          </div>
        </div>
      )
    }
  }
}

FlowerQuestionnaire.propTypes = {

}
