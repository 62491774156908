import React from 'react';
import PropTypes from 'prop-types';
import CheckoutModal from '../../../adyen/CheckoutModal';
import GuidedBrideChat from '../../../chat/GuidedBrideChat';

export default class GuidedBrideVendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  chat = () => {
    if (this.props.user.has_active_subscription != true) {
      return (
        <div>
          {/* <p>When you sign up for a Guided Bride subscription you can chat with your vendors in real time
            and receive email notifications from them when you're not online!
          </p>
          <button
            className='button button-primary mt-one-em'
            onClick={() => this.setState({ modalIsOpen: true })}
            type='button'>Subscribe Now</button> */}

          <p className='has-text-centered'>Coming soon...</p>

          <CheckoutModal
            token={this.props.token}
            user={this.props.user}
            clientKey={this.props.clientKey}
            stripePublishableKey={this.props.stripePublishableKey}
            modalIsOpen={this.state.modalIsOpen}
            closeModal={() => this.setState({ modalIsOpen: false })} />
        </div>
      )
    } else {
        return (
          // <div className='chat__container'>
          //   <GuidedBrideChat user={this.props.user} vendor={this.props.vendor} />
          // </div>

          <p className='has-text-centered'>Coming soon...</p>
        )
      }
  }

  render() {
    return (
      <div>
        <div className='vendors__detail'>
          <i className="icon icon-arrow-left sm-only clickable" onClick={this.props.closeGuidedBrideVendor}></i>
          <h4 className='title is-4 is-spaced has-text-centered'>Guided Bride</h4>

          <div className="tabs">
            <ul>
              <li
                className='is-active'>
                <a style={{cursor: 'default'}}>Chat</a>
              </li>
            </ul>
          </div>

          { this.chat() }
      </div>
    </div>
    )
  }
}

GuidedBrideVendor.propTypes = {
  closeGuidedBrideVendor: PropTypes.func.isRequired,
  clientKey: PropTypes.string.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
}
