import React from 'react';
import PropTypes from 'prop-types';
import CustomerList from './CustomerList';

export default class VendorHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  tabs = () => {
    const tabItems = [
      { title: 'Clients', path: ''},
      {title: 'Info', path: '/info' }
    ]

    return tabItems.map((i) => {
      if (this.props.tab === i.title) {
        return <li key={i.title} className='dashboard__li  active'><i className="nav-circle nav-circle--active"></i><a>{i.title}</a></li>
      } else {
        return <li key={i.title} className='dashboard__li'><i className="nav-circle"></i><a href={i.path}>{i.title}</a></li>
      }
    })
  }

  render() {
    return (
      <div className='vendor-home dashboard'>
        <div className='dashboard__navbar'>
          <div className='sidebar'>
            <div className='sidebar__logo'>
              <img src='/images/logo.png' alt='Guided Bride Logo' />
            </div>
            <div className='sidebar__column'>
              { this.tabs() }
            </div>

            <div className='sidebar__bottom'>
              <a href='/vendors/sign_out'>Log Out</a>
            </div>
          </div>
        </div>

        <div className='dashboard__content'>
          <CustomerList vendor={this.props.vendor} />
        </div>
      </div>
    )
  }
}

VendorHome.propTypes = {
  vendor: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired
}
