import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import VendorForm from './VendorForm';
import { handleResponse, FormResponse, errorsFormatter } from '../../../../../forms/Response';
import { Loading } from '../../../../../forms/Helpers';

export default class EditVendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleVendorContacts = (vals, vendor) => {
    if (vendor && vendor.contacts.length > vals.contacts.length) {
      let contacts = [...vendor.contacts];
      let savedContacts = vals.contacts.map(c => c.id);
      contacts.forEach((contact) => {
        if (savedContacts.includes(contact.id) === false) {
          contact['_destroy'] = true;
        }
      })
      return contacts;
    } else {
      return vals.contacts;
    }
  }

  handleSubmit = (vals) => {
    let { vendor } = this.props;
    this.setState({ isSubmitting: true }, () => {
      let contacts = this.handleVendorContacts(vals, vendor);
      axios.patch(`/api/v1/vendors/${vendor.id}`, {
        vendor: {
          id: vendor.id,
          company_name: vals.vendorName,
          vendor_type_id: vals.vendorType,
          user_id: this.props.user.id,
          venue_type: vals.venue_type,
          vendor_contacts_attributes: contacts,
          vendor_location_attributes: vals.location
        },
        authenticity_token: this.props.token
      })
      .then((response) => {
        this.setState({ isSubmitting: false }, () => {
          this.props.handleEditedVendor(response.data.vendor);
        })
      })
      .catch((error) => {
        let message = errorsFormatter(error.response.data.errors) || 'There was a problem saving this vendor.';
        handleResponse(this, message, error.response.status, 4000);
      })
    })
  }

  deleteVendor = () => {
    let { vendor } = this.props;

    if (confirm('Are you sure you want to delete this vendor? This cannot be undone.')) {
      this.setState({ isSubmitting: true }, () => {
        axios.delete(`/api/v1/vendors/${vendor.id}`, {
          params: {
            authenticity_token: this.props.token
          }
        })
        .then((response) => {
          this.setState({ isSubmitting: false }, () => {
            this.props.handleDeletedVendor(vendor.id);
          })
        })
        .catch((error) => {
          let message = errorsFormatter(error.response.data.errors) || 'There was a problem deleting this vendor.';
          handleResponse(this, message, error.response.status, 4000);
        })
      })
    }
  }

  response = () => {
    let { responseMessage, responseCode } = this.state;
    if (responseMessage && responseCode) {
      return FormResponse(responseMessage, responseCode)
    }
  }

  render() {
    if (this.state.isSubmitting) {
      return (
        <div>
          <Loading />
        </div>
      )
    } else {
      return (
        <div className='vendor-contact'>
          <i className="icon icon-close is-pulled-right clickable"
             onClick={this.props.closeForm}></i>
          <h4 className='title is-4'>Edit {this.props.isVenue ? 'Venue' : 'Vendor'}</h4>
          { this.response() }
            <VendorForm
              isVenue={this.props.isVenue}
              vendor={this.props.vendor}
              handleSubmit={this.handleSubmit}
              vendorTypes={this.props.vendorTypes}
              closeForm={this.props.closeForm}
              deleteVendor={this.deleteVendor} />
        </div>
      )
    }
  }
}

EditVendor.propTypes = {
  token: PropTypes.string.isRequired,
  vendorTypes: PropTypes.array,
  handleEditedVendor: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  closeForm: PropTypes.func.isRequired,
  vendor: PropTypes.object.isRequired,
  handleDeletedVendor: PropTypes.func.isRequired
}
