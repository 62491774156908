import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { Loading } from '../../../forms/Helpers';
import { FormResponse, handleResponse, errorsFormatter } from '../../../forms/Response';
import * as CONSTANTS from '../../../Constants'

const customStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '3rem 4rem'
  }
};

export default class Countdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  formattedWeddingDate(user) {
    let d = user.wedding_date;
    let dates = CONSTANTS.Months();
    let day = d.split('-')[2];
    let month = dates[parseInt(d.split('-')[1]) - 1];
    let year = d.split('-')[0];
    return `${month} ${day}, ${year}`;
  }

  countdown = (user) => {
    let weddingDate = new Date(user.wedding_date);
    let now = new Date()
    weddingDate.setHours(0,0,0,0);
    now.setHours(0,0,0,0);
    const MILLISECONDS_IN_A_DAY = 86400000;
    let daysUntilWedding = Math.floor((weddingDate - now) / MILLISECONDS_IN_A_DAY);
    return this.countdownBox(daysUntilWedding);
  }

  countdownBox = (daysUntilWedding) => {
    if (daysUntilWedding < 0) {
      return null //<span>Congratulations!</span>
    } else {
      return (
        <div className='wedding-countdown__display'>
          <h2>{ daysUntilWedding }</h2>
          <div className='wedding-countdown__days'>
            <span>Days</span>
          </div>
        </div>

      )
    }
  }

  venueLocations = () => {
    let venues = this.props.user.venues;
    if (venues.length === 0) {
      return (
        <>
        <div>
          <i className='icon icon-location'></i> <a href='/venues?action=new&venue_type=ceremony'>Add Ceremony Location</a>
        </div>
        <div>
          <i className='icon icon-location'></i> <a href='/venues?action=new&venue_type=reception'>Add Reception Location</a>
        </div>
        </>
      )
    } else {
      let sameLocation = venues.filter(v => v.venue_type === 'ceremony and reception')[0];
      if (sameLocation) {
        return (
          <span>
            <i className='icon icon-location'></i>Ceremony and Reception: <a href={`/venues?id=${sameLocation.id}`}>{ sameLocation.company_name }</a>
          </span>
        )
      } else {
        return (
          <div>
            <div>
              { this.ceremonyLocation(venues) }
            </div>
            <div>
              { this.receptionLocation(venues) }
            </div>
          </div>
        )
      }
    }
  }

  ceremonyLocation = (venues) => {
    let ceremonyVenues = venues.filter(v => v.venue_type === 'ceremony')
    if (ceremonyVenues.length > 0) {
      return ceremonyVenues.map((v) => {
        return <span key={v.id}><i className='icon icon-location'></i>Ceremony: <a className='link-without-styling' href={`/venues?id=${v.id}`}>{ v.company_name }</a></span>
      })
    } else {
      return (
        <a
          className='link-without-styling'
          href='/venues?action=new&venue_type=ceremony'>Add a Ceremony Location</a>
      )
    }
  }

  receptionLocation = (venues) => {
    let receptionVenues = venues.filter(v => v.venue_type === 'reception');
    if (receptionVenues.length > 0) {
      return receptionVenues.map((v) => {
        return <span key={v.id}><i className='icon icon-location'></i>Reception: <a className='link-without-styling' href={`/venues?id=${v.id}`}>{ v.company_name }</a></span>
      })
    } else {
      return (
        <a className='link-without-styling'
          href='/venues?action=new&venue_type=reception'>Add a Reception Location</a>
      )
    }
  }

  locationByType = (locationType) => {
    let { user } = this.props;
    return user.venues.filter((l) => l.venue_type.toLowerCase() === locationType.toLowerCase());
  }

  copyResponse = () => {
    if (this.state.copyResponse) {
      return <p className='color-main-shade-3'>Coped!</p>
    }
  }

  locationShare = () => {
    let { user } = this.props;
    if (user.venues && user.venues.length > 0) {
      return (
        <>
          { this.copyResponse() }
          <div className='wedding-countdown__share'>
            <span onClick={this.share}>
              <span>Share Your Venue Details</span>
              <i className='icon icon-share'></i>
            </span>
          </div>
        </>

      )
    }
  }

  venueDetailsForShare(sharedLocation, ceremony, reception) {
    let textToCopy = `Here are my venue details.`
    function formatted(v, typeOf) {
      let text;
      if (v.location) {
        text = ` ${typeOf}: ${v.company_name}: ${v.location.street_address}, ${v.location.city}`;
        text += v.location.us_state ? `, ${v.location.us_state}.` : '. ';
      } else {
        text = ` ${typeOf}: ${v.company_name}`;
      }
      return text
    }
    if (sharedLocation && sharedLocation.length > 0) {
      textToCopy += formatted(sharedLocation[0], 'Ceremony and Reception')
    } else {
      if (ceremony && ceremony.length > 0) {
        ceremony.map((c) => {
          textToCopy += formatted(c, 'Ceremony')
        });
      }
      if (reception && reception.length > 0) {
        reception.map((r)=> { textToCopy += formatted(r, 'Reception') });
      }
    }
    return textToCopy;
  }

  share = () => {
    let bothLocations = this.locationByType('ceremony and reception');
    let ceremony = this.locationByType('ceremony');
    let reception = this.locationByType('reception');
    let textToCopy = this.venueDetailsForShare(bothLocations, ceremony, reception);
    if (navigator.share) {
      navigator.share({
        title: 'Here are my venue details.',
        text: textToCopy,
      })
    } else {
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          this.setState({ copyResponse: true }, () => {
            setTimeout(() => this.setState({ copyResponse: null }), 1000)
          })
        })
    }
  }


  render() {
    let { user } = this.props;
    return (
      <div className='wedding-countdown'>
        <div className='header-with-swatch'>
          <h4 className='subtitle is-4 has-text-centered section-header'>Our Wedding Details</h4>
        </div>

        <div className='dashboard__subheader has-text-centered'>
          <span>Wedding Date</span>
        </div>
        <h4 className='subtitle is-4 has-text-centered'>{ this.formattedWeddingDate(user) }</h4>
        { this.countdown(user) }

        <div className='has-text-centered wedding-countdown__bottom'>
          { this.venueLocations() }
          { this.locationShare() }
        </div>
        <article className='questionnaires-list'>
          <div className="columns columns--icons">
            <div className="column is-one-quarter">
              <a href='/questionnaires/wedding_party_members'>
                <i className='icon icon-party-person'></i>
                <span className='icon-text'>Wedding Party</span>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href='/questionnaires/photo_hours'>
                <i className='icon icon-camera'></i>
                <span className='icon-text'>Photography</span>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href='/vendors'>
                <i className='icon icon-people'></i>
                <span className='icon-text'>Vendors</span>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href='/lists'>
              <i className='icon icon-tasklist'></i>
                <span className='icon-text'>Checklists</span>
              </a>
            </div>
          </div>
          <div className='has-text-centered'>
            <a href='/questionnaires'>see all wedding details</a>
          </div>
        </article>
      </div>
    )
  }
}

Countdown.propTypes = {
  token: PropTypes.string.isRequired,
  addCeremonyLocationToUser: PropTypes.func.isRequired
}
