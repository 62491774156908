import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Form, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormatPhone, EnforceFormat } from './PhoneFormatting';

export const FormTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
    <div className="control">
      { label &&
        <label
          className="label"
          htmlFor={props.id || props.name}>{label}
          { props.tooltip &&
            <span data-tooltip={props.tooltip}
                  className="has-text-weight-normal has-tooltip-multiline has-tooltip-right"><i className='icon icon-question'></i></span>
          }
        </label>
      }
      <input className={"text-input input " + (meta.touched && meta.error ? 'error' : '')} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error error-small">{meta.error}</div>
      ) : null}
    </div>
    </>
  );
};

export const FormPhoneInput = ({ label, ...props}) => {
  const [field, meta] = useField(props);
  return (
    <>
    <div className="control">
      { label &&
        <label className="label" htmlFor={props.id || props.name}>{label}</label>
      }
      <input {...field}
        onKeyDown={(e) => EnforceFormat(e)}
        onChange={e => {
          e.target.value = FormatPhone(e)
          field.onChange(e);
        }}
        className={"text-input input " + (meta.touched && meta.error ? 'error' : '')}  />
      {meta.touched && meta.error ? (
        <div className="error error-small">{meta.error}</div>
      ) : null}
    </div>
    </>
  );
}

export const FormCheckbox = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <>
      <label className="checkbox form__checkbox">
        <input type="checkbox" {...field} {...props} />
        {label}
        { props.tooltip &&
          <span data-tooltip={props.tooltip}
                className="has-text-weight-normal has-tooltip-multiline has-tooltip-top"><i className='icon icon-question'></i></span>
        }
      </label>
      {meta.touched && meta.error ? (
        <div className="error error-small">{meta.error}</div>
      ) : null}
    </>
  );
};

export const FormSelect = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
    <div className='control'>
      { label &&
        <label className='label' htmlFor={props.id || props.name}>{label}</label>
      }
      <select {...field} {...props} className='select' />
      {meta.touched && meta.error ? (
        <div className="error error-small">{meta.error}</div>
      ) : null}
    </div>
    </>
  );
};

export const FormRadioGroup = ({ label, children, ...props }) => {
  const [field, meta] = useField({ ...props, type: 'radio' });
  return (
    <>
      <fieldset className="radio">
        <legend>{label}</legend>
          {children}
          {meta.touched && meta.error ? (
            <div className="error error-small">{meta.error}</div>
          ) : null}
      </fieldset>
    </>
  );
};

export const FormRadioButton = ({ label, children, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ ...props, type: 'radio' });
  return (
    <div className="radio__container">
      <label
        className="radio__label"
        htmlFor={props.id || props.name} {...field} {...props}
        onClick={() => setFieldValue(props.name, props.value)}>
        {label}
        <input type="radio" {...field} {...props} />
          <span className="radio__btn" onClick={() => setFieldValue(props.name, props.value)}></span>
      </label>
    </div>
  );
};

export const DatePickerField = ({ label, ...props}) => {
  const [field, meta] = useField(props);
  return (
    <>
    <div className="control">
      { label &&
        <label className="label" htmlFor={props.id || props.name}>{label}</label>
      }
      <input
        type='date'
        className={"text-input input " + (meta.touched && meta.error ? 'error' : '')} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error error-small">{meta.error}</div>
      ) : null}
    </div>
    </>
  );
}

// NOTE: keep this in case we revert back to a datepicker

// export const DatePickerField = ({ label, ...props }) => {
//   const { setFieldValue } = useFormikContext();
//   const [field, meta] = useField(props);
//   return (
//     <div className="control datepicker">
//       { label &&
//         <label htmlFor={props.id || props.name}>{label}</label>
//       }
//         <DatePicker
//           {...field}
//           {...props}
//           placeholderText={props.placeholderText}
//           className={"input " + (meta.touched && meta.error ? "error" : "")}
//           selected={(field.value && new Date(field.value)) || null}
//           dateFormat={"MM/dd/yyyy"}
//           showMonthDropdown
//           showYearDropdown
//           showDayDropdown
//           dropdownMode = 'select'
//           value={field.name !== "" ? new Date(field.name) : ""}
//           onChange={val => {
//             setFieldValue(field.name, val);
//           }}
//         />
//         {meta.touched && meta.error ? (
//           <div className="error">{meta.error}</div>
//         ) : null}
//     </div>
//   );
// };

export const Loading = () => {
  return (
    <div className="loading-spinner">
      <div></div><div></div><div></div><div></div>
    </div>
  )
}
