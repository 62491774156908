import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { FormTextInput, FormSelect, FormCheckbox, DatePickerField, FormRadioGroup, FormRadioButton, Loading } from '../../forms/Helpers';
import { FormResponse, handleResponse } from '../../forms/Response';
import CheckoutModal from './../../adyen/CheckoutModal';

export default class IntroForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTile: 0,
      finishedIntro: false,
      finishedPrompt: 1,
      modalIsOpen: false
    }
  }

  componentWillUnmount() {
    if (this.formResponse) {
      clearTimeout(this.formResponse);
    }
  }

  handleSubmit = (values) => {
    this.setState({ isSubmitting: true } , () => {
      axios.post('/api/v1/finalize_account', {
        user:  {
          id: this.props.user.id,
          first_name: values.firstName,
          last_name: values.lastName,
          partner_first_name: values.partnerFirstName,
          partner_last_name: values.partnerLastName,
          bride_or_groom: values.brideOrGroom,
          wedding_date: values.weddingDate,
          number_of_guests: values.numberOfGuests,
          budget: values.budget
        },
        authenticity_token: this.props.token
      })
      .then((response) => {
        this.setState({ isSubmitting: false, finishedIntro: true })
        // window.location.replace('/tasks')
      })
      .catch((error) => {
        handleResponse(this, error.response.data.message, error.response.status, 4000);
      })
    })
  }

  form = () => {
    return (
      <Formik
        initialValues={{
          firstName: this.props.user.first_name && this.props.user.first_name.trim() || '',
          lastName: this.props.user.last_name && this.props.user.last_name.trim() || '',
          partnerFirstName: '',
          partnerLastName: '',
          budget: '',
          numberOfGuests: '',
          brideOrGroom: 'bride',
          weddingDate: '',
          termsOfService: '',
        }}
        validationSchema={Yup.object({
          firstName: Yup.string()
            .required('Required'),
          lastName: Yup.string()
            .required('Required'),
          budget: Yup.number()
            .required('Required')
            .min(0, 'Must be more than 0!'),
          brideOrGroom: Yup.string()
            .oneOf(
              ['bride', 'groom',],
              'Invalid Response'
            )
            .required('Required'),
          partnerFirstName: Yup.string()
              .required('Required'),
          numberOfGuests: Yup.number()
            .required('Required')
            .min(0, 'Must be more than 0!'),
          weddingDate: Yup.date()
            .min(new Date(), 'Must be after today!')
            .max(new Date(new Date().setFullYear(new Date().getFullYear() + 5)), 'Must be within 5 years!')
            .typeError('Please enter a valid date.')
            .required('Required'),
          termsOfService: Yup.boolean()
            .required('The terms and conditions must be accepted.')
            .oneOf([true], 'The terms and service must be accepted.'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            this.handleSubmit(values)
            setSubmitting(false);
          }, 400)
        }}
        render={({ values }) => (
          <Form className="six-hundred">

            { this.firstTile(values) }

            { this.secondTile(values) }

            { this.thirdTile(values) }
          </Form>
        )}
      />

    );
  };

  firstTile = (vals) => {
    if (this.state.activeTile === 0) {
      return (
        <>
          <div className="field">
            <FormRadioGroup label="I am a" name="brideOrGroom">
              <FormRadioButton value="bride" name="brideOrGroom" label="Bride" />
              <FormRadioButton value="groom" name="brideOrGroom" label="Groom" />
            </FormRadioGroup>
          </div>

          <div className="field is-grouped">
            <FormTextInput
              name="firstName"
              type="text"
              placeholder="First Name"
            />
            <FormTextInput
              name="lastName"
              type="text"
              placeholder="Last Name"
            />
          </div>

          <div className='field is-grouped'>
            <FormTextInput
              name="partnerFirstName"
              type="text"
              placeholder="Partner First Name"
            />
            <FormTextInput
              name="partnerLastName"
              type="text"
              placeholder="Partner Last Name"
            />
          </div>

          <div className="field">
            <DatePickerField
              placeholder='Wedding Date?'
              name="weddingDate"
              label="Wedding Date"
            />
          </div>

          <button
            className='button button-primary'
            type='button'
            disabled={this.firstTileDisabled(vals)}
            onClick={this.nextTile}>next</button>
        </>
      )
    }
  }

  firstTileDisabled = (vals) => {
    let inputVals = [vals.firstName, vals.lastName, vals.partnerFirstName, vals.partnerLastName, vals.weddingDate];
    let emptyField = this.emptyFieldExists(inputVals) || this.weddingDateWithinBounds(vals.weddingDate);
    if (emptyField === true) {
      return true;
    } else {
      return Date.parse(vals.weddingDate) < new Date()
    }
  }

  weddingDateWithinBounds(weddingDate) {
    if (weddingDate) {
      let fiveYearsFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 5))
      let today = new Date();
      let wedding = Date.parse(weddingDate);
      if (wedding > fiveYearsFromNow || wedding < today) {
        return true;
      } else {
        return false;
      }
    }
  }

  submitDisabled = (vals) => {
    return this.emptyFieldExists(Object.values(vals))
  }

  emptyFieldExists = (inputVals) => {
    for (let i = 0; i < inputVals.length; i++) {
      if (inputVals[i] === '') {
        return true;
        break;
      }
    }
  }


  secondTile = (vals) => {
    if (this.state.activeTile === 1) {
      return (
        <>
        <div className="field">
          <FormTextInput
            name="budget"
            type="number"
            label="Estimated Budget?"
            placeholder="$"
            tooltip='This is a tooltip about your estimated budget for Meg to edit.'
          />
        </div>
        <button className='button' type='button' onClick={this.previousTile}>back</button>
        <button
          disabled={this.emptyFieldExists([vals.budget])}
          className='button button-primary'
          type='button'
          onClick={this.nextTile}>next</button>
        </>
      )
    }
  }

  warnAboutLargeGuestNumber(vals) {
    if (vals.numberOfGuests > 999) {
      return <p className='error mt-quarter-em'>That's a really big party! Are you sure this number is correct?</p>
    }
  }

  thirdTile = (values) => {
    if (this.state.activeTile === 2) {
      return (
        <>
          <div className="field">
            <label className='label'>Guest Count
              <span data-tooltip="This is the anticipated number of guests in attendance. This is not your invitation list. Typically 10 - 15% of your invitation list are unable to attend."
                    className="has-text-weight-normal has-tooltip-multiline has-tooltip-right"><i className='icon icon-question'></i></span>
            </label>
            <FormTextInput
              name="numberOfGuests"
              type="number"
            />
          { this.warnAboutLargeGuestNumber(values) }
          </div>
          <div className="field">
            <FormCheckbox
              label={<span>I accept the <a href="/terms_of_service" target="_blank">Terms of Service</a></span>}
              name="termsOfService"
            />
          </div>
        <button className='button' type='button' onClick={this.previousTile}>back</button>
        <button
          disabled={this.submitDisabled(values)}
          className="button button-primary"
          type="submit">Submit</button>
        </>
      )
    }
  }

  nextTile = ()=> {
    this.setState({ activeTile: this.state.activeTile + 1 })
  }

  previousTile = () => {
    this.setState({ activeTile: this.state.activeTile - 1 })
  }

  progressBar = () => {
    return (
      <div className='intro-form__progress acts-as-form six-hundred'>
        <div className={'intro-form__dot ' + (this.dotClass(0))}></div>
        <div className={'intro-form__dot ' + (this.dotClass(1))}></div>
        <div className={'intro-form__dot ' + (this.dotClass(2))}></div>
      </div>
    )
  }

  dotClass = (num) => {
    let { activeTile } = this.state;
    if (num === activeTile) {
      return 'intro-form__dot--active';
    } else if (activeTile > num) {
      return 'intro-form__dot--finished';
    }
  }

  finishedIntroComponent = () => {
    let { finishedPrompt } = this.state;
    if (finishedPrompt === 1) {
      return (
        <div className='intro-form'>
          <h3 className="title is-3">We've saved your information to customize your dashboard!</h3>
          <h5 className='subtitle is-5 mt-one-em'>One last question: have you booked your venue yet?</h5>
          <div className='row-buttons'>
            <button className='button' onClick={() => this.setState({ finishedPrompt: 2 })}>Yes</button>
            <button className='button' onClick={() => this.setState({ finishedPrompt: 3 })}>No</button>
          </div>
        </div>
      )
    } else if (finishedPrompt === 2) {
      return (
        <div className='intro-form'>
          <h3 className='title is-3'>Great! Click the button below to add your details.</h3>
          <div className='row-buttons'>
            <a className='acts-as-button button' href='/'>Not right now</a>
            <a className='acts-as-button button button-primary' href='/venues?action=new&venue_type=ceremony'>Add Venue</a>
          </div>
        </div>
      )
    } else {
      return (
        <div className='intro-form'>
          <h3 className='title is-3'>The Guided Bride worksheet can help you find the perfect venue.</h3>
          <p>Use our venues worksheet for comparing your venue options and pricing. Print a copy for each venue you tour so you can easily compare prices and inclusions.</p>
          <div className='row-buttons mt-one-em'>
            <a className='acts-as-button button' href='/'>Not right now</a>
            <a onClick={() => this.setReloadTimeout()}
               className='acts-as-button button button-primary'
               href="#"
               >Download here</a>
          </div>
          <CheckoutModal
            token={this.props.token}
            user={this.props.user}
            clientKey={this.props.clientKey}
            stripePublishableKey={this.props.stripePublishableKey}
            modalIsOpen={this.state.modalIsOpen}
            closeModal={() => this.setState({ modalIsOpen: false })} 
          />
        </div>
      )
    }
  }

  setReloadTimeout() {
    if (this.props.user.has_active_subscription) {
      window.open('/downloads/venue-worksheet.pdf','_blank')
      setTimeout(() => window.location.href = '/', 500);
    } else {
      this.setState({ modalIsOpen: true })
    }
  }

  render() {
    let { responseMessage, responseCode } = this.state;
    if (this.state.isSubmitting) {
      return (
        <div className="intro-form">
          <Loading />
        </div>
      )
    } else if (this.state.finishedIntro) {
      return this.finishedIntroComponent()
    } else {
      return (
        <div className="intro-form">
          <h3 className="title is-3">Let's get started!</h3>
          <p className="intro-form__instructions">Fill out the information below to begin using Guided Bride!</p>
          { FormResponse(responseMessage, responseCode) }
          { this.progressBar() }
          { this.form() }
        </div>
      )
    }
  }
}


IntroForm.propTypes = {
  user: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired
}
