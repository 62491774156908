import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { handleResponse, FormResponse, errorsFormatter } from '../../../../../forms/Response';
import { Loading } from '../../../../../forms/Helpers';
import VendorForm from './VendorForm';

export default class AddVendor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleSubmit = (vals) => {
    this.setState({ isSubmitting: true }, () => {
      axios.post('/api/v1/vendors', {
        vendor: {
          company_name: vals.vendorName,
          vendor_type_id: vals.vendorType,
          user_id: this.props.user.id,
          vendor_contacts_attributes: vals.contacts,
          is_venue: this.props.isVenue,
          venue_type: vals.venue_type,
          vendor_location_attributes: vals.location
        },
        authenticity_token: this.props.token
      })
      .then((response) => {
        this.props.handleNewVendor(response.data.vendor);
      })
      .catch((error) => {
        let message = errorsFormatter(error.response.data.errors) || 'There was a problem saving this vendor.'
        handleResponse(this, message, error.response.status, 4000);
      })
    })
  }

  response = () => {
    let { responseMessage, responseCode } = this.state;
    if (responseMessage && responseCode) {
      return FormResponse(responseMessage, responseCode)
    }
  }

  render() {
    if (this.state.isSubmitting) {
      return (
        <div className='vendor-contact'>
          <Loading />
        </div>
      )
    } else {
      return (
        <div className='vendor-contact'>
          <h4 className='title is-4'>{'Add a new ' + (this.props.isVenue ? 'venue' : 'vendor' )}</h4>
          { this.response() }

          <VendorForm
            isVenue={this.props.isVenue}
            handleSubmit={this.handleSubmit}
            vendorTypes={this.props.vendorTypes}
            closeForm={this.props.closeForm} />

        </div>
      )
    }
  }
}

AddVendor.propTypes = {
  vendorTypes: PropTypes.array.isRequired,
  closeForm: PropTypes.func.isRequired,
  handleNewVendor: PropTypes.func.isRequired
}
