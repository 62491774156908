import React from 'react';
import PropTypes from 'prop-types';
import { PieChart } from 'react-minimal-pie-chart';
import { formatMoney } from '../../../HelperFunctions';

const BUDGET_COLORS = [
  '#D67A5E', '#53c9c9', '#AB802A', '#C4947A', '#B54F2C',
  '#8eacad', '#8da843', '#F1A686', '#2d8a58', '#87422B', '#7f0ea1', '#939bdb',
  '#d93292', '#7db384', '#9784ad', '#b1bd9b', '#edb140', '#0ef099'
]

export default class BudgetOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: null,
    }
  }

  componentDidMount() {
    this.initializeValues(this.props.user);
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.initializeValues(this.props.user);
    }
  }

  legendColumn = (_legend) => {
    let { chartData } = this.state;
    if (chartData) {
      return chartData.map((d, index) => {
        return (
          <div
            key={`${index}-${d.title}`}
            className='budget-chart__legend-item'
          >
            <div className='swatch' style={{background: d.color}}></div>
            <div className='legend-key'>{d.title}</div>
          </div>
        )
      })
    }
  }

  initializeValues = (user) => {
    let data = this.chartDataAndRemainingBudget(user);
    this.setState({ chartData: data['chartData'], remainingBudget: data['remainingBudget'] })
  }

  calculateRemainingBudget = (user) => {
    let totalSpent = 0;
    user.contracts.map(c => totalSpent += parseFloat(c.total_price));
    let contractPayments = user.non_contract_payments.filter(ncp => ncp.not_included_in_budget != true && ncp.amount)
    contractPayments.map(cp => totalSpent += parseFloat(cp.amount));
    let remainingBudget = parseFloat(user.budget) - totalSpent;
    return remainingBudget;
  }

  chartDataAndRemainingBudget = (user) => {
    let data = [];
    let remainingBudget = this.calculateRemainingBudget(user);
    if (remainingBudget) {
      let remainingPercentage = (remainingBudget / this.props.user.budget) * 100
      data.push({ title: 'Remaining Budget', value: remainingPercentage, color: '#595959' })
      data.push(this.contractsDataByType());
      return { chartData: data.flat(), remainingBudget: remainingBudget }
    }
  }

  calculateContractTotals = () => {
    let totals = {}
    let { user } = this.props;
    user.contracts.map((contract) => {
      contract.vendor_types.map((vt) => {
        if (totals[vt.vendor_type_id]) {
          totals[vt.vendor_type_id] += vt.amount;
        } else {
          totals[vt.vendor_type_id] = vt.amount;
        }
      })
    })
    user.non_contract_payments.filter(p => p.not_included_in_budget === false && p.amount).map((p) => {
      if (p.vendor_type_id && totals[p.vendor_type_id]) {
        totals[p.vendor_type_id] += p.amount;
      } else if (p.vendor_type_id) {
        totals[p.vendor_type_id] = p.amount;
      }
    })
    return totals;
  }

  calculateSpentTotal = (user) => {
    let contractTotal = user.contracts.reduce((a,b) => a + (parseFloat(b['total_price']) || 0), 0);
    let otherPayments = user.non_contract_payments.filter(ncp => ncp.not_included_in_budget != true && ncp.amount);
    let otherTotal = otherPayments.reduce((a,b) => a + (parseFloat(b['amount']) || 0), 0)
    return contractTotal + otherTotal
  }

  contractsDataByType = () => {
    let totals = this.calculateContractTotals()
    let data = [];
    Object.keys(totals).map((t, index) => {
      let percentage = (totals[t] / this.props.user.budget) * 100;
      let title = this.props.vendorTypes.filter(type => type[0] === parseInt(t))[0];
      title = title ? title[1] : 'Other';
      data.push({ color: BUDGET_COLORS[index], title: title, value:  percentage })
    })
    return data
  }

  formattedRemaining = () => {
    let remainingValue = this.calculateRemainingBudget(this.props.user);
    return <h6 className={ remainingValue < 0 ? 'error' : ''}>Remaining: { formatMoney(remainingValue) }</h6>
  }

  changeLocation = () => {
    if (window.location.pathname != '/budget') {
      window.location.assign('/budget');
    };
  }

  render() {
    return (
      <div className={'budget-chart ' + (this.props.showButtons ? 'budget-chart--dashboard' : '')}>
        { this.props.showButtons
          ?  <div className='header-with-swatch'>
              <h4 className='subtitle is-4 has-text-centered mb-quarter-em section-header'>Budget</h4>
              <div className='main-budget'>{formatMoney(this.props.user.budget)}</div>
            </div>
          : <>
              <h4 className='subtitle is-4 has-text-centered'>{ this.props.header}</h4>
              <div className='main-budget2'>{formatMoney(this.props.user.budget)}</div>
            </>
        }
        <div className='budget-chart__container columns is-desktop'>
          <div className='column is-full'>
            <div className='budget-remaining'>
              { this.formattedRemaining() }
              <h6>Spent: { formatMoney(this.calculateSpentTotal(this.props.user))}</h6>
            </div>

            <div className={'budget-chart__chart'}>
              { this.state.chartData &&
                <PieChart
                  className={window.location.pathname != '/budget' ? 'pointer__svg' : ''}
                  data={this.state.chartData}
                  onClick={this.changeLocation}
                />
              }
            </div>
          </div>

        </div>

        <div className='columns'>
          <div className='column budget-legend'>
            { this.legendColumn() }
          </div>
        </div>


        { this.props.showButtons &&
        <div className='has-text-centered row-buttons' style={{justifyContent: 'center'}}>
          <a
            className='acts-as-button button button-small button-mt link-without-styling'
            href='/vendors?action=new'
          >
            Add Booked Vendors
          </a>
          {/* {this.props.user.has_active_subscription !== true ? null : */}
            <button
              onClick={this.props.toggleExpenseModal}
              className='button button-small'
            >
              Add Other Expenses
            </button>
          {/* } */}
        </div>
        }
      </div>
    )
  }
}

BudgetOverview.propTypes = {
  vendorTypes: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  showButtons: PropTypes.bool.isRequired
}
