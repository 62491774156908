import React from 'react';
import PropTypes from 'prop-types';
import { FormTextInput, FormSelect, DatePickerField, Loading, FormCheckbox } from '../../../../../forms/Helpers';
import { FieldArray } from 'formik';

export default class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  addVendorPayment = () => {
    this.props.values['vendor_payments'].push(
      {total_due: '', due_date: '', days_before_to_send_reminder: '', status: 'unpaid' }
    )
    this.forceUpdate()
  }

  render() {
    let { values } = this.props;
    return (
      <div>
        <div className="spaced-group">
          <div className="field is-grouped half-child-media media-align-flex-end">
            <FormTextInput
              name="total_price"
              label='Total Contract Amount'
              type="number"
              placeholder="$"
            />
            <FormTextInput
              name="number_of_hours_contracted"
              label='Number of Hours Contracted'
              type="number"
              placeholder=""
            />
          </div>
        </div>

          <div className='spaced-group mt-one-em'>
            { this.props.vendorTypesFieldArray(values) }
          </div>


          <div className="field">
            <FormTextInput
              label='Link to Contract'
              name="link_to_document"
              type="text"
              placeholder="Link to Contract"
              tooltip='If your vendor is using a digital contract service, you can add that link here.'
            />
          </div>

          <div className='contract-doc-auth'>
            <div className="field">
              <FormTextInput
                label='Login'
                name="login"
                type="text"
                placeholder="Login"
              />
            </div>

            <div className="field">
              <FormTextInput
                label='Password'
                name="password"
                type="text"
                placeholder="Password"
              />
            </div>
          </div>

          <div className="field">
            <FormTextInput
              label='Note to contract'
              name="note_to_contract"
              type="text"
              placeholder="Note to contract"
            />
          </div>

          <div className="field is-grouped">
            <FormTextInput
              name="deposit_amount"
              type="number"
              label='Deposit'
              placeholder="$"
            />
          </div>

          <div className='spaced-group'>
            { this.props.paymentMethodsFieldArray(values) }
          </div>


          <div className='field'>
            <FormCheckbox
              name='not_included_in_budget'
              type='checkbox'
              label='Do not include this contract in your budget'
              tooltip='Check this box if this is an expense you want to track, but the cost is outside of your listed budget (for example, if the cost is being covered by someone else)'
              />
          </div>

          <div className='mt-one-em'>
            <h5 className='subtitle is-5'>Payments</h5>

            { values.vendor_payments.map((_p, paymentIndex) => {
                return (
                  <div key={paymentIndex}>
                    <h6>Payment { paymentIndex + 1 }</h6>
                      <FieldArray
                        name='vendor_payments'
                        render={arrayHelpers => (
                          <div key={paymentIndex} className='vendor-contract__add vendor-form__nested'>
                            { this.props.vendorPaymentsFields(values, paymentIndex ) }
                            <span className='error clickable' onClick={() => arrayHelpers.remove(paymentIndex)}>delete</span>
                          </div>
                        )} />
                  </div>
                )
              })
            }
            <button
              className='vendor-types__btn vendor-types__btn--add'
              type="button"
              onClick={this.addVendorPayment}>
              <i className='icon icon-plus-circle'></i>
            </button>
          </div>

          <div className='mt-one-em'>
            <button className='button' type='button' onClick={this.props.closeForm}>Cancel</button>
            <button
              type='submit'
              className='button fifty button-primary'
              onClick={() => this.props.setReddyToSubmit(values)}
              disabled={this.props.completeBasicInfoDisabled}
            >
              Submit
            </button>
          </div>
      </div>
    )
  }
}

EditForm.propTypes = {
  values: PropTypes.object.isRequired,
  removeVendorPayment: PropTypes.func.isRequired,
  installmentFieldArray: PropTypes.func.isRequired,
  vendorTypesFieldArray: PropTypes.func.isRequired,
  paymentMethodsFieldArray: PropTypes.func.isRequired,
  vendorPaymentsFields: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
}
