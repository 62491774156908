import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { modalStyle } from './ModalStyle';
import CheckoutModal from '../../adyen/CheckoutModal';

const navItems = [
  { title: 'Dashboard', path: '/' },
  { title: 'Tasks', path: '/tasks' },
  { title: 'Venues', path: '/venues', subItems: [{ title: 'Booked Venues', path: '/venues' }, { title: 'Venue Quotes', path: '/venues-quotes'}] },
  { title: 'Vendors', path: '/vendors', subItems: [{ title: 'Booked Vendors', path: '/vendors' }, { title: 'Vendor Quotes', path: '/quotes'}] },
  { title: 'Budget', path: '/budget', subItems: [{ title: 'My Budget', path: '/budget' }, { title: 'Suggested Budget', path: '/suggested-budget'}] },
  { title: 'Wedding Details', path: '/questionnaires' },
  { title: 'Lists', path: '/lists' },
  { title: 'FAQs', path: '/faqs', className: 'additional_links' },
  { title: 'Terms of Service', path: '/terms_of_service', target: '_blank', className: 'additional_links' },
  { title: 'Privacy Policy', path: '/privacy_policy', target: '_blank', className: 'additional_links' },
]

export default class DashboardNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalContent: null,
    }
  }

  componentDidMount() {
    if (typeof(window) !== 'undefined') {
      Modal.setAppElement('body')
    }
    if (window.location.href.includes('payment_error=')) {
      let error = window.location.href.split('payment_error=')[1];
      this.setState({ modalIsOpen: true, modalContent: 'upgrade', redirectPaymentError: decodeURIComponent(error).replace(/\+/g, ' ') })
      setTimeout(() => this.setState({ redirectPaymentError: null }), 4000);
    }
    window.addEventListener('resize', this.updateWidth);
  }

  updateWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  navItems = () => {
    return navItems.map((item) => {
      return this.navListItem(item, this.props.page, this.props.user);
    })
  }

  emulateLinkClick = (e, item) => {
    if (!item.subItems) {
      let link = e.target.nodeName === 'A' ? e.target : e.target.querySelector('a')
      if (link) {
        link.click()
      } else {
        window.location.href = item.path
      }
    }
  }

  navListItem = (item, page, user) => {
    const isActive = item.title.toLocaleLowerCase() === page.toLocaleLowerCase();
    if (user.completed_intro_information === true) {
      return (
        <li
          className={'dashboard__li ' + (isActive ? ' active' : '') + (item.className ? ' '+ item.className + ' ' : '') + (item.subItems ? ' has-subitems' : '')}
          onClick={(e) => this.emulateLinkClick(e, item)}
          key={item.title} >
          { item.subItems &&
            <div className='dashboard__subitems'>
              { item.title }
              { item.subItems.map(s => {
                return <a key={s.title} className={s.title.toLocaleLowerCase() === page.toLocaleLowerCase() ? 'active' : ''} href={s.path}>{s.title}</a>
              })}
            </div>
          }
          { !item.subItems &&
            <a href={item.path} target={item.target || '_self'}>{item.title}</a>
          }
        </li>
      )
    } else {
      return <li className='dashboard__li' key={item.title} ><span data-tooltip="Please complete your profile before continuing." className="has-tooltip-right">{item.title}</span></li>
    }
  }

  weddingHeader(user) {
    if ( user.first_name && user.partner_first_name ) {
      return `${user.first_name} & ${user.partner_first_name}'s Wedding`;
    }
  }

  upgrade = () => {
    if (!this.props.user.has_active_subscription) {
      return (
        <button
          onClick={this.toggleUpgrade}
          className='button'
          style={{background: '#a17188', color: 'white'}}
        >
          Upgrade
        </button>
      )
    }
  }

  toggleUpgrade = () => {
    this.setState({ modalIsOpen: true, modalContent: 'upgrade', sliderOpen: false })
  }

  toggleModal = () => {
    this.setState({ modalIsOpen: false, modalContent: null })
  }

  modalContent = () => {
    const { modalIsOpen, modalContent } = this.state;
    if (modalIsOpen && modalContent === 'upgrade') {
      return (
        <CheckoutModal
          user={this.props.user}
          token={this.props.token}
          clientKey={this.props.clientKey}
          stripePublishableKey={this.props.stripePublishableKey}
          modalIsOpen={modalIsOpen}
          closeModal={() => this.setState({ modalIsOpen: false, modalContent: null })}
        />
      )
    }
  }

  render() {
    const { user } = this.props;
    return (
      <>
        <div className='dashboard__navbar'>
          <div className='sidebar'>
            <div className='sidebar__logo'>
              <a href="/">
                <img src='/images/gb-logo.png' alt='Guided Bride Logo' />
              </a>
            </div>
            <div className='sidebar__column'>
              { this.navItems() }
            </div>
            <div className='has-text-centered email-for-help'>
              <p>Need help? Email us here:</p>
              <a href="mailto:help@guidedbride.com">
                help@guidedbride.com
              </a>
            </div>
            <div className={'sidebar__social'}>
              <a href="https://www.facebook.com/guidedbride" target="_blank">
                <i className="icon icon-facebook"></i>
              </a>
              <a href="https://www.instagram.com/guidedbride" target="_blank">
                <i className="icon icon-instagram"></i>
              </a>
              <a href="https://www.pinterest.com/guidedbride" target="_blank">
                <i className="icon icon-pinterest"></i>
              </a>
            </div>
          </div>
        </div>

        <div className={'slider ' + (this.state.sliderOpen ? 'slider--open' : 'slider--closed')}>
          <div className='slider__nav'>
            <div>
              <button onClick={() => this.setState({ sliderOpen: !this.state.sliderOpen })}>
                <i className={'icon ' + (this.state.sliderOpen ? 'icon-clear' : 'icon-menu')}></i>
              </button>
              <a className='home' href='/'>
                <i className='icon icon-home'></i>
              </a>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              { this.upgrade() }
              <button onClick={() => this.setState({ isAccountOpen: !this.state.isAccountOpen })}>
                  <i className={'icon icon-user-circle'}></i>
                  <div className={`account-modal ${this.state.isAccountOpen ? 'account-modal__open' : 'account-modal__closed'}`}>
                    <div>
                      <a href='/account'>Account</a>
                      <a href='/users/sign_out'>Log Out</a>
                    </div>
                  </div>
              </button>
            </div>
          </div>

          <div className='slider__container'>
            <div className='slider__options'>
              <h5
                className={'title is-5 has-text-centered main-wedding-header'}>
                { this.weddingHeader(user) }
              </h5>
              { this.navItems() }
            </div>
            <div className='has-text-centered slider__options email-for-help'>
              <p>Need help? Email us here:</p>
              <a href="mailto:help@guidedbride.com">help@guidedbride.com</a>
            </div>
          </div>
        </div>

        <div className='slider--desktop'>
          <div className='slider--desktop__nav'>
            <h3
              className={'title is-4 main-wedding-header'}>
              { this.weddingHeader(user) }
            </h3>
            <div style={{display: 'flex', alignItems: 'center'}}>
              { this.upgrade() }
              <button onClick={() => this.setState({ isAccountOpen: !this.state.isAccountOpen })}>
              <i className={'icon icon-user-circle'}></i>
              <div className={`account-modal ${this.state.isAccountOpen ? 'account-modal__open' : 'account-modal__closed'}`}>
                <div>
                  <a href='/account'>Account</a>
                  <a href='/users/sign_out'>Log Out</a>
                </div>
              </div>
            </button>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.toggleModal}
          style={modalStyle(this.state.width, '#F3F1EE')}>
          <div className='dashboard-modal'>
            { this.modalContent()}
          </div>
        </Modal>
      </>
    )
  }
}

DashboardNav.propTypes = {
  page: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  clientKey: PropTypes.string.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
}
