import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Modal from 'react-modal';
import { modalStyle } from '../ModalStyle';
import TaskList from './TaskList';
import ActiveTask from './ActiveTask';
import { tasksByDate, TASK_STATUSES, UNFINISHED_STATUSES } from './TaskFunctions';
import NewTaskForm from './NewTaskForm';
import RelatedTaskModal from '../tasks/RelatedTaskModal';
import CheckoutModal from '../../../adyen/CheckoutModal';
export default class Tasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: this.props.user.tasks,
      filteredTasks: this.props.user.tasks,
      activeTask: this.initialTask(),
      activeFilter: 'all',
      tagFilter: null,
      showDescriptionForm: false,
      showDateForm: false,
      showProgressPrompt: false,
      modalIsOpen: false,
      relatedTaskModalOpen: false,
      width: window.innerWidth,
    }
  }

  componentDidMount() {
    if (typeof(window) !== 'undefined') {
      Modal.setAppElement('body')
    }
    window.addEventListener('resize', this.updateWidth);
  }

  updateWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  initialTask = () => {
    if (window.innerWidth > 700) {
      return this.todayOrNextTask(this.props.user.tasks);
    } else {
      return null
    }
  }

  todayOrNextTask = (tasks) => {
    if (tasks.length === 0) { return null }
    const today = this.dateWithoutHours(new Date);
    const tasksSortedByDate = tasksByDate(tasks);
    const unfinishedTasks = tasksSortedByDate.filter((t) => UNFINISHED_STATUSES.includes(t.status));
    const todayAndUpcomingUnfinishedTasks = unfinishedTasks.filter((t) => Date.parse(this.dateWithoutHours(t.due_date)) >= Date.parse(today));
    const task = todayAndUpcomingUnfinishedTasks.length > 0
      ? Object.assign({}, todayAndUpcomingUnfinishedTasks[0])
      : Object.assign({}, unfinishedTasks.length > 0 
        ? unfinishedTasks[unfinishedTasks.length - 1]
        : tasksSortedByDate[tasksSortedByDate.length - 1]
      );
    if (!task.notes || task.notes.length === 0) {
      task.notes = task.description
    }
    return task;
  }

  handleUpdateSubmit = () => {
    let { activeTask, activeFilter } = this.state;
      let users_checklist_item = activeTask;
      axios.patch(`/api/v1/users_checklist_items/${activeTask.id}`, {
        users_checklist_item,
        authenticity_token: this.props.token
      })
      .then((response) => {
        const tasks = this.updateTaskInCollection(response.data.item);
        const filteredTasks = this.filteredTasks(activeFilter, tasks);
        this.setState({ statusChangeResponse: response, tasks, filteredTasks, activeTask: response.data.item, showDescriptionForm: false, showDateForm: false }, () => {
          setTimeout(() => this.setState({ statusChangeResponse: null }), 2000)
        })
      })
      .catch((error) => {
        this.setState({ statusChangeResponse: error.response }, () => {
          setTimeout(() => this.setState({ statusChangeResponse: null }), 4000)
        })
      })
  }

  closeAllModals = () => {
    this.setState({ modalIsOpen: false, relatedTaskModalOpen: false })
  }

  handleFieldUpdate = (field, val, submit = false) => {
    const activeTask = Object.assign({}, this.state.activeTask);
    activeTask[`${field}`] = val;
    const showProgressPrompt = field === 'status' && val === 'in_progress';
    const relatedTaskModalOpen = field === 'status' && val === 'complete' && activeTask.tags.length;
    if (submit === true) {
      this.setState({ activeTask, relatedTaskModalOpen, showProgressPrompt }, () => this.handleUpdateSubmit())
    } else {
      this.setState({ activeTask, relatedTaskModalOpen, showProgressPrompt })
    }
  }

  updateTaskInCollection = (item) => {
    let tasks = [...this.state.tasks];
    let itemToRemove = tasks.filter(t => t.id === item.id)[0]
    if (itemToRemove) {
      tasks[tasks.indexOf(itemToRemove)] = item;
    }
    return tasks;
  }

  dateWithoutHours(d) {
    let dateToUpdate = new Date(d);
    dateToUpdate.setHours(0,0,0,0);
    return dateToUpdate
  }

  filteredTasks = (status, updatedItems) => {
    const tasks = updatedItems ? updatedItems : [...this.state.tasks]
    const today = this.dateWithoutHours(new Date)
    switch (status) {
      case 'all':
        return tasks;
      case 'due_today':
        return tasks.filter(t => this.dateWithoutHours(t.due_date).getTime() === today.getTime())
      case 'overdue':
        return tasks.filter(t =>
          t.status != TASK_STATUSES['complete'] &&
          this.dateWithoutHours(t.due_date).getTime() < today.getTime()
        )
      default:
        return tasks.filter(t => t.status === TASK_STATUSES[status]);
    }
  }

  filterByStatus = (status) => {
    const filteredTasks = this.filteredTasks(status);
    this.setState({ filteredTasks, activeTask: {}, activeFilter: status })
  }

  removeActiveTask = () => {
    this.setState({ activeTask: {} });
  }

  filterByTag = (tag) => {
    let tasks = this.props.user.tasks;
    if (tag === this.state.tagFilter) {
      this.resetTasks()
    } else {
      let filtered = tasks.filter(t => t.tags.includes(tag))
      this.setState({ tasks: filtered, tagFilter: tag, activeFilter: 'all' })
    }
  }

  resetTasks = () => {
    this.setState({ tasks: this.props.user.tasks, tagFilter: null })
  }

  setActiveTask = (task) => {
    let foundTask = this.state.tasks.filter(t => t.id === task.id)[0];
    if (foundTask) {
      let activeTask = Object.assign({}, foundTask);
      activeTask = this.activeTaskWithNotesAdjusted(activeTask);
      this.setState({ activeTask, showDescriptionForm: false, showNewTaskForm: false })
    }
  }

  activeTaskWithNotesAdjusted(task) {
    if (!task.notes || task.notes.length === 0) {
      task.notes = task.description;
    }
    return task
  }

  toggleForm = (formName, isPaywalled = false) => {
    if (isPaywalled === true && this.props.user.has_active_subscription === false ) {
      this.setState({ modalIsOpen: true })
    } else {
      let task = this.props.user.tasks.filter(t => t.id === this.state.activeTask.id)[0]
      task = this.activeTaskWithNotesAdjusted(this.state.activeTask)
      // task = this.activeTaskWithNotesAdjusted(task)
      this.setState({ [`show${formName}`]: !this.state[`show${formName}`], activeTask: task })
    }
  }

  progressPromptResponse = (openDateForm) => {
    if (openDateForm) {
      this.setState({ showDateForm: true, showProgressPrompt: false })
    } else {
      let { activeTask } = this.state;
      let showProgressWarning = activeTask && Date.parse(activeTask.due_date) < new Date() ? true : false
      this.setState({ showProgressPrompt: false, showProgressWarning }, () => {
        setTimeout(() => this.setState({ showProgressWarning: false }), 2000)
      })
    }
  }

  addNewTask = (task) => {
    console.log(task)
    const tasks = [...this.state.tasks]
    tasks.push(task);
    const filteredTasks = this.filteredTasks('all', tasks);
    this.setState({ tasks, filteredTasks, activeFilter:'all', activeTask: task, showNewTaskForm: false })
    // this.setActiveTask(task)
  }

  toggleNewTaskForm = () => {
    if (this.props.user.has_active_subscription) {
      this.setState({ showNewTaskForm: true, activeTask: {} })
    } else {
      this.setState({ modalIsOpen: true })
    }
  }

  subscribeModal = () => {
    return (
      <CheckoutModal
        modalIsOpen={this.state.modalIsOpen}
        closeModal={() => this.setState({ modalIsOpen: false })}
        user={this.props.user}
        token={this.props.token}
        clientKey={this.props.clientKey}
        stripePublishableKey={this.props.stripePublishableKey}
      />
    )
  }

  render() {
    let { activeTask, tasks, filteredTasks, statusChangeResponse, activeFilter, tagFilter } = this.state;
    return (
      <div className='standard-padding dashboard__tasks dashboard__inner'>
        <div className={'dashboard__task-list dashboard__left ' + (this.state.activeTask && Object.keys(this.state.activeTask).length !== 0
&& Object.getPrototypeOf(this.state.activeTask) === Object.prototype ? 'dashboard__left--collapsed' : 'dashboard__left--full')}>
          <div className='dashboard__container' style={{position: 'relative'}}>
            <div className='header-with-button'>
              <div className='header-with-swatch header-with-swatch--left-justified'>
                <h2 className="title dashboard__header">Task timeline</h2>
              </div>
              { !this.state.showNewTaskForm &&
                <button
                  type='button'
                  className='button button-primary add-new'
                  onClick={this.toggleNewTaskForm}>
                  add new
                </button>
              }
            </div>

            { this.subscribeModal() }
            { this.state.relatedTaskModalOpen &&
              <Modal
                isOpen={true}
                onRequestClose={this.closeAllModals}
                style={modalStyle(this.state.width, '#F3F1EE')}
              >
                <i
                  className='icon icon-close clickable'
                  style={{
                    position:'absolute',
                    right:'1.5em',
                    top:'1.5em'
                  }}
                  onClick={this.closeAllModals}
                />
                <RelatedTaskModal
                  tasks={tasks}
                  activeTask={activeTask}
                  token={this.props.token}
                  handleUpdateSubmit={this.handleUpdateSubmit}
                  closeModal={this.closeAllModals}
                  isModalOpen={true}
                  isOnTasks={true}
                />
              </Modal>
            }

            <TaskList
              tasks={filteredTasks}
              tagFilter={tagFilter}
              resetTasks={this.resetTasks}
              activeFilter={activeFilter}
              taskStatuses={TASK_STATUSES}
              filterByStatus={this.filterByStatus}
              activeTask={activeTask}
              makeActive={this.setActiveTask}/>
          </div>
        </div>
        <div className={'dashboard__task-item dashboard__right ' + (this.state.activeTask && Object.keys(this.state.activeTask).length !== 0
&& Object.getPrototypeOf(this.state.activeTask) === Object.prototype ? 'dashboard__right--full' : 'dashboard__right--collapsed')}>
          <div className='dashboard__container tasks-down'>
            { this.state.showNewTaskForm &&
              <NewTaskForm
                token={this.props.token}
                user={this.props.user}
                addNewTask={this.addNewTask}
                toggleNewTaskForm={() => this.setState({ showNewTaskForm: !this.state.showNewTaskForm })} />
            }
            { !this.state.showNewTaskForm &&
              <ActiveTask
                task={activeTask}
                showProgressPrompt={this.state.showProgressPrompt}
                taskStatuses={TASK_STATUSES}
                tagFilter={this.state.tagFilter}
                filterByTag={this.filterByTag}
                handleUpdateSubmit={this.handleUpdateSubmit}
                showDescriptionForm={this.state.showDescriptionForm}
                showDateForm={this.state.showDateForm}
                showProgressWarning={this.state.showProgressWarning}
                progressPromptResponse={this.progressPromptResponse}
                toggleForm={this.toggleForm}
                removeActiveTask={this.removeActiveTask}
                handleFieldUpdate={this.handleFieldUpdate}
                statusChangeResponse={statusChangeResponse}
                user={this.props.user}
                token={this.props.token}
                clientKey={this.props.clientKey}
                stripePublishableKey={this.props.stripePublishableKey}
              />
            }

            { this.state.showNewTaskForm}

          </div>
        </div>
      </div>
    )
  }
}

Tasks.propTypes = {
  user: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  clientKey: PropTypes.string.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
}
