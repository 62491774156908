import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Chat from '../../../../chat/Chat';
import ContractsContainer from './contracts/ContractsContainer';
import VendorContacts from './tabs/contacts/VendorContacts';
import CheckoutModal from '../../../../adyen/CheckoutModal';
import { Loading } from '../../../../forms/Helpers';

export default class VendorsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.vendor && this.props.vendor && prevProps.vendor.id != this.props.vendor.id) {
    //   this.setState({ activeTab: TABS[0] });
    // }
  }

  contacts = () => {
    let { vendor } = this.props;
    if (vendor.contacts && vendor.contacts.length > 0) {
      return vendor.contacts.map(c => {
        return this.contact(c);
      })
    } else if (vendor) {
      return (
        <div className='vendors__contact'>
          <h5 className='is-5 subtitle mb-one-em'>No contacts added yet.</h5>
        </div>
      )
    }
  }

  contact(person) {
    return (
      <div className='vendor-contact' key={person.id}>
        <h5>{person.first_name} {person.last_name}</h5>
        <p>{person.email}</p>
        <p>{person.phone_number}</p>
      </div>
    )
  }

  vendorChat = () => {
    let { vendor } = this.props;

    if (this.props.user.has_active_subscription != true) {
      return (
        <div>
          {/* <p>When you sign up for a Guided Bride subscription you can chat with your vendors in real time
            and receive email notifications from them when you're not online!
          </p>
          <button
            className='button button-primary mt-one-em'
            onClick={() => this.setState({ modalIsOpen: true })}
            type='button'>Subscribe Now</button> */}

          <p className='has-text-centered'>Coming soon...</p>

          <CheckoutModal
            token={this.props.token}
            user={this.props.user}
            clientKey={this.props.clientKey}
            stripePublishableKey={this.props.stripePublishableKey}
            modalIsOpen={this.state.modalIsOpen}
            closeModal={() => this.setState({ modalIsOpen: false })} />
        </div>
      )
    } else if (vendor.contacts.length > 0) {
      return (
        // <div className='chat__container'>
        //   <Chat user={this.props.user} vendor={vendor} userType={'user'} />
        // </div>
        <p className='has-text-centered'>Coming soon...</p>
      )
    } else {
      return (
        <div className='chat__container'>
          <p>Add a contact to get started! Once you've added a contact we will send
          an invitation for your vendor join the chat.</p>
        </div>
    )
    }
  }

  tabs = () => {
    return this.props.tabs.map((t) => {
      return (
          <li
            key={t}
            className={this.props.activeTab === t ? 'is-active' : ''}>
            <a onClick={() => this.props.setActiveTab(t)}>{t}</a>
          </li>
      )
    })
  }

  convertVendorToQuote = () => {
    if (confirm('Are you sure you want to convert this vendor to a quote? You will lose the contacts and contracts you have added.')) {
      this.setState({ isSubmitting: true }, () => {
        axios.post(`/api/v1/convert_vendor_to_quote/${this.props.vendor.id}`, {
          authenticity_token: this.props.token
        })
        .then((response) => {
          this.setState({ conversionSuccess: true, isSubmitting: false }, () => {
            setTimeout(() => this.props.handleDeletedVendor(this.props.vendor.id, true), 2000)
          })
        })
        .catch((error) => {
          this.setState({ conversionError: true, isSubmitting: false }, () => {
            setTimeout(() => this.setState({ conversionError: null }), 2000)
          })
        })
      })
    }
  }

  currentTabContent = () => {
    switch (this.props.activeTab) {
      case 'Info':
        return (
          <>
          { this.state.conversionError &&
            <p className='error'>There was a problem converting this vendor to a quote. Please try again later.</p>
          }
          { this.state.conversionSuccess &&
            <p className='success'>You will now find this vendor in your quotes!</p>
          }
          <VendorContacts {...this.props} />
          <button
            className='button button-small mb-one-em'
            onClick={this.props.toggleEditVendorForm}>edit { this.props.isVenue ? 'venue' : 'vendor' } info</button>
            
          {this.props.vendor.note ?
            <div className='notes'>
              <h5 className="is-5 subtitle mb-one-em has-text-centered">Services Offered/Notes</h5>
              <p>{this.props.vendor.note}</p>
            </div>
          : ''}
          <div className='oops'>
            <a onClick={this.convertVendorToQuote}>Oops, I haven’t booked this vendor. Send vendor
                back to quotes
            </a>
          </div>
          </>
        )
      case 'Chat':
        return this.vendorChat();
      case 'Contracts':
       return (
        <ContractsContainer
          {...this.props}
          addOrUpdateContract={this.props.addOrUpdateContract}
          removeDeletedContract={this.props.removeDeletedContract} />
       )
  }
  }

  render() {
    let { vendor, allVendorsLength } = this.props;
    if (this.state.isSubmitting) {
      return ( <Loading />)
    }
    else if (vendor) {
      return (
        <div>
          <div className='vendors__detail'>
            <i className="icon icon-arrow-left sm-only clickable" onClick={this.props.closeVendorDetail}></i>
            <h4 className='title is-4 is-spaced has-text-centered'>{ vendor.company_name }</h4>

            <div className="tabs">
              <ul>
                { this.tabs() }
              </ul>
            </div>
            { this.currentTabContent() }
        </div>
      </div>
      )
    } else if (allVendorsLength === 0) {
      return (
        <div className='vendors__detail'>
          <h4 className='title is-4'>Get started by adding your first { this.props.isVenue ? 'venue' : 'vendor'}.</h4>
        </div>
      )
    } else {
      return (
        <div className='vendors__detail'>
          <h4 className='title is-4'>Select a { this.props.isVenue ? 'venue' : 'vendor'} for more information</h4>
        </div>
      )
    }
  }
}

VendorsDetail.propTypes = {
  vendor: PropTypes.object,
  clientKey: PropTypes.string.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
  toggleEditVendorForm: PropTypes.func.isRequired,
  closeVendorDetail: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  addOrUpdateContract: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  handleNewVendorPayment: PropTypes.func
}
