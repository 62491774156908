import React from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import Modal from 'react-modal';
import Checkout from './Checkout';
import { modalStyle } from '../users/dashboard/ModalStyle';

export default class CheckoutModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      promoCode: '',
      showPayment: false,
      promoCodeChecked: false,
      dollarsPrice: null,
      discount: 0,
    }
  }

  componentDidMount() {
    if (typeof(window) !== 'undefined') {
      Modal.setAppElement('body')
    }
    window.addEventListener('resize', this.updateWidth);
    this.priceCalculationAndVerificationWithServer();
  }

  updateWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  paywallHeader = () => {
    // let header = 'Please add your payment method here for Guided Bride full access.'
    let header = 'Become a Guided Bride, full access member for $24.99/month.'

    // -------------------------------------------------------------------------------------------------------//
    //                                               HI!                                                      //
    // In some places, the props were transferred to the component with a change in the original inscription, //
    // the customer said to change it, so look if you are not too lazy, as it was before.                     //
    // -------------------------------------------------------------------------------------------------------//

    // if (this.props.paywallHeader) {
    //   header = this.props.paywallHeader;
    // } 
    return ( <h5 className='is-5 subtitle'>{ header }</h5> )
  }

  paywallText = () => {
    // let text = 'Get full access to all features free for 30 days by entering promo code bellow.'

    // -------------------------//
    // Similar to what is above //
    // -------------------------//

    // if (this.props.paywallText) {
    //   text = this.props.paywallText;
    // }
    // return ( <p> {text} </p> )
    if (!this.props.renewSubscription) {
      return (
        <p>Get 30 days free with promo code <strong>FREE30DAYS</strong>.</p>
      )
    } else {
      return null
    }
  }

  priceCalculationAndVerificationWithServer = () => {
    Axios.post('/subscriptions/price_calculation', {
      subscription_type: {
        promo_code: this.state.promoCode
      },
      user_id: this.props.user.id,
      authenticity_token: this.props.token
    })
    .then((response) => {
      if (response.status === 200) {
        this.setState({
          dollarsPrice: response.data.final_price,
          discount: response.data.discount,
          showPayment: true,
          promoCodeChecked: !!this.state.promoCode,
        })
      } else {
        this.setState({showPayment: false})
      }
    })
    .catch(() => {
      this.setState({showPayment: false})
    })
  }

  successMessage = () => {
    const { discount, promoCode } = this.state;

    if (promoCode.toUpperCase() === 'FREE30DAYS' && !!discount)
      return <div className="discount">Congratulations, you’re getting 30 days free!</div>

    return (!!discount && <div className="discount">Your discount on this purchase will be <span>{discount}%</span></div>)
  }

  render() {
    const {
      showPayment,
      dollarsPrice,
      discount,
      promoCodeChecked,
      promoCode } = this.state;

    const activeSubscriptions = (this.props.user.subscriptions || []).filter((item) => item.isActive )
    const activeSubscription = activeSubscriptions ? null : activeSubscriptions[activeSubscriptions.length - 1];
    const nextChargeDate = activeSubscription ? activeSubscription.next_charge_date : null;
    if (this.props.user.has_active_subscription === true && nextChargeDate) {return null}
    else {
      return (
        <Modal
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.props.closeModal}
          style={modalStyle(this.state.width, '#F3F1EE')}
        >
          <>
            <i className='icon icon-close clickable checkout__close' onClick={this.props.closeModal} />
            <div style={{clear: 'both', textAlign: 'center'}}>
              { this.paywallHeader() }
              { this.paywallText() }
            </div>
            <div className={'checkout__subscription'}>
              {!this.props.renewSubscription &&
                <label className={'promo-code'}>
                  Enter promo code here:
                  <input
                    type="text"
                    placeholder={'PROMO CODE'}
                    spellCheck={'false'}
                    value={promoCode}
                    onChange={e => this.setState({promoCode: e.target.value, promoCodeChecked: false})} 
                    onBlur={() => this.setState({showPayment: false}, this.priceCalculationAndVerificationWithServer)}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') this.setState({showPayment: false}, this.priceCalculationAndVerificationWithServer)}
                    }
                  />
                  { this.successMessage() }
                  { !!promoCode && !discount && promoCodeChecked &&
                    <div className="discount">
                      This promo code is not applicable
                    </div>
                  }
                </label>
              }
            </div>
            { this.props.renewSubscription ?
              <p>Your card will automatically be charged $24.99 for your full access membership. You may cancel at any time in the subscription area of your account settings.</p> :
              <p>After 30 days, your card will automatically be charged $24.99 for your full access membership. You may cancel at any time in the subscription area of your account settings.</p>
            }

          {showPayment && dollarsPrice !== null &&
            <Checkout
              dollarsPrice={dollarsPrice}
              subscriptionPer={'month'}
              promoCode={promoCode}
              user={this.props.user}
              token={this.props.token}
              clientKey={this.props.clientKey}
              stripePublishableKey={this.props.stripePublishableKey}
            />
          ||
            <div style={{height: 428}}/>
          }
          </>
        </Modal>
      )
    }

  }
}

CheckoutModal.propTypes = {
  user: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  clientKey: PropTypes.string.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  // paywallText: PropTypes.string,
  // paywallHeader: PropTypes.string
}
