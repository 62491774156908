import React from 'react';
import PropTypes from 'prop-types';
import Talk from "talkjs";

export default class GuidedBrideChat extends React.Component {
  constructor(props) {
    super(props);
    this.talkjsContainer = React.createRef();
    this.state = {}
  }

  componentDidMount() {
    this.initializeChat();
  }

  componentDidUpdate() {
    window.talkSession.destroy();
    this.initializeChat();
  }

  initializeChat = () => {
    const { user, vendor } = this.props;
    Talk.ready.then(() => {
      let me = new Talk.User({
        id: `user_${user.unique_identifier}`,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        role: 'user'
      });

    window.talkSession = new Talk.Session({
      appId: "tPmDVED8",
      me: me
    });

      let other = new Talk.User({
        id: `vendor_${vendor.chat_id}`,
        name: 'Guided Bride',
        email: vendor.email,
        role: 'vendor'
      });

      let conversation = window.talkSession.getOrCreateConversation(Talk.oneOnOneId(me, other))
      conversation.setParticipant(me);
      conversation.setParticipant(other);
      //let inbox = window.talkSession.createInbox({selected: conversation});
      let chat = window.talkSession.createChatbox(conversation);
      chat.mount(this.talkjsContainer.current);
    })
  }

  render() {
    return (
      <div>
        <div className='vendors__chat'
              ref={this.talkjsContainer}></div>
      </div>

    )
  }
}

GuidedBrideChat.propTypes = {
  user: PropTypes.object.isRequired,
  vendor: PropTypes.object.isRequired
}
