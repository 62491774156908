import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

export default class CurrentVideo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return (
      <div>
        <ReactPlayer
          url={this.props.video.embed_url}
          controls={true} />
        <h2>{ this.props.video.title }</h2>
        <div>
          <p>{ this.props.video.description}</p>
        </div>
      </div>
    )
  }
}

CurrentVideo.propTypes = {
  video: PropTypes.object.isRequired
};
