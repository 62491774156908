import React from 'react';
import PropTypes from 'prop-types';
import Header from './layout/Header';
import Footer from './layout/Footer';

export default class Faqs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openFaqs: []
    }
  }

  toggleFaq = (faq) => {
    let openFaqs = [...this.state.openFaqs];
    if (openFaqs.includes(faq)) {
      openFaqs.splice(openFaqs.indexOf(faq), 1);
    } else {
      openFaqs.push(faq);
    }
    this.setState({ openFaqs })
  }
  questionAndAnswer = (faq) => {
    let { openFaqs } = this.state;
    return (
      <div className='faqs__item'>
        <div className='faqs__question' onClick={() => this.toggleFaq(faq)}>
          <span>{faq.question}</span>
          <i className={'icon icon-' + (openFaqs.includes(faq) ? 'up' : 'down')}></i>
        </div>
        { openFaqs.includes(faq) &&
          <div className='faqs__answer'>
            <p>{faq.answer}</p>
          </div>
        }
      </div>
    )
  }

  render() {
    return (
      <div className="faq-page">
        {/* <Header /> */}
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className={"navbar-menu " + (this.state.isActive ? 'is-active' : '')}>
            <div className="navbar-start">
              <div className="navbar-item">
                <div className="buttons">
                  <a className="acts-as-button button button-login" href="/">&lt;</a>
                </div>
              </div>

              <div className="navbar-item faq-title">
                <h1 className="is-2 title">Frequently Asked Questions</h1>
              </div>
            </div>
            {/* <div class="navbar-center faq-title">
              <div class="navbar-item">
                <h1 class="is-2 title">Frequently Asked Questions</h1>
              </div>
            </div> */}
            { this.props.user ? null :
              <div className="navbar-end">
                <div className="navbar-item">
                  <div className="buttons">
                    <a className="acts-as-button button button-login" href='/users/sign_in'>
                      Log in
                    </a>
                    <a className="acts-as-button button button-primary button-signup" href='/users/sign_up'>
                      Sign up
                    </a>
                  </div>
                </div>
              </div>
            }
          </div>
        </nav>
        <div className='faqs'>
          <h1 className='is-2 title'>Frequently Asked Questions</h1>

          { this.props.faqs.map(f => {
            return (
              this.questionAndAnswer(f)
            )
          })}
        </div>
        <Footer />
      </div>
    )
  }
}

Faqs.propTypes = {

}
