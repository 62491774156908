import React from 'react';
import PropTypes from 'prop-types';
import { FormResponse } from '../../../forms/Response';
import CheckoutModal from '../../../adyen/CheckoutModal';

export default class ActiveTask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      specialOffer: null,
      tip: null,
      updatedNote: ``,
      modalIsOpen: false,
      currentTask: null
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.task && this.props.task !== prevProps.task) {
      let { task } = this.props;
      this.setState({ currentTask: task })
      let updatedNote = this.descriptionContent(task);
      this.setState({ updatedNote })
    }
  }

  descriptionContent(task) {
    if (task.notes && task.notes.length > 0) {
      return task.notes;
    } else if (task.description && task.description.length > 0) {
      return task.description;
    } else {
      return '';
    }
  }

  statusChangeResponse = () => {
    let { statusChangeResponse } = this.props;
    if (statusChangeResponse) {
      return FormResponse(statusChangeResponse.data.message, statusChangeResponse.status)
    }
  }

  taskButton = (task, number) => {
    if (task[`link_button_${number}_text`] && task[`link_button_${number}_location`]) {
      return (
        <div className='active-task__button has-text-centered mt-one-em mb-quarter-em'>
          <a
            target="_blank"
            className='acts-as-button button button-primary'
            href={task[`link_button_${number}_location`]}>{task[`link_button_${number}_text`] }</a>
        </div>
      )
    }
  }

  tags = () => {
    let { task } = this.props;
    if (task && task.tags && task.tags.length > 0 && this.props.tagDisabled != true) {
      return (
        <React.Fragment>
          <span className='has-text-weight-bold active-card__tags-header'>Related Tags</span>
          <div className='active-card__tags'>
            { this.tagsList(task.tags) }
          </div>
        </React.Fragment>
      )
    }
  }

  tagsList = (tags) => {
    return tags.map((t,index) => {
      return (
        <React.Fragment key={t + index}>
          <span
            onClick={() => this.props.filterByTag(t)}
            className={'active-card__tag active-card__tag--desktop' + (t === this.props.tagFilter ? ' active-card__tag--active' : '')}>{t}</span>
          <span
            onClick={() => {
              this.props.filterByTag(t)
              this.props.removeActiveTask()
            }}
            className={'active-card__tag active-card__tag--phone' + (t === this.props.tagFilter ? ' active-card__tag--active' : '')}>{t}</span>
          </React.Fragment>
      )
    })
  }

  statusFields = () => {
    let statuses = {'in_progress': 1, 'complete': 2 }
    return ['in_progress', 'complete'].map((s) => {
      let currentlySet = this.props.task.status === statuses[s]
      return (
        <span
          key={s}
          className='clickable'
          disabled={currentlySet}
          onClick={() => {
            if (!currentlySet) { this.props.handleFieldUpdate('status', s, true)}}
          }>
          <i className={'icon icon-checkbox-' + (currentlySet ? 'checked' : 'unchecked')}></i>
          { s.split('_').join(' ') }
        </span>
      )
    })
  }

  notApplicable = (task) => {
    if (task.admin_generated === true) {
      const NOT_APPLICABLE_ENUM = 3;
      let currentlySet = this.props.task.status === NOT_APPLICABLE_ENUM;
      return (
        <div className='field active-task__na'>
          <span
            className='clickable'
            disabled={false}
            onClick={() => this.props.handleFieldUpdate('status', 'na', true)}>
            <i className={'icon icon-checkbox-' + (currentlySet ? 'checked' : 'unchecked')}></i>
            This task does not apply to us
          </span>
        </div>
      )
    }
  }

  formattedDate = (showForm, task) => {
    if (showForm === true) {
      return (
        <div className='field'>
          <input
            className='control'
            value={task.due_date}
            type='date'
            onChange={(e) => this.props.handleFieldUpdate('due_date', e.target.value)}></input>
          <div className='options'>
            <button
              type='button'
              onClick={() => this.props.toggleForm('DateForm')}
              className='button button-small'>cancel</button>
            <button
              type='button'
              onClick={this.props.handleUpdateSubmit}
              className='button button-primary button-small'>submit</button>

          </div>
        </div>
      )
    } else {
      if (!task.due_date) return null

      let splitDate = task.due_date.split('-')
      return (
        <p
          onClick={() => this.props.toggleForm('DateForm')}
          className='active-card__date'>Due: {`${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`}</p>
      )
    }
  }

  descriptionSubmitDisabled = () => {
    let { task } = this.props;
    let { updatedNote } = this.state;
    if (updatedNote === '' || updatedNote === task.notes || updatedNote === task.description) {
      return true
    } else { return false }
  }

  toggleDescriptionForm = () => {
    let updatedNote = this.descriptionContent(this.props.task);
    this.setState({ updatedNote }, () => {
      this.props.toggleForm('DescriptionForm');
    })
  }

  taskDescription = (task) => {
    if (this.props.showDescriptionForm) {
      return (
        <div className='field active-card__note'>
          <form className='active-card__edit-description' onSubmit={(e) => {e.preventDefault()} }>
            <div className='field'>
              <textarea 
                className='input'
                rows="4"
                onChange={(e) => this.setState({ updatedNote: `${e.target.value}` })}
                value={this.state.updatedNote} 
              />
            </div>
            <div className='options'>
              <button
                type='button'
                onClick={this.toggleDescriptionForm}
                className='button button-small'>cancel</button>
              { task.admin_generated === true && task.notes && task.notes.length > 0 && task.notes != task.description &&
              <button
                type='button'
                onClick={() => this.props.handleFieldUpdate('notes', null, true)}
                className='button button-small'>restore to default</button>
              }
              <button
                type='button'
                disabled={this.descriptionSubmitDisabled()}
                onClick={() => this.props.handleFieldUpdate('notes', this.state.updatedNote, true)}
                className='button button-primary button-small'>submit</button>
            </div>
          </form>
        </div>
      )
    } else {
      return (
        <div className='field active-card__note'>
          <div className='active-card__description'>
            {
              <>
                {this.taskContent(task)}
              </>
            }
          </div>
          { task.link_button_one_location || task.link_button_two_location
            ?    <div className='row-buttons row-buttons--centered mt-one-em mb-one-em'>
                  { this.taskButton(task, 'one') }
                  { this.taskButton(task, 'two') }
                </div>
            : null
          }

          {/* <div className='has-text-centered active-card__edit-text'>
            <span className='clickable acts-as-button button button-small mr-0' onClick={() => this.props.toggleForm('DescriptionForm', true)}>add personal note</span>
          </div> */}
        </div>
      )
    }
  }

  taskContent(task) {
    if (this.state.updatedNote) {
      return this.state.updatedNote;
    }else if (task.notes && task.notes.length > 0) {
      return task.notes;
    } else if (task.description && task.description.length > 0) {
      return task.description
    } else {
      // return 'There is no description for this task yet.'
      return;
    }
  }

  taskTips = () => {
    let { task } = this.props;
    if (task.tips.length > 0) {
      return (
        <span
          className='active-task__tip clickable'
          onClick={() => this.toggleTipOrOffer('tip', task.tips[0])}>
          <span className='circle'>GB</span>
          <span className='has-text-weight-bold'>Tip</span>
        </span>
      )
    }
  }

  toggleTipOrOffer = (typeOf, item) => {
    if (this.props.user.has_active_subscription) {
      this.setState({ [typeOf]: item })
    } else {
      this.setState({ modalIsOpen: true })
    }
  }

  specialOffers = () => {
    let { task } = this.props;
    if (task.special_offers.length > 0) {
      return (
          <span
            onClick={() => this.toggleTipOrOffer('specialOffer', task.special_offers[0])}
            className='active-task__offer clickable'>
              Special Offer
          </span>
      )
    }
  }

  progressPrompt = () => {
    if (this.props.showProgressPrompt) {
      return (
        <div className='active-task__progress-prompt'>
          <h5 className='is-5 subtitle no-margin'>Do you want to add a new due date to this task?</h5>
          <div className='row-buttons'>
            <button onClick={() => this.props.progressPromptResponse(true)} className='button button-small'>Yes</button>
            <button onClick={() => this.props.progressPromptResponse(false)}className='button button-small'>No</button>
        </div>
      </div>
      )
    } else if (this.props.showProgressWarning) {
      return (
        <div className='active-task__progress-prompt'>
          <p>This task will remain overdue in your task list until it is marked as complete.</p>
        </div>
      )
    }
  }

  taskType = (task) => {
    if (task.vendor_payment_id) {
      return 'Payment Reminder'
    } else if (!task.admin_generated) {
      return 'My Custom Task'
    } else if (task.admin_generated) {
      return 'Guided Bride Task'
    }
  }

  render() {
    let { task, taskStatuses, showDateForm } = this.props;
    let { tip, specialOffer } = this.state;
    if (tip) {
      return (
        <div className='active-card'>
          <i className="icon icon-arrow-left is-pulled-left clickable"
             onClick={() => this.setState({ tip: null })}></i>
           <h4 className='title is-4 has-text-centered'>{ tip.title }</h4>
           <div className='has-text-centered task-tip'>
             <p>{tip.description}</p>
           </div>
        </div>
      )
    } else if (specialOffer) {
      return (
        <div className='active-card'>
          <i className="icon icon-arrow-left is-pulled-left clickable"
             onClick={() => this.setState({ specialOffer: null })}></i>
          <h4 className='title is-4 has-text-centered'>{ specialOffer.title }</h4>
          <div className='has-text-centered special-offer'>
            <p className='special-offer__row'>{ specialOffer.description}</p>
            { specialOffer.link &&
              <a
                className='acts-as-button button button-primary special-offer__row'
                target='_blank'
                href={ specialOffer.link }>link</a>
            }
            { specialOffer.promo_code &&
              <p className='special-offer__row has-text-weight-bold'>Promo Code: { specialOffer.promo_code }</p>
            }
          </div>
          <p class="special-offer__row has-text">Special Offers are subject to change at any time without notice. Offer must be listed on-site at the time of use to be considered valid.</p>
        </div>
      )
    } else if (task && Object.keys(task).length > 0) {
      return (
        <div className='active-card'>
          <i className='sm-only icon icon-arrow-left' onClick={this.props.removeActiveTask}></i>
        { this.props.submitError === true &&
          <p className='error has-text-centered'>There was a problem updating this task.</p>
        }
          <h3 className='title is-4 has-text-centered'>{ task.title }</h3>
          <div className='has-text-centered'>
            { this.formattedDate(showDateForm, task) }
          </div>

          <div className='has-text-centered'>
            { this.statusChangeResponse() }
          </div>

          { this.taskDescription(task) }

          { this.progressPrompt() }

          <div className='active-task__status'>
          { this.statusFields() }
            <button
              disabled={this.props.task.status === 0}
              type='button'
              className='clickable button button-small'
              onClick={() => this.props.handleFieldUpdate('status', 0, true)}
            >
              reset status
            </button>
          </div>
          { this.notApplicable(task) }

        {(task.tips.length > 0 || task.special_offers.length > 0) &&
            <div className='active-task__goodies'>
              { this.taskTips() }
              { this.specialOffers() }
            </div>
        }

          { this.tags() }

          <h6 className={`title is-6 has-text-centered ${this.props.showTasksLink ? 'mb-10' : ''}`}>{this.taskType(task)}</h6>
          { this.props.showTasksLink && <h6 className='title is-6 has-text-centered has-link'><a href="/tasks" target="_self">Go to My Tasks List</a></h6>}
          <CheckoutModal
            token={this.props.token}
            user={this.props.user}
            clientKey={this.props.clientKey}
            stripePublishableKey={this.props.stripePublishableKey}
            modalIsOpen={this.state.modalIsOpen}
            closeModal={() => this.setState({ modalIsOpen: false })} />
        </div>
      )
    } else {
      return (
        <div className='active-card'>
          <h4 className='title is-4 has-text-centered'>No task selected.</h4>
        </div>
      )
    }

  }
}

ActiveTask.propTypes = {
  clientKey: PropTypes.string.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
  task: PropTypes.object,
  filterByTag: PropTypes.func,
  tagFilter: PropTypes.string,
  handleUpdateSubmit: PropTypes.func.isRequired,
  showTasksLink: PropTypes.bool,
  showDescriptionForm: PropTypes.bool.isRequired,
  showDateForm: PropTypes.bool.isRequired,
  toggleForm: PropTypes.func.isRequired,
  tagDisabled: PropTypes.bool,
  user: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired
}
