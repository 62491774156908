import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, useField } from 'formik';
import { FormTextInput, FormSelect, Loading } from '../../../../forms/Helpers';
import * as Yup from 'yup';

export default class WeddingPartyMemberForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  initialValues = () => {
    let { person } = this.props;
    if (person) {
      return {
        id: person.id,
        name: person.name,
        title: person.title,
        wedding_party_side: person.side
      }
    } else {
      return {
        name: '',
        title: '',
        wedding_party_side: '1'
      }
    }
  }

  headerText = () => {
    if (this.props.person && this.props.isFamily) {
      return 'Update This Family Member';
    } else if (this.props.person) {
      return 'Update This Wedding Party Member';
    } else if (this.props.isFamily) {
      return 'Add a New Family Member';
    } else {
      return 'Add a New Wedding Party Member';
    }
  }

  titleOptions = () => {
    let options = ['Best Man', 'Best Woman', 'Bridesmaid', 'Flowergirl', 'Groomsman', 'Groomswoman', 'Jr. Bridesmaid', 'Jr. Groomsman', 'Maid of Honor', 'Man of Honor' , 'Officiant', 'Ring bearer', 'Other'];
    if (this.props.isFamily === true) {
      options = ['Mother', 'Father', 'Stepmother', 'Stepfather', 'Grandmother', 'Grandfather', 'Sister', 'Brother', 'Godparent', 'Son', 'Daughter', 'Other']
    }
    return options.map((o) => {
      return <option value={o} key={o}>{o}</option>
    })
  }

  render() {
    return (
      <div className='acts-as-form six-hundred wedding-party__list'>
        <div>
          <h4 className='is-4 title mb-one-em'>{ this.headerText() }</h4>
        </div>
        <Formik
          initialValues={this.initialValues()}
          validationSchema={Yup.object({
            name: Yup.string()
              .required('Required'),
            title: Yup.string()
              .required('Required'),
            wedding_party_side: Yup.number()
            })
          }
          onSubmit={(values, { setSubmitting }) => {
              this.props.handleSubmit(values)
          }}
          render={({ values }) => (
            <Form>
              <div className='field'>
                <FormTextInput
                  label='Name'
                  name="name"
                  type="text"
                />
              </div>
              <div className='field'>
                <FormSelect label={this.props.isFamily ? 'Relationship' : 'Title'}
                   name='title'>
                  <option value=""></option>
                  { this.titleOptions() }
                </FormSelect>
              </div>
              <FormSelect label='Side?' name='wedding_party_side'>
                <option value="1">{this.props.user.first_name}</option>
                <option value="2">{this.props.user.partner_first_name}</option>
                { this.props.isFamily != true &&
                  <option value="3">None</option>
                }
              </FormSelect>

              <div className='mt-one-em grouped-buttons'>
                <button className='button button-primary' type="submit">
                  { this.props.person ? 'Update' : 'Submit' }
                </button>
                <button
                  type='button'
                  className='button'
                  type="submit"
                  onClick={this.props.closeForm}>cancel</button>
                { this.props.person &&
                  <button
                    onClick={this.props.deletePerson}
                    className='button button-delete'
                    type='button'>
                      delete
                  </button>
                }
              </div>

            </Form>
          )}
        >
        </Formik>
      </div>
    )
  }
}

WeddingPartyMemberForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  deletePerson: PropTypes.func.isRequired
}
