import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, Form, useField, FieldArray } from 'formik';
import * as Yup from 'yup';
import { FormTextInput, DatePickerField, Loading } from '../../../forms/Helpers';
import { FormResponse, handleResponse, errorsFormatter } from '../../../forms/Response';

export default class NewTaskForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  initialValues = () => {
    let { task } = this.props;
    if (task) {
      return {
        notes: task.notes,
        due_date: task.due_date,
        user_generated_title: task.user_generated_title
      }
    } else {
      return {
        notes: '',
        due_date: '',
        user_generated_title: ''
      }
    }
  }

  handleSubmit = (vals) => {
    this.setState({ isSubmitting: true }, () => {
      axios.post('/api/v1/users_checklist_items', {
        users_checklist_item: {
          user_id: this.props.user.id,
          notes: vals.notes,
          due_date: vals.due_date,
          user_generated_title: vals.user_generated_title,
        },
        authenticity_token: this.props.token
      })
      .then((response) => {
        this.props.addNewTask(response.data.item)
      })
      .catch((error) => {
        let message = errorsFormatter(error.response.data.errors) || 'There was a problem creating this item.'
        handleResponse(this, message, error.response.status, 4000)
      })
    })
  }

  form = () => {
    let { responseMessage, responseCode, isSubmitting } = this.state;
    return (
      <Formik
        initialValues={this.initialValues()}
        validationSchema={Yup.object({
          due_date: Yup.date()
                       .required('Required'),
          user_generated_title: Yup.string()
                                   .required('Required')
          })}
        onSubmit={(values, { setSubmitting }) => {
            this.handleSubmit(values)
        }}
        render={({ values }) => (
          <Form>
              { this.state.isSubmitting != true &&
                <div>
                  { FormResponse(responseMessage, responseCode) }
                  <div className='field'>
                    <FormTextInput
                      label='Title'
                      name="user_generated_title"
                      type="text"
                      placeholder='Task Title'
                    />
                  </div>

                  <div className='field'>
                    <FormTextInput
                      label='Description'
                      name="notes"
                      type="text"
                      placeholder='Description'
                    />
                  </div>

                  <div className='field'>
                    <DatePickerField
                      label='Due Date'
                      name='due_date'
                      type='date'
                    />
                  </div>

                  { !responseMessage &&
                    <div>
                      <button
                        className='button button-primary'
                        type="submit">Submit</button>
                      <button
                        type='button'
                        className='button'
                        onClick={this.props.toggleNewTaskForm}>cancel</button>
                    </div>
                  }


                </div>
              }
              { this.state.isSubmitting &&
                <Loading />
              }

          </Form>
        )}
      >
      </Formik>
    );
  };

  render() {
    return (
      <div className='active-card'>
        <h4 className='title is-4'>Add a New Task</h4>
        { this.form() }
      </div>
    )
  }
}

NewTaskForm.propTypes = {
  task: PropTypes.object,
  user: PropTypes.object.isRequired,
  addNewTask: PropTypes.func.isRequired
}
