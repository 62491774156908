import React from 'react';
import PropTypes from 'prop-types';
import Chat from '../chat/Chat';

export default class ActiveCustomer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const { customer, vendor} = this.props;
    if (!customer) {
      return (
        <div className='active-card'>
          <h4 className='title is-4 is-spaced has-text-centered'>Select a User</h4>
        </div>
      )
    } else {
      return (
        <div className='active-card'>
          <i className='icon icon-close is-pulled-right clickable'
            onClick={this.props.closeActiveCustomer}></i>
          <h4 className='title is-4 is-spaced has-text-centered mb-one-em'>{customer.first_name} {customer.last_name}</h4>
          <div className='vendor-user__chat'>
            <Chat user={customer} vendor={vendor} userType={'vendor'} />
          </div>
        </div>
      )
    }
  }
}

ActiveCustomer.propTypes = {
  customer: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]),
  vendor: PropTypes.object.isRequired,
  closeActiveCustomer: PropTypes.func.isRequired
}
