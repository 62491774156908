import React from 'react';
import PropTypes from 'prop-types';

export default class VendorContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  contacts = () => {
    let { vendor } = this.props;
    if (vendor.contacts && vendor.contacts.length > 0) {
      return vendor.contacts.map(c => {
        return this.contact(c);
      })
    } else if (vendor) {
      return (
        <div className='vendors__contact'>
          <h5 className='is-5 subtitle mb-one-em'>No contacts added yet.</h5>
        </div>
      )
    }
  }

  contact(person) {
    return (
      <div className='vendor-contact' key={person.id}>
        <label>Contact</label>
        <h5 className='is-5 subtitle'>{person.first_name} {person.last_name}</h5>
        <label>Phone</label>
        <h5 className='is-5 subtitle'>{person.phone_number}</h5>
        <label>Email</label>
        <h5 className='is-5 subtitle'>{person.email}</h5>
      </div>
    )
  }

  render() {
    return (this.contacts())
  }
}

VendorContacts.propTypes = {

}
