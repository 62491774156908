import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Loading } from '../../../forms/Helpers';
import ListForm from './ListForm';
import ActiveList from './ActiveList';
import AdminListToCopy from './AdminListToCopy';
import CheckoutModal from '../../../adyen/CheckoutModal';
import Modal from 'react-modal';

const TABS = {
  my: 'my',
  all: 'all',
  pack: 'pack'
}
export default class ListsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newListName: '',
      lists: this.props.lists || [],
      modalIsOpen: false,
      activeTab: TABS['my'],
      openLists: [],
      addedAdminTaskConfirmationModal: false,
      width: window.innerWidth,
    }
  }

  componentDidMount() {
    if (typeof(window) !== 'undefined') {
      Modal.setAppElement('body')
    }
    window.addEventListener('resize', this.updateWidth);
  }

  updateWidth = () => {
    this.setState({ width: window.innerWidth })
  }


  allLists = () => {
    let { lists, activeTab } = this.state;
    if (activeTab === TABS['my'] || activeTab === TABS['pack']) {
      if (lists.length > 0) {
        return lists.sort((a, b) => (a.name > b.name) - (a.name < b.name)).map((l) => {
          return this.listCard(l);
        })
      } else {
        return (<p className='mt-one-em'>Get started by creating your own list or adding one from the 'Templates' above.</p>)
      }
    } else if (activeTab === TABS['all']) {
      return this.adminLists();
    }
  }

  listCard = (list) => {
    let { activeList } = this.state;
    const copyList = Object.assign({}, list);
    copyList.items = list.items.filter((i)=> i.isDeleted !== true)
    return (
      <div
        key={list.id}
        onClick={() => this.setState({ activeList: list, showNewListForm: false, adminListToCopy: null })}
        className={'lists__card lists__card--no-m-l ' + (activeList && activeList.id === copyList.id ? 'lists__card--active' : '')}>
        <p className='has-text-weight-bold'>{copyList.name}</p>
        <span className='lists__item-total'>{ this.itemTotal(copyList) }</span>
      </div>
    )
  }

  adminLists = () => {
    return this.props.adminLists.map((l, index) => {
      return this.adminListCard(l, index);
    })
  }

  promptPayment = () => {
    this.setState({
      modalIsOpen: true,
      paywallText: "With a Guided Bride subscription, you get access to a wide range of curated lists to help you prepare for the big day",
      paywallHeader: "Get access to Guided Bride's lists"
    })
  }

  toggleAdminList = (listName) => {
    let openLists = [...this.state.openLists];
    if (openLists.includes(listName)) {
      openLists.splice(openLists.indexOf(listName), 1);
    } else {
      openLists.push(listName);
    }
    this.setState({ openLists });
  }

  adminListCard = (listItem, index) => {
    return (
      <div key={index}>
        <div className='lists__header'>
          <h5
            className='is-5 subtitle mt-one-em has-font-weight-bold'
            onClick={() => this.toggleAdminList(listItem.name) }>{ listItem.name }</h5>
          <i
            className={'icon icon-' + (this.state.openLists.includes(listItem.name) === true ? 'up' : 'down' )}
            onClick={() => this.toggleAdminList(listItem.name) }></i>
        </div>

        { this.state.openLists.includes(listItem.name) &&
          listItem.lists.sort((a, b) => (a.name > b.name) - (a.name < b.name)).map((l, index) => {
          return (
            <div key={index} className='lists__card lists__card--no-m-l'
              onClick={listItem.has_access ? () => this.setState({ adminListToCopy: l, activeList: null, showNewListForm: false }) : this.promptPayment }>
              <span>{ l.name }</span>
            </div>
          )
        })}

      </div>
    )
  }

  itemTotal = (list) => {
    if (!list) { return }
    let noun = 'items';
    let listCount = list.items.length;
    if (listCount === 1) {
      noun = 'item';
    }
    if (this.state.activeTab === TABS['pack']) {
      noun = 'items';
      listCount = list.items.filter(i => i.is_active != true).length;
      if (listCount === 1) {
        noun = 'item';
      }
    }
    return `${listCount} ${noun}`;
  }

  clearActiveList = () => {
    this.setState({ activeList: null })
  }

  toggleNewListForm = () => {
    if (this.props.user.has_active_subscription) {
      this.setState({ showNewListForm: true })
    } else {
      this.setState({
         modalIsOpen: true,
         paywallText: 'With a Guided Bride subscription you can keep track of all the things you need when planning for the big day.',
         paywallHeader: 'Keep track of the small things.'
        })
    }
  }

  closePaymentModal = () => {
    this.setState({ modalIsOpen: false, paywallText: null, paywallHeader: null })
  }

  addNewListButton = () => {
    return (
      <button
        className='button button-primary'
        style={{visibility: this.state.showNewListForm ? 'hidden' : 'visible'}}
        onClick={this.toggleNewListForm}>
        add new
      </button>
    )
  }

  handleUpdatedListItem = (listItem) => {
    let activeList = this.activeListCopy();
    let itemToUpdate = activeList.items.filter(l => l.id === listItem.id)[0];
    if (itemToUpdate) {
      activeList.items[activeList.items.indexOf(itemToUpdate)] = listItem;
      this.setState({ activeList })
    }
  }

  handleCopiedList = (newList) => {
    let lists = [...this.state.lists];
    lists.push(newList);
    this.setState({
      activeList: newList,
      activeTab: TABS['my'],
      lists,
      adminListToCopy: null,
      addedAdminTaskConfirmationModal: true
    })
  }

  listContent = () => {
    let { lists } = this.props;
    if (this.state.isSubmitting) {
      return <Loading />
    } else if (this.state.showNewListForm) {
      return (
        <ListForm
          token={this.props.token}
          addNewListToExisting={this.addNewListToExisting}
          toggleNewListForm={() => this.setState({ showNewListForm: !this.state.showNewListForm})} />
      )
    } else if (this.state.activeList) {
      return (
        <ActiveList
          activeTab={this.state.activeTab}
          list={this.state.activeList}
          handleUpdatedListItem={this.handleUpdatedListItem}
          token={this.props.token}
          removeListItem={this.removeListItem}
          restoreListItem={this.restoreListItem}
          removeList={this.removeList}
          updateList={this.updateList}
          clearActiveList={this.clearActiveList}
          addListItemToList={this.addListItemToList}
          addNewListToExisting={this.addNewListToExisting} />
      )
    } else if (this.state.adminListToCopy) {
      return (
        <AdminListToCopy
          close={() => this.setState({ adminListToCopy: null })}
          handleCopiedList={this.handleCopiedList}
          list={this.state.adminListToCopy}
          user={this.props.user}
          token={this.props.token} />
      )
    } else if (lists.length > 0) {
      return (
        <div className='active-card__detail has-text-centered'>
          <h4 className='title is-4 has-text-center'>{this.headingRightPanel()}</h4>
          <h4 className='title is-4 has-text-center'>Select a list to see items</h4>
        </div>
      )
    } else {
      return (
        <div className='active-card__detail has-text-centered'>
          <h4 className='title is-4 has-text-center'>{this.headingRightPanel()}</h4>
          <p className='title is-4 has-text-center title-regular'>Select or create a list to get started</p>
        </div>
      )
    }
  }

  headingRightPanel = () => {
    switch (this.state.activeTab) {
      case 'my':
        return 'My Lists';
      case 'pack':
        return 'Pack Lists';
      case 'all':
        return 'Templates';
      default:
        break;
    }
  }

  activeListCopy = () => {
    return Object.assign({}, this.state.activeList);
  }

  addListItemToList = (listItem) => {
    let updatedList = this.activeListCopy();
    updatedList.items.push(listItem);
    this.setState({ activeList: updatedList })
  }

  removeListItem = (listItem) => {
    const updatedList = this.activeListCopy();
    const itemToRemove = updatedList.items.filter((i) => i.id === listItem.id)[0];
    if (itemToRemove) {
      updatedList.items.find(el => el.id === itemToRemove.id).isDeleted = true;
      this.setState({ updatedList });
    }
  }

  restoreListItem = (ArrayOfListItems) => {
    const updatedList = this.activeListCopy();
    ArrayOfListItems.forEach(listItem => {
      const itemToRestore = updatedList.items.filter((i) => i.id === listItem.id)[0];
      if (itemToRestore) {
        updatedList.items.find(el => el.id === itemToRestore.id).isDeleted = null;
      }
    });
    this.setState({ updatedList })
  }

  listToChange = (list) => {
    let lists = [...this.state.lists];
    return lists.filter(l => l.id === list.id)[0];
  }

  removeList = (list) => {
    let lists = [...this.state.lists];
    let listToRemove = this.listToChange(list);
    if (listToRemove) {
      lists.splice(lists.indexOf(listToRemove), 1);
      this.setState({ lists, activeList: null })
    }
  }

  updateList = (list) => {
    let lists = [...this.state.lists];
    let listToUpdate = this.listToChange(list);
    if (listToUpdate) {
      lists[lists.indexOf(listToUpdate)] = list;
      this.setState({ lists, activeList: list })
    }
  }

  addNewListToExisting = (list) => {
    let lists = [...this.state.lists];
    lists.push(list);
    this.setState({ lists, activeList: list, showNewListForm: false, activeTab: TABS['my'] })
  }

  dashboardRightClass = () => {
    if (this.state.isSubmitting || this.state.showNewListForm || this.state.activeList || this.state.adminListToCopy) {
      return 'dashboard__right--full';
    } else { return 'dashboard__right--collapsed'; }
  }

  dashboardLeftClass = () => {
    if (this.state.isSubmitting || this.state.showNewListForm || this.state.activeList || this.state.adminListToCopy) {
      return 'dashboard__left--collapsed';
    } else { return 'dashboard__left--full'; }
  }

  render() {
    const confirmationModalStyle = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '1rem 2rem',
        background: 'white',
        border: 'solid 3px',
        borderRadius: 0,
        width: `${ this.state.width > 992 ? '50%' : this.state.width > 776 ? '80%' : '90%'}`,
        fontWeight: 'bold',
      }
    }
    const confirmationModalClose = () => this.setState({ addedAdminTaskConfirmationModal: false });
    return (
      <div className='standard-padding dashboard__inner lists'>
        <div className={'dashboard__left ' + (this.dashboardLeftClass() )}>
          <div className='dashboard__container'>
            <div className='header-with-button'>
              <div className='header-with-swatch header-with-swatch--left-justified'>
                <h2 className='title dashboard__header'>Lists</h2>
              </div>
              { this.addNewListButton() }

              { !this.props.user.has_active_subscription &&
                <CheckoutModal
                  user={this.props.user}
                  token={this.props.token}
                  clientKey={this.props.clientKey}
                  stripePublishableKey={this.props.stripePublishableKey}
                  modalIsOpen={this.state.modalIsOpen}
                  closeModal={this.closePaymentModal}
                  paywallText={this.state.paywallText}
                  paywallHeader={this.state.paywallHeader}
                  />
              }

              { this.state.addedAdminTaskConfirmationModal &&
                <Modal
                  isOpen={this.state.addedAdminTaskConfirmationModal}
                  onRequestClose={confirmationModalClose}
                  style={confirmationModalStyle}>
                  <div className='dashboard-modal'>
                    <p className='has-text-centered'>
                      Your list has been added
                    </p>
                    <div className='has-text-centered'>
                      <button
                        onClick={confirmationModalClose}
                        className="button button-secondary button-full-on-sm mt-one-em"
                      >Okay</button>
                    </div>
                  </div>
                </Modal>              
              }
            </div>

            <div className='lists__all'>
              <div className='lists__tab'>
                <span
                  className={this.state.activeTab === TABS['my'] ? 'active' : ''}
                  onClick={() => this.setState({
                    activeTab: TABS['my'],
                    activeList: null,
                    showNewListForm: false,
                    adminListToCopy: null
                  })}
                >
                    My Lists
                </span>

                <span
                  className={this.state.activeTab === TABS['pack'] ? 'active' : ''}
                  onClick={() => this.setState({
                    activeTab: TABS['pack'],
                    activeList: null,
                    showNewListForm: false,
                    adminListToCopy: null
                  })}
                >
                  Pack Lists
                </span>

                <span
                  className={this.state.activeTab === TABS['all'] ? 'active' : ''}
                  onClick={() => this.setState({
                    activeTab: TABS['all'],
                    activeList: null,
                    showNewListForm: false,
                    adminListToCopy: null
                  })}
                >
                  Templates
                </span>
              </div>
              { this.state.activeTab === TABS['pack'] && this.state.lists.length > 0 &&
                <div className='lists__print'>
                  <a href='/pack-lists.pdf' target='_blank'>
                    <i className='icon icon-printer'></i>
                    <span>Print all Pack Lists</span>
                  </a>
                </div>
              }
              { this.allLists()}
            </div>
          </div>
        </div>

        <div className={'dashboard__right ' + (this.dashboardRightClass())}>
          <div className='dashboard__container tasks-down'>
            <div className='active-card'>
              { this.listContent() }
            </div>
          </div>
        </div>

      </div>
    )
  }
}

ListsContainer.propTypes = {
  lists: PropTypes.array.isRequired,
  clientKey: PropTypes.string.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
}
