import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import IndividualQuestion from './IndividualQuestion';
import { Loading } from '../../../../forms/Helpers';
import { FormResponse, handleResponse } from '../../../../forms/Response';


const DIRECTIONS = {
  next: 'next',
  previous: 'previous'
}
export default class PhotoHoursContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuestion: this.props.content[0],
      values: this.initializeValues(),
      isFinished: false,
      result: this.props.answerTotal
    }
  }

  initializeValues = () => {
    let { content, answers } = this.props;
    let values = {}

    if (answers) {
      values = answers;
    } else {
      values = this.setEmptyValues();
    }
    return values;
  }

  setEmptyValues = () => {
    let values = {}
    this.props.content.map((k) => {
      k.options.response_type === 'multiple' ? values[k.field] = [] : values[k.field] = '';
    })
    return values;
  }

  changeQuestion = (direction) => {
    let { currentQuestion } = this.state;
    let { content } = this.props;
    let currentIndex = content.indexOf(currentQuestion);
    if (direction === DIRECTIONS.previous && this.hasPrevious()) {
      let showPrevious = this.meetsConditions(this.props.content[currentIndex - 1]);
      let previous = showPrevious ? this.props.content[currentIndex - 1] : this.props.content[currentIndex - 2];
      this.setState({ currentQuestion: previous })
    } else if (direction === DIRECTIONS.next && this.hasNext()) {
      let showNext = this.meetsConditions(this.props.content[currentIndex + 1]);
      let next = showNext ? this.props.content[currentIndex + 1] : this.props.content[currentIndex + 2];
      this.setState({ currentQuestion: next })
    }
  }

  meetsConditions = (question) => {
    if (!question.options.conditional) { return true }
    else {
      let showQuestion = true;
      let conditions = question.options.conditions;
      for (let i=0; i < conditions.length; i++) {
        if (this.state.values[Object.keys(conditions[i])[0]] != Object.values(conditions[i])[0]) {
          showQuestion = false;
          break;
        }
      }
      return showQuestion;
    }
  }

  hasPrevious = () => {
    let { currentQuestion } = this.state;
    let { content } = this.props;
    return currentQuestion != content[0];
  }

  hasNext = () => {
    let { currentQuestion } = this.state;
    let { content } = this.props;
    return currentQuestion != content[content.length - 1]
  }

  handleInputResponse = (question, response, automaticallyMoveToNext = null) => {
    let values = Object.assign({}, this.state.values);
    values[`${question.field}`] = response;
    // let totalMinutes = Object.values(values).reduce((a, b) => a + b);
    this.setState({ values }, () => {
      if (automaticallyMoveToNext) { this.changeQuestion('next'); }
    })
  }

  deleteInputResponse = (question, response) => {
    let values = Object.assign({}, this.state.values);
    delete values[`${question.field}`];
    this.setState({ values })
  }

  handleMultipleInputResponse = (question, response) => {
    let values = Object.assign({}, this.state.values);
    if (values[`${question.field}`].includes(response)) {
      values[`${question.field}`].splice(values[`${question.field}`].indexOf(response), 1);
    } else {
      if (question.field === 'special_events' && response === 'No') {
        values[`${question.field}`] = [response];
        this.setState({ values })
        return;
      }
      if (question.field === 'special_events' && values.special_events.includes('No')) {
        values['special_events'].splice(values.special_events.indexOf('No'), 1);
      }
      values[`${question.field}`].push(response);
    }
    this.setState({ values })
  }

  runningTotal = () => {
    return <h3 className='title is-3'>Total minutes: { this.state.totalMinutes }</h3>
  }

  submitForm = () => {
    this.setState({ isSubmitting: true }, () => {
      let url = '/questionnaires/photo_hours';
      let method = 'POST';
      if (this.state.values.id) {
        url = `/questionnaires/photo_hours/${this.state.values.id}`;
        method = 'PATCH';
      }
      axios({
        method: method,
        url: url,
        data: {
          photo_hours: this.state.values,
          authenticity_token: this.props.token
        }
      })
      .then((response) => {
        let values = Object.assign({}, this.state.values);
        values['id'] = response.data.photo_hour.id;
        this.setState({ values, result: response.data.result, isSubmitting: false, editResults: false, viewResults: false, currentQuestion: this.props.content[0] });
      })
      .catch((error) => {
        let errorResponse = error.response;
        let message = `There was a problem saving your photo hours response. ${errorResponse.data.message}`;
        handleResponse(this, message, errorResponse.status, 4000);
      })
    })
  }

  deletePhotoHours = () => {
    if (confirm('Are you sure you want to delete your responses? This cannot be undone.')) {
      this.setState({ isSubmitting: true }, () => {
        axios.delete(`/questionnaires/photo_hours/${this.state.values.id}`, {
          headers: {
            'X-CSRF-Token': this.props.token,
            'Content-Type': 'application/json'
          }
        })
        .then((response) => {
          let values = this.setEmptyValues()
          this.setState({ isSubmitting: false, result: null, currentQuestion: this.props.content[0], values, viewResults: false, editResults: false });
        })
        .catch((error) => {
          let errorResponse = error.response;
          let message = `There was a problem deleting your photo hours information. ${errorResponse.data.message}`;
          handleResponse(this, message, errorResponse.status, 4000);
        })
      })
    }
  }

  currentQuestionNumber = () => {
    let current = this.props.content.indexOf(this.state.currentQuestion);
    return `${current + 1} / ${this.props.content.length}`;
  }

  toggleEditResponses = () => {
    this.setState({ editResults: !this.state.editResults })
  }

  toggleViewResponses = () => {
    this.setState({ viewResults: !this.state.viewResults })
  }

  hourTotal = () => {
    return  (
      <div className='photo-hours__question quiz__question photo-hours-center'>
        <h4 className='title is-4'>Based on your responses, you'll need a photographer for { this.state.result } hours.</h4>
        <div className="grouped-buttons">
          <button className='button button-primary' onClick={this.toggleViewResponses}>view responses</button>
          <button className='button button-primary' onClick={this.toggleEditResponses}>edit responses</button>
          <button className='button button-cancel' onClick={this.deletePhotoHours}>delete responses</button>
        </div>
      </div>
    )
  }

  allQuestions = () => {
    let { content } = this.props;
    
    return content.map((c) => {
      if (this.meetsConditions(c)) {
        return (
          <IndividualQuestion
            key={c.field}
            values={this.state.values}
            hasPrevious={false}
            hasNext={false}
            question={c}
            showAll={true}
            user={this.props.user}
            handleInputResponse={this.handleInputResponse}
            handleMultipleInputResponse={this.handleMultipleInputResponse}
            deleteInputResponse={this.deleteInputResponse}
            submitForm={this.submitForm}
            changeQuestion={null}
            />
        )
      }
    })
  }

  viewResponses = () => {
    let { content } = this.props;
  
    return content.map((c) => {
      if (this.meetsConditions(c)) {
        return (
          <IndividualQuestion
            key={c.field}
            values={this.state.values}
            hasPrevious={false}
            hasNext={false}
            question={c}
            showAll={true}
            user={this.props.user}
            handleInputResponse={this.handleInputResponse}
            handleMultipleInputResponse={this.handleMultipleInputResponse}
            deleteInputResponse={this.deleteInputResponse}
            submitForm={this.submitForm}
            changeQuestion={null}
            isView={true}
            />
        )
      }
    })
  }

  render() {
    if (this.state.result && !this.state.editResults && !this.state.viewResults) {
      return (
        <div className='dashboard__container photo-hours-center'>
          <div className='wedding-party__list photo-hours-center'>
            <a className='link-back' href='/questionnaires'>Back to Wedding Details</a>
            { this.hourTotal()}
          </div>
        </div>
      )
    } else if (this.state.result && this.state.editResults) {
      return (
        <div className='photo-hours photo-hours__full-form'>
          <a className='link-back' href='/questionnaires'>Back to Wedding Details</a>
          <div className='acts-as-form six-hundred photo-hours__text-left'>
            <i style={{float: 'right', cursor: 'pointer'}}
              className='icon icon-close'
              onClick={() => this.setState({ viewResults: false, editResults: false })} 
            />
            { this.allQuestions() }
          </div>

          { FormResponse(this.state.responseMessage, this.state.responseCode) }

          <div className='photo-hours__edit'>
            <button
              className='button button-primary button-half-width'
              type='button'
              onClick={this.submitForm}>submit</button>
            <button className='button cancel-button button-half-width' onClick={this.toggleEditResponses}>cancel</button>
          </div>
        </div>
      )
    } else if (this.state.result && this.state.viewResults) {
      return (
        <div className='photo-hours photo-hours__full-form'>
          <a className='link-back' href='/questionnaires'>Back to Wedding Details</a>
          <div className='acts-as-form six-hundred photo-hours__text-left'>
            <i style={{float: 'right', cursor: 'pointer'}}
              className='icon icon-close'
              onClick={() => this.setState({ viewResults: false, editResults: false })} 
            />
            { this.viewResponses() }
          </div>
          
          <div className='photo-hours__edit'>
            <button className='button cancel-button button-half-width' onClick={this.toggleViewResponses}>back</button>
          </div>
        </div>
      )
    } else if (this.state.isSubmitting) {
      return (
        <div className='df-fdc-aic'>
          <h3 className='title is-3 has-text-centered'>
            Calculating your results.
          </h3>
          <Loading />
        </div>
      )
    } else if (this.state.formOpen){
      return (
        <div className='photo-hours dashboard__container'>
          <a className='link-back' href='/questionnaires'>Back to Wedding Details</a>

          { FormResponse(this.state.responseMessage, this.state.responseCode) }

          <div className='acts-as-form six-hundred quiz__container'>
            <IndividualQuestion
              values={this.state.values}
              hasPrevious={this.hasPrevious()}
              hasNext={this.hasNext()}
              question={this.state.currentQuestion}
              questionNumber={this.currentQuestionNumber()}
              showAll={false}
              user={this.props.user}
              handleInputResponse={this.handleInputResponse}
              handleMultipleInputResponse={this.handleMultipleInputResponse}
              deleteInputResponse={this.deleteInputResponse}
              submitForm={this.submitForm}
              changeQuestion={this.changeQuestion} />
          </div>
        </div>
      )
    } else {
      return (
        <div className='dashboard__container'>
          <div className='wedding-party__list'>
            <a className='link-back' href='/questionnaires'>Back to Wedding Details</a>
            <h5
              className='title is-5 weight-normal'
              style={{marginBottom:10}}
            >How many hours do you need to book your photographer for?</h5>
            <h6
              className='title is-6 weight-normal'
              style={{fontSize:'1.15rem'}}
            >Take our quiz to find out!</h6>
            <button
              onClick={() => this.setState({ formOpen: true })}
              className='button button-primary'>Start the quiz</button>
          </div>
        </div>
      )
    }
  }
}

PhotoHoursContainer.propTypes = {
  token: PropTypes.string.isRequired,
  content: PropTypes.array.isRequired
}
