import React from 'react';
import PropTypes from 'prop-types';
import { PieChart } from 'react-minimal-pie-chart';
import { formatMoney } from '../../HelperFunctions';

const BUDGET_COLORS = [
  '#c97b84',
  '#CBA58E',
  '#79A9D1',
  '#3E6291',
  '#588b8b',
  '#9bbfbf',
  '#742f37',
  '#E7C5C9',
  '#d5ae27' // '#fcd757'
]

const BUDGET_DATA = [
  {title: 'Ceremony & Reception Site Fee', value: 12.5, color: BUDGET_COLORS[0]},
  {title: 'Music', value: 5, color: BUDGET_COLORS[1]},
  {title: 'Beauty', value: 1.5, color: BUDGET_COLORS[2]},
  {title: 'Cake/Desert', value: 2, color: BUDGET_COLORS[3]},
  {title: 'Food & Beverage', value: 50, color: BUDGET_COLORS[4]},
  {title: 'Flowers', value: 10, color: BUDGET_COLORS[5]},
  {title: 'Photo/Video', value: 10, color: BUDGET_COLORS[6]},
  {title: 'Stationary', value: 2, color: BUDGET_COLORS[7]},
  {title: 'Rentals', value: 10, color: BUDGET_COLORS[8]}
]

export default class SuggestedBudget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  budgetAmount = (categoryValue) => {
    const USER_BUDGET = parseFloat(this.props.user.budget);
    return formatMoney(USER_BUDGET * (categoryValue / 100));
  }

  legend = (content) => {
    return BUDGET_DATA.map((d) => {
      return (
        <div
          key={d.title}
          className='budget-chart__legend-item'
        >
          { content === 'title' &&
            <div>
              <span className='swatch' style={{background: d.color}}></span>
              <div className='legend-key'>{d.title}</div>
            </div>
          }
          {content === 'amount' && <div className='text-budget'>{ ' ' + this.budgetAmount(d.value)}</div>}
        </div>
      )
    })
  }

  activeSegment = (item) => {
    if (item) {
      return (
        <h4
          style={{color: item.color}}
          className='is-4 subtitle'>{item.title} - {this.budgetAmount(item.value)}</h4>
      )
    } else { return <h4 className='is-4 subtitle'>Hover for details.</h4>}
  }

  render() {
    return (
      <div className='standard-padding suggested-budget'>
        <div className='white-tile'>
          <div className='header-with-button'>
            <div className='header-with-swatch header-with-swatch--left-justified'>
              <h2 className='title dashboard__header'>Suggested Budget</h2>
            </div>
          </div>

          <div className="tile is-ancestor" style={{marginTop: '0px', boxShadow: 'none'}}>
              <div className="tile is-parent">
                <article className="center-budget">
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                      <h6 className='mb-one-em'>Budget Breakdown</h6>
                      <div>{formatMoney(this.props.user.budget)}</div>
                    </div>
                    <div className='budget-breakdown'>
                      <div className='budget-breakdown__title'>{ this.legend('title') }</div>
                      <div className='budget-breakdown__amount'>{ this.legend('amount') }</div>
                    </div>
                  </div>
                </article>
              </div>

              <div className="tile is-parent is-8">
                <article className="tile is-child">
                  <div className='budget-chart__chart'>
                    <PieChart
                      data={BUDGET_DATA}
                      onMouseOut={() => this.setState({ activeSegment: null })}
                      onMouseOver={(e, segmentIndex) => {this.setState({ activeSegment: BUDGET_DATA[segmentIndex] }) } }
                   />
                  </div>
                  <div className='budget-chart__clicked'>
                    { this.activeSegment(this.state.activeSegment)}
                  </div>

                </article>
              </div>
            </div>
        </div>
      </div>
    )
  }
}

SuggestedBudget.propTypes = {

}
