import React from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import Modal from 'react-modal';
import { modalStyle } from './ModalStyle';
import BudgetOverview from './overview/BudgetOverview';
import SuggestedBudget from './SuggestedBudget';
import NonContractPaymentForm from './payments/NonContractPaymentForm';
import { formatMoney } from '../../HelperFunctions';
import Checkout from '../../adyen/Checkout'
import CheckoutModal from '../../adyen/CheckoutModal';

export default class Budget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      width: window.innerWidth,
      promoCode: '',
      showPayment: false,
      promoCodeChecked: false,
      dollarsPrice: null,
      discount: 0,
    }
  }

  componentDidMount() {
    if (typeof(window) !== 'undefined') {
      Modal.setAppElement('body')
    }

    if (window.location.href.includes('payment_error=')) {
      let error = window.location.href.split('payment_error=')[1];
      this.setState({ redirectPaymentError: decodeURIComponent(error).replace(/\+/g, ' ') })
      setTimeout(() => this.setState({ redirectPaymentError: null }), 4000);
    }

    window.addEventListener('resize', this.updateWidth);

    this.priceCalculationAndVerificationWithServer();
  }

  updateWidth = () => {
    this.setState({ width: window.innerWidth })
  }

  toggleModalAndAddPayment = (payment) => {
    let user = Object.assign({}, this.props.user);
    if (this.state.item) {
      user.non_contract_payments[user.non_contract_payments.indexOf(this.state.item)] = payment;
    } else {
      user.non_contract_payments.push(payment);
    }
    this.setState({ user }, () => {
      this.toggleModal();
    })
  }

  deleteExpense = () => {
    let user = Object.assign({}, this.props.user);
    user.non_contract_payments.splice(user.non_contract_payments.indexOf(this.state.item), 1);
    this.setState({ user }, () => { this.toggleModal() })
  }

  modalContent = () => {
    if (this.state.modalContent === 'newExpense' && this.props.user.has_active_subscription != true && this.state.dollarsPrice !== null) {
      return (
        <CheckoutModal
          modalIsOpen={true}
          closeModal={this.toggleModal}
          user={this.props.user}
          token={this.props.token}
          clientKey={this.props.clientKey}
          stripePublishableKey={this.props.stripePublishableKey}
          redirectPaymentError={this.state.redirectPaymentError}
        />
      )
      return (
        <Checkout
          dollarsPrice={this.state.dollarsPrice}
          token={this.props.token}
          user={this.props.user}
          subscriptionPer={'month'}
          clientKey={this.props.clientKey}
          stripePublishableKey={this.props.stripePublishableKey}
          closeModal={this.toggleModal}
          paywallText={'Keep track of all the little things so you\'re always working with a realistic budget.'}
          paywallHeader={'Subscribe to track your non contract expenses.'} />
      )
    } else if (this.state.modalContent === 'newExpense') {
      return (
        <NonContractPaymentForm
          item={this.state.item}
          deleteExpense={this.deleteExpense}
          toggleModal={this.toggleModal}
          toggleModalAndAddPayment={this.toggleModalAndAddPayment}
          {...this.props} />
      )
    } else {
      let { vendors } = this.props;
      if (vendors && vendors.length > 0) {
       return (
         <div>
           <i style={{float: 'right', cursor: 'pointer'}} className='icon icon-close' onClick={this.toggleModal}></i>
           <h3 style={{clear:'both'}} className='title is-3'>Select a vendor below</h3>
           <div className='budget__vendor-options'>
             { vendors.map((v) => {
               const sectionName = v.is_venue ? 'venues' : 'vendors' // /vendors?id or /venues?id
               return (
                 <div key={v.id}>
                  <a href={`/${sectionName}?id=${v.id}&tab=contracts&action=create`}>{v.company_name}</a>
                </div>
             )
             })}
           </div>
         </div>
       )
      } else {
        return (
          <div>
            <h3 className='title is-3'>Add a Vendor to Get Started.</h3>
            <p>Create your first vendor to start adding contracts.</p>
            <a
              href='/vendors?action=new'
              className='acts-as-button button button-primary mt-one-em'>Add Vendor</a>
          </div>
        )
      }
    }
  }

  toggleModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen, modalContent: null, item: null })
  }

  columnTotals = () => {
    let contracts = this.props.user.contracts.filter(c => c.not_included_in_budget != true);
    let totalAmount = 0;
    let amountPaid = 0;
    contracts.map((c) => {
      totalAmount += parseInt(c.total_price);
      c.vendor_payments.map((vp) => {
        if (vp.status === 'fully_paid') {
          amountPaid += parseInt(vp.total_due);
        }
      })
    })
    let openBalance = totalAmount - amountPaid;

    return (
      <tr>
        <td className='has-text-weight-bold'>
          TOTAL
        </td>
        <td></td>
        <td>{ formatMoney(totalAmount) }</td>
        <td>{ formatMoney(amountPaid) }</td>
        <td>{ formatMoney(openBalance) }</td>
      </tr>
    )
  }

  contractsList = () => {
    let contracts = this.props.user.contracts.filter(c => c.not_included_in_budget != true);

    if (contracts.length > 0) {
      return (
        <table className='contract__table media-categories-disable'>
          <thead>
            <tr>
              <th>Vendor</th>
              <th>Categories</th>
              <th>Total Amount</th>
              <th>Amount Paid</th>
              <th>Open Balance</th>
            </tr>
          </thead>
          <tbody>
            { contracts.map((c) => {
                return this.tableRow(c)
              })
            }
            { this.columnTotals() }
          </tbody>
        </table>
      )
    } else {
      return (<p className='mt-one-em mb-one-em'>No contracts added yet.</p>)
    }
  }

  tableRow = (contract) => {

    let is_venue;

    const vendors_check = this.props.vendors.some(item => {
      return item.contracts.some(item => item.id === contract.id) 
              && item.company_name === contract.vendor_name 
              && item.is_venue === true
    });

    if(vendors_check) {
      is_venue = vendors_check;
    } else {
      is_venue = contract.is_venue
    }

    const sectionName = is_venue ? 'venues' : 'vendors' // /vendors?id or /venues?id
    console.log('contract', contract)
    const contractLink = `/${sectionName}?id=${contract.vendor_id}&tab=contracts`;
    return (
      <tr key={contract.id}>
        <td>
          <a href={contractLink}>{contract.vendor_name}</a>
        </td>
        <td>{ this.vendorTypes(contract)}</td>
        <td>{ formatMoney(contract.total_price) }</td>

        { this.paidAmountAndBalance(contract, contractLink) }
       </tr>
    )
  }

  vendorTypes(contract) {
    let types = contract.vendor_types.map(vt => vt.vendor_type)
    if (types.length === 1) {
      return types[0]
    }else if (types.length > 1) {
      return types.join(', ')
    } else {
      return 'None'
    }
  }

  paidAmountAndBalance(contract, link) {
    let paidItems = contract.vendor_payments.filter(vp => vp.status === 'fully_paid')
    let paidAmount = paidItems.reduce((a, b) => a + (b['total_due'] || 0), 0);
    let balance = parseFloat(contract.total_price) - parseFloat(paidAmount);
    return (
      <>
      <td>{ formatMoney(paidAmount) }</td>
      <td>{ formatMoney(balance) }</td>
      </>
    )
  }

  priceCalculationAndVerificationWithServer = () => {
    Axios.post('/subscriptions/price_calculation', {
      subscription_type: {
        promo_code: this.state.promoCode
      },
      user_id: this.props.user.id,
      authenticity_token: this.props.token
    })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            dollarsPrice: response.data.final_price,
            discount: response.data.discount,
            showPayment: true,
            promoCodeChecked: !!this.state.promoCode,
          })
        } else {
          this.setState({ showPayment: false })
        }
      })
      .catch(() => {
        this.setState({ showPayment: false })
      })
  }

  expenseAndAmountTable = (expenses) => {
    return (
      <table className='contract__table'>
        <thead>
            <tr>
              <th>Expense</th>
              <th>Amount</th>
            </tr>
        </thead>
        <tbody>
            { expenses.map((e) => {
                return this.expenseRow(e)
              })
            }
            <tr>
              <td className='has-text-weight-bold'>TOTAL</td>
              <td>{ formatMoney(expenses.reduce((a, b) => a + (b['amount'] || 0), 0)) }</td>
            </tr>
        </tbody>
      </table>
    )
  }

  expenseRow(e) {
    if (e.amount != null) {
      return (
        <tr key={e.id}>
          <td><a onClick={() => this.setState({ modalIsOpen: true, modalContent: 'newExpense', item: e })}>{e.name}</a></td>
          <td>{formatMoney(e.amount) }</td>
        </tr>
      )
    } else if (e.total_price) {
      let contractLink = `/vendors?id=${e.vendor_id}&tab=contracts&contract_id=${e.id}`;
      return (
        <tr key={e.id}>
          <td><a href={contractLink}>{e.vendor_name} Contract</a></td>
          <td><a href={contractLink}>{formatMoney(e.total_price) }</a></td>
        </tr>
      )
    }
  }

  otherExpenses = () => {
    let expenses = this.props.user.non_contract_payments.filter(p => p.not_included_in_budget != true && p.amount)
    if (expenses.length > 0) {
      return (this.expenseAndAmountTable(expenses))
    } else {
      return (<p className='mt-one-em mb-one-em'>No non-contract expenses added to budget yet.</p>)
    }
  }

  notIncludedInBudget = () => {
    let { user } = this.props;
    let expenses = user.non_contract_payments.filter(p => p.not_included_in_budget === true && p.amount);
    let contractNotIncludedInBudget = user.contracts.filter(c => c.not_included_in_budget === true );
    expenses.push(contractNotIncludedInBudget);
    let allExpenses = expenses.flat();
    if (allExpenses.length > 0) {
      return (this.expenseAndAmountTable(allExpenses))
    } else {
      return (<p className='mt-one-em mb-one-em'>No non-budget items or contracts added yet.</p>)
    }
  }


  budgetOverview = () => {
    return (
      <div className='columns'>
        <div className='column'>
          <BudgetOverview
            header={'Budget'}
            showButtons={false}
            token={this.props.token}
            user={this.props.user}
            vendorTypes={this.props.vendorTypes}/>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className='standard-padding'>
        <div className='white-tile'>
          <div className='header-with-button'>
            <div className='header-with-swatch header-with-swatch--left-justified'>
              <h2 className='title dashboard__header'>My Budget</h2>
            </div>
          </div>

          <div className='row-buttons budget__options'>
            <button
              className='button button-primary'
              onClick={() => this.setState({ modalIsOpen: true, modalContent: 'newContract'})}>
              Add Contract details
            </button>
            <button
              className='button button-secondary'
              onClick={() => this.setState({ modalIsOpen: true, modalContent: 'newExpense'})}>
              Add Non Contract Expense
            </button>
          </div>
          <div className="tile is-ancestor">
            <div className="tile is-vertical is-8">
              <div className="tile">
                <div className="tile is-parent is-vertical">
                  <article className="tile is-child">
                    <div className='budget-item__card budget-item__card--full'>
                      <h4 className='subtitle is-4 mb-quarter-em'>Contracts</h4>
                      { this.contractsList() }
                    </div>
                  </article>

                  <article className="tile is-child">
                    <div className='budget-item__card budget-item__card--full'>
                      <h4 className='subtitle is-4 mb-quarter-em'>Non-Contract Expenses</h4>
                      { this.otherExpenses() }
                    </div>
                  </article>

                  <article className="tile is-child">
                    <div className='budget-item__card budget-item__card--full'>
                      <h4 className='subtitle is-4 mb-quarter-em'>Expenses Not Included in Budget</h4>
                      { this.notIncludedInBudget() }
                    </div>
                  </article>

                </div>
              </div>
            </div>
            <div className="tile is-parent">
              <article className="tile is-child budget-item__card budget-item__card--full">
                { this.budgetOverview() }
              </article>
            </div>
          </div>

          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.toggleModal}
            style={modalStyle(this.state.width, '#F3F1EE')}>
            <div className='dashboard-modal'>
              { this.modalContent()}
            </div>
          </Modal>
        </div>
      </div>
    )
  }
}

Budget.propTypes = {
  vendors: PropTypes.array.isRequired
}
