import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, Form, useField, FieldArray } from 'formik';
import { handleResponse, FormResponse, errorsFormatter } from '../../../../../forms/Response';
import { FormTextInput, FormSelect, DatePickerField, Loading, FormCheckbox } from '../../../../../forms/Helpers';
import * as Yup from 'yup';
import EditForm from './EditForm';

const SECTIONS = {
  basicInfo: 'basicInfo',
  vendorPayment: 'vendorPayment',
  reviewAndSubmit: 'reviewAndSubmit',
  allSections: 'allSections'
}

const PAYMENT_METHODS = ['Credit Card', 'Check', 'Venmo', 'PayPal', 'Apple Pay', 'Other']

export default class ContractForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSection: this.props.contract && this.props.contract.id ? SECTIONS.allSections : SECTIONS.basicInfo,
    }
  }

  currentSection = (values) => {
    switch (this.state.currentSection) {
      case SECTIONS.allSections:
        return this.allSections(values);
      case SECTIONS.basicInfo:
        return this.basicInfo(values);
      case SECTIONS.vendorPayment:
        return this.vendorPayment(values);
      case SECTIONS.reviewAndSubmit:
        return this.reviewAndSubmit(values);
    }
  }

  allSections = (values) => {
    return (
      <div>
        <EditForm
          values={values}
          closeForm={this.props.closeForm}
          removeVendorPayment={this.removeVendorPaymentWithoutSettingState}
          installmentFieldArray={this.installmentFieldArray}
          vendorTypesFieldArray={this.vendorTypesFieldArray}
          paymentMethodsFieldArray={this.paymentMethodsFieldArray}
          vendorPaymentsFields={this.vendorPaymentsFields}
          setReddyToSubmit={this.setReddyToSubmit}
          completeBasicInfoDisabled={this.completeBasicInfoDisabled(values)}
        />
      </div>
    )
  }

  basicInfo = (values) => {
    return (
      <>
      <div className="spaced-group">
        <div className="field is-grouped half-child-media media-align-flex-end">
          <FormTextInput
            name="total_price"
            label='Total Contract Amount*'
            type="number"
            placeholder="$"
          />
          <FormTextInput
            name="number_of_hours_contracted"
            label='Number of Hours Contracted'
            type="number"
            placeholder=""
          />
        </div>
        </div>

        <div className='spaced-group mt-one-em'>
          { this.vendorTypesFieldArray(values) }
        </div>


        <div className="field">
          <FormTextInput
            label='Link to Contract'
            name="link_to_document"
            type="text"
            placeholder="Link to Contract"
            tooltip='If your vendor is using a digital contract service, you can add that link here.'
          />
        </div>

        <div className='contract-doc-auth'>
          <div className="field">
            <FormTextInput
              label='Login'
              name="login"
              type="text"
              placeholder="Login"
            />
          </div>

          <div className="field">
            <FormTextInput
              label='Password'
              name="password"
              type="text"
              placeholder="Password"
            />
          </div>
        </div>

        <div className="field">
            <FormTextInput
              label='Note to contract'
              name="note_to_contract"
              type="text"
              placeholder="Note to contract"
            />
          </div>

        <div className="field is-grouped">
          <FormTextInput
            name="deposit_amount"
            type="number"
            label='Deposit'
            placeholder="$"
          />
        </div>

        <div className='field'>
          { this.paymentMethodsFieldArray(values) }
        </div>

        <div className='field'>
          <FormCheckbox
            name='not_included_in_budget'
            type='checkbox'
            label='Do not include this contract in your budget'
            tooltip='Check this box if this is an expense you want to track, but the cost is outside of your listed budget (for example, if the cost is being covered by someone else)'
            />
        </div>

        <div className='row-buttons'>
          <button
            type='button'
            className='button button-cancel fifty'
            onClick={this.props.closeForm}>cancel</button>
          <button
            type='button'
            disabled={this.completeBasicInfoDisabled(values)}
            className='button button-primary fifty'
            onClick={() => this.afterBasicInfoAction(values)}>next</button>
        </div>
      </>
    )
  }

  completeBasicInfoDisabled = (values) => {
    let firstBreakdown = values.vendor_type_vendor_contracts[0];
    if (values.total_price === '' || values.total_price <= 0 ||
        this.vendorTypesTotalEqualsOverallTotal(values) === false) {
      return true
    } else {
      return false;
    }
  }

  afterBasicInfoAction = (values) => {
    let remaining = this.paymentsToTotalRemainder(values);
    if (values.vendor_payments.length > 1) {
      this.setState({ currentSection: SECTIONS.vendorPayment });
    } else if (parseFloat(remaining) > 0) {
      this.addPaymentFromTotal(values, remaining);
    } else {
      this.setState({ currentSection: SECTIONS.reviewAndSubmit });
    }
  }

  vendorPaymentNextButtonDisabled = (vendorPayments) => {
    const payments = [...vendorPayments];
    payments.splice(0,1);
    const arrResults = payments.map(payment => {
      if (payment.total_due !== '' && payment.due_date !== '') {
        return true;
      } else { return false }
    })
    return !arrResults.every(r => r)
  }

  vendorPayment = (values) => {
    const remaining = this.paymentsToTotalRemainder(values);
    const heading = `There is $${remaining} remaining.`
    return (
      <div>
        <h5 className='subtitle is-5'>{heading}</h5>
        <h6 className='subtitle is-6'>Add Payment</h6>
        {values.vendor_payments.map((payment,paymentIndex) => {
          if (payment.status === 'fully_paid') { return false }
          return (
            <React.Fragment key={paymentIndex}>
            <h6>Payment { paymentIndex }</h6>
            {this.installmentFieldArray(values, paymentIndex)}
            </React.Fragment>)})
          }
      {values.vendor_payments.length === 1 &&
        <button
          className='vendor-types__btn vendor-types__btn--add'
          type="button"
          onClick={() => this.addVendorPayment(values)}>
          <i className='icon icon-plus-circle'></i>
        </button>
      }
        <div className='row-buttons'>
          <button
            onClick={() => this.paymentBackButton()}
            type='button'
            className='button button-cancel fifty'>back</button>
          <button
            type='button'
            disabled={this.vendorPaymentNextButtonDisabled(values.vendor_payments)}
            className='button button-primary fifty'
            onClick={() => this.nextPayment(values)}>submit</button>
        </div>
      </div>
    )
  }

  removeVendorPaymentWithoutSettingState = (values, paymentId) => {
    let paymentToRemove = values.vendor_payments.filter(p => p.id === paymentId)[0];
    if (paymentToRemove) {
      values.vendor_payments.splice(values.vendor_payments.indexOf(paymentToRemove), 1);
    }
  }

  paymentsWithoutDeposit = (values) => {
    let payments = [...values.vendor_payments]
    payments.splice(0, 1);
    return payments;
  }

  paymentBackButton = () => {
    this.setState({ currentSection: SECTIONS.basicInfo });
  }

  addPaymentFromTotal = (values, suggestedTotal) => {
    values.vendor_payments.push(
      {
        total_due: suggestedTotal,
        due_date: '',
        days_before_to_send_reminder: '',
        status: 'unpaid'
      }
    )

    this.setState({ currentSection: SECTIONS.vendorPayment })
  }


  paymentsToTotalRemainder = (values) => {
    if (values.total_price === '' || values.vendor_payments.length === 0) { return null }
    let payments = values.deposit_amount === '' ? 0 : values.deposit_amount;
    values.vendor_payments.forEach((p) => {
      if (p.total_due != '') { payments += parseFloat(p.total_due)}
    });
    return Number(values.total_price - payments).toFixed(2);
  }

  nextPayment = (values) => {
    let paymentRemainder = this.paymentsToTotalRemainder(values);
    if (parseFloat(paymentRemainder) > 0) {
      alert('Total payments should be equal to Contract Total!')
    } else if (parseFloat(paymentRemainder) < 0) {
      alert('You have added too many payments!')
    }
      else {
      this.setState({ currentSection: SECTIONS.reviewAndSubmit })
    }
  }

  promptToShow = (values) => {
    if (this.state.currentSection === SECTIONS.basicInfo && values.vendor_payments.length > 1) {
      this.setState({ currentSection: SECTIONS.vendorPayment });
    }
  }

  formatDate(d) {
    let splitDate = d.split('-');
    return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
  }

  formatTotal(total) {
    if (total.toString().split('.')[1] === '00') {
      return total.toString().split('.')[0];
    } else {
      return total;
    }
  }

  vendorPaymentsOverview = (vendorPayments) => {
    let payments = [...vendorPayments];
    payments.splice(0,1);
    return payments.map((p, index) => {
      return (
        <div key={index}>
          { p.status === 'unpaid' &&
            <p key={index}>Payment { index + 1 }: ${this.formatTotal(p.total_due)} due on {this.formatDate(p.due_date)}</p>
          }

          { p.status != 'unpaid' &&
            <p key={index}>Payment { index + 1 }: ${this.formatTotal(p.total_due)} paid.</p>
          }
        </div>
      )
    })
  }

  reviewAndSubmit = (values) => {
    let remaining = this.paymentsToTotalRemainder(values);
    let paymentTotal = 0;
    values.vendor_payments.forEach(p => paymentTotal += parseFloat(p.total_due));
    return (
      <div>
        <h5 className='subtitle is-5'>Review and Submit</h5>
        { parseInt(remaining) < 0 &&
          <div className='review-and-submit__warning'>
            <p className='error'>The payment total you have entered is more than the contract amount. Please make sure that you have entered the information correctly before submitting.</p>
          </div>
        }

        <div className='review-and-submit'>
          <p>Contract Total: ${values.total_price}</p>
          <p>Deposit: ${values.deposit_amount ? values.deposit_amount : 0}</p>
          { values.vendor_payments.length > 1 &&
            this.vendorPaymentsOverview(values.vendor_payments)
          }
        </div>

        <div className='row-buttons'>
          <button className='button fifty' onClick={() => this.backFromReview(values)}>back</button>
          <button type="submit" className='button fifty button-primary' onClick={() => this.setReddyToSubmit(values)}>Submit</button>
        </div>
      </div>
    )
  }

  setReddyToSubmit = (values) => {
    const paymentRemainder = this.paymentsToTotalRemainder(values);
    if (parseFloat(paymentRemainder) > 0) {
      alert('Total payments should be equal to Contract Total!')
    } else if (parseFloat(paymentRemainder) < 0) {
      alert('You have added too many payments!')
    }
      else {
      this.setState({reddyToSubmit: true});
    }
  }

  backFromReview = (values) => {
    if (values.vendor_payments.length < 2) {
      this.setState({ currentSection: SECTIONS.basicInfo });
    } else {
      this.setState({ currentSection: SECTIONS.vendorPayment })
    }
  }

  addVendorPayment = (values) => {
    values['vendor_payments'].push(
      {total_due: this.paymentsToTotalRemainder(values), due_date: '', days_before_to_send_reminder: '', status: 'unpaid' }
    )
    this.forceUpdate()
  }

  removeVendorPayment = (values, paymentIndex) => {
    values.vendor_payments.splice(paymentIndex, 1);
    this.forceUpdate();
  }

  installmentFieldArray = (values, index) => {
    return (
      <FieldArray
        name='vendor_payments'
        render={arrayHelpers => (
          <div key={index} className='vendor-contract__add vendor-form__nested'>
            { this.vendorPaymentsFields(values, index) }
          {index + 1 === values.vendor_payments.length &&
            <button
              className='vendor-types__btn vendor-types__btn--add'
              type="button"
              onClick={() => this.addVendorPayment(values)}>
              <i className='icon icon-plus-circle'></i>
            </button>
          }
            <button
              className='vendor-types__btn vendor-types__btn--delete'
              type='button'
              onClick={() => this.removeVendorPayment(values, index)}>
              <i className='icon icon-minus-circle'></i>
            </button>
          </div>
        )}
      />
    )
  }

  paymentMethodsFieldArray = (values) => {
    return (
      <>
        <FieldArray
          name='payment_methods'
          render={arrayHelpers => (
            <div>
              { values.payment_methods.map((pm, index) => (
                <div key={index}>
                  <div className='field is-grouped media-align-flex-end'>
                    <FormSelect
                      style={{height: '3em', width: '100%'}}
                      label='Payment Method'
                      name={`payment_methods[${index}]['method']`}
                    >
                    <option></option>
                    { PAYMENT_METHODS.map(p => {
                      return <option key={p} value={p.toLocaleLowerCase().replace(' ', '_')}>{p}</option>
                    })}
                    </FormSelect>

                    <FormTextInput
                      name={`payment_methods[${index}]['note']`}
                      type='text'
                      label='Note'
                      placeholder='ex. @venmo_user'
                      />
                  </div>

                  { index + 1 === values.payment_methods.length &&
                    <button
                      className='vendor-types__btn vendor-types__btn--add'
                      type="button"
                      onClick={() => arrayHelpers.push({method: '', note: '' })}>
                      <i className='icon icon-plus-circle'></i>
                    </button>
                  }
                      <button
                        className='vendor-types__btn vendor-types__btn--delete'
                        type='button'
                        onClick={() => arrayHelpers.remove(index)}>
                        <i className='icon icon-minus-circle'></i>
                      </button>

                </div>
              ))
            }

            { values.payment_methods.length === 0 &&
              <>
                <div>
                  <label className='label'>Add Payment Methods</label>
                </div>
                <button
                  className='vendor-types__btn vendor-types__btn--add'
                  type="button"
                  onClick={() => arrayHelpers.push({method: '', note: '' })}>
                  <i className='icon icon-plus-circle'></i>
                </button>
              </>
            }
            </div>
          )}
          />
      </>
    )
  }

  vendorTypesFieldArray = (values) => {
      return (
        <>
          <label className='label'>Budget Categories*</label>
            { this.vendorTypesTotalEqualsOverallTotal(values) === false &&
              <p className='error'>Budget breakdown must equal contract total.</p>
            }
            <FieldArray
              name='vendor_type_vendor_contracts'
              render={arrayHelpers => (
                <div>
                  { values.vendor_type_vendor_contracts &&
                    values.vendor_type_vendor_contracts.length > 0 &&
                    values.vendor_type_vendor_contracts.map((vt, index) => (
                      <div key={index}>
                        <div  className='vendor-contract__add vendor-form__nested'>
                          { this.vendorTypesFields(index, values) }
                        </div>

                        { index + 1 === values.vendor_type_vendor_contracts.length &&

                          <button
                            className='vendor-types__btn vendor-types__btn--add'
                            type="button"
                            onClick={() => arrayHelpers.push({amount: '', vendor_type_id: '' })}>
                            <i className='icon icon-plus-circle'></i>
                          </button>
                        }
                        { index > 0 &&
                          <button
                            className='vendor-types__btn vendor-types__btn--delete'
                            type='button'
                            onClick={() => arrayHelpers.remove(index)}>
                            <i className='icon icon-minus-circle'></i>
                          </button>
                        }
                      </div>

                      ))
                  }
                  { !values.vendor_type_vendor_contracts || values.vendor_type_vendor_contracts.length === 0 &&
                    <button
                      className='vendor-types__btn vendor-types__btn--add'
                      type="button"
                      onClick={() => arrayHelpers.push({amount: '', vendor_type_id: '' })}>
                      <i className='icon icon-plus-circle'></i>
                    </button>
                  }

                </div>
                )}
              />
        </>
      )
  }

  addFirstVendorType = (values) => {
    values.vendor_type_vendor_contracts = [
         {
           amount: '',
           vendor_type_id: ''
         }
    ]
    this.setState({ firsVendorType: true })
  }

  paymentError = (values) => {
    let payments = 0;
    values.vendor_payments.forEach(p => payments += parseFloat(p.total_due));
    if (payments.toFixed(2) > values.total_price) {
      return (<p className='error'>Payment value is more than total due.</p>)
    }
  }

  vendorTypeOptions = (index, values) => {
    let currentItem = values.vendor_type_vendor_contracts[`${index}`];
    let alreadySelectedIds = values.vendor_type_vendor_contracts.filter(v => v.vendor_type_id != '').map(v => parseInt(v.vendor_type_id));
    if (currentItem) {
      let currentlySelected = currentItem['vendor_type_id']
      if (currentlySelected) { alreadySelectedIds.splice(alreadySelectedIds.indexOf(parseInt(currentlySelected)), 1) }
    }
    const { vendorTypes, vendorCategories } = this.props;
    return vendorCategories?.map(c => {
      if (vendorTypes.filter(v => alreadySelectedIds.includes(v.id) === false).map(t => t.vendor_category_id).includes(c[0])) {
        return (
          <optgroup key={c[0]} label={c[1]}>
            {
              vendorTypes?.map(vt => {
                if (c[0] === vt.vendor_category_id) {
                  return (
                    <option key={vt.id} value={vt.id}>{vt.name}</option>
                  )
                }
              })
            }
          </optgroup>
        )
      }
    })
  }

  vendorTypesTotalEqualsOverallTotal = (values) => {
    let valueTotal = values.total_price === '' ? 0 : values.total_price;
    return values.vendor_type_vendor_contracts.reduce((a, b) => a + (b['amount'] || 0), 0) === valueTotal;
  }

  vendorTypesFields = (index, values) => {
    return (
      <div>
        <div className='field is-grouped half-child-media'>
          <FormSelect
            style={{height: '3em', width: '100%'}}
            name={`vendor_type_vendor_contracts[${index}][vendor_type_id]`}
          >
          <option disabled>Category</option>
          <option></option>
          { this.vendorTypeOptions(index, values) }
        </FormSelect>

        <FormTextInput
          placeholder='Amount'
          name={`vendor_type_vendor_contracts[${index}][amount]`}
          type="number"
        />
        </div>
      </div>
    )
  }

  vendorPaymentsFields = (values, index) => {
    const remindDays = (values?.vendor_payments[index]?.days_before_to_send_reminder);
    return (
      <div>
        <div className='field is-grouped media-align-flex-end'>
          <FormTextInput
            name={`vendor_payments[${index}][total_due]`}
            type="number"
            label='Amount Due'
            placeholder="Amount Due"
          />

          <DatePickerField
            label='Due Date (required)'
            name={`vendor_payments[${index}][due_date]`}
          />

          <FormTextInput
            label={`Remind Me ${remindDays || 10} Days Before`}
            name={`vendor_payments[${index}][days_before_to_send_reminder]`}
            type="number"
            placeholder="10"
          />
        { values.vendor_payments[index]['id'] &&
          <FormSelect label="Status" name={`vendor_payments[${index}][status]`}>
            <option value={'unpaid'}>Unpaid</option>
            <option value={'fully_paid'}>Paid</option>
          </FormSelect>
        }
        </div>
      </div>
    )
  }

  handleDeletedPayments = (vals, contract) => {
    if (contract && contract.vendor_payments.length > vals.vendor_payments.length) {
      let payments = [...contract.vendor_payments];
      let savedPayments = vals.vendor_payments.map(v => v.id);
      payments.forEach((payment) => {
        if (savedPayments.includes(payment.id) === false) {
          payment['_destroy'] = true;
        }
      })
      return payments;
    } else {
      return vals.vendor_payments;
    }
  }

  handleDeletedTypes = (vals, contract) => {
    if (contract && contract.vendor_types.length > vals.vendor_type_vendor_contracts.length) {
      let vendor_types = [...contract.vendor_types];
      let savedTypes = vals.vendor_type_vendor_contracts.map(v => v.id);
      vendor_types.forEach((vt) => {
        if (savedTypes.includes(vt.id) === false) {
          vt['_destroy'] = true;
        }
      })
      return vendor_types;
    } else {
      return vals.vendor_type_vendor_contracts;
    }
  }

  submitContract = (vals, contract) => {
    if (this.state.reddyToSubmit && [SECTIONS.reviewAndSubmit, SECTIONS.allSections].includes(this.state.currentSection)) {
      this.setState({ isSubmitting: true }, () => {
        let method = 'POST';
        let url = '/api/v1/vendor_contracts';
        let contractId = null;
        if (contract && contract.id) {
          method = 'PATCH';
          url = `/api/v1/vendor_contracts/${contract.id}`;
          contractId = contract.id;
        }
        let payments = this.handleDeletedPayments(vals, contract);
        let vendorTypes = this.handleDeletedTypes(vals, contract);
        let paymentMethods = vals.payment_methods.filter(p => p['method'] != '').map(p => JSON.stringify(p) )
        axios({
          method: method,
          url: url,
          data: {
            vendor_contract: {
              id: contractId,
              vendor_id: this.props.vendor.id,
              link_to_document: vals.link_to_document,
              login: vals.login,
              password: vals.password,
              note_to_contract: vals.note_to_contract,
              total_price: vals.total_price,
              deposit_amount: vals.deposit_amount,
              not_included_in_budget: vals.not_included_in_budget,
              payment_methods: paymentMethods,
              vendor_payments_attributes: payments,
              vendor_type_vendor_contracts_attributes: vendorTypes
            },
            authenticity_token: this.props.token
          }
        })
        .then((response) => {
          this.setState({ reddyToSubmit: false }, () => {
            this.props.addOrUpdateContract(response.data.contract);
          })
        })
        .catch((error) => {
          this.setState({ isSubmitting: false, reddyToSubmit: false }, () => {
            let message = errorsFormatter(error.response.data.errors) || 'There was a problem saving this vendor.';
            handleResponse(this, message, error.response.status, 4000);
          })
        })
      })
    }
  }

  vendorTypeInitialValues = (contract) => {
    if (contract && contract.vendor_types && contract.vendor_types.length > 0) {
      return contract.vendor_types.map((vt) => {
        return {
          id: vt.id,
          vendor_type_id: vt.vendor_type_id || '',
          amount: vt.amount || ''
        }
      })
    }
    else {
      return [
        {
          amount: '',
          vendor_type_id: ''
        }
      ]
    }
  }

  vendorPaymentInitialValues = (contract) => {
    if (contract && contract.vendor_payments.length > 0) {
      return contract.vendor_payments.map((p) => {
        return {
          id: p.id,
          total_due: p.total_due || '',
          due_date: p.due_date || '',
          days_before_to_send_reminder: p.days_before_to_send_reminder || '',
          status: p.status || '',
        }
      })
    } else {
      return [
        {
          total_due: '',
          due_date: '',
          days_before_to_send_reminder: '',
          status: 'fully_paid',
        }
      ]
    }
  }

  initialValues = () => {
    let { contract } = this.props;
    if (contract) {
      return {
        id: contract.id || '',
        name: contract.name || '',
        total_price: parseFloat(contract.total_price) || '',
        deposit_amount: parseFloat(contract.deposit_amount) || '',
        link_to_document: contract.link_to_document || '',
        login: contract.login || '',
        password: contract.password || '',
        note_to_contract: contract.note_to_contract || '',
        days_before_to_send_reminder: contract.days_before_to_send_reminder || '',
        number_of_hours_contracted: contract.number_of_hours_contracted || '',
        not_included_in_budget: contract.not_included_in_budget || false,
        payment_methods: contract.payment_methods.map(p => JSON.parse(p)),
        vendor_payments: this.vendorPaymentInitialValues(contract),
        vendor_type_vendor_contracts: this.vendorTypeInitialValues(contract)
      }
    } else {
      return (
        {
          id: '',
          name:'',
          total_price: '',
          deposit_amount: '',
          link_to_document: '',
          login: '',
          password: '',
          note_to_contract: '',
          days_before_to_send_reminder: '',
          number_of_hours_contracted: '',
          not_included_in_budget: false,
          payment_methods: [{ method: '', note: '' }],
          vendor_payments: this.vendorPaymentInitialValues(),
          vendor_type_vendor_contracts: this.vendorTypeInitialValues()
        }
      )
    }
  }

  formNavigation = (values) => {
    if (!this.props.contract || !this.props.contract.id) {
      return (
        <nav className="breadcrumb has-arrow-separator" aria-label="breadcrumbs">
          <ul className='vendor-payments__nav'>
            <li>
              <span onClick={() => this.setState({ currentSection: SECTIONS.basicInfo })}>Contract Details</span>
            </li>
        </ul>
        </nav>

      )
    }
  }

  form(isSubmitting) {
    if (isSubmitting) {
      return <Loading />
    } else {

    }
  }

  render() {
    if (this.state.isSubmitting) {
      return (
        <div className='vendor-contract__form'>
           <Loading />
        </div>
      )
    } else {
      return (
        <div className='vendor-contract__form'>
        <Formik
          initialValues={this.initialValues()}
          validationSchema={Yup.object({
            total_price: Yup.number()
              .required('Please enter a valid amount.')
              .min(0, 'Has to be more than 0!'),
            vendor_type_vendor_contracts: Yup.array()
            .of(Yup.object().shape({
                vendor_type_id: Yup.number().required('Please select a vendor type.'),
                amount: Yup.number().required('Please enter an amount.')
              })
            ),
            vendor_payments: Yup.array()
              .of(Yup.object().shape({
                total_due: Yup.number(),
                due_date: Yup.date()
                  .when(['total_due'], {
                    is: (val1, val2) => val1 > 0 && val2 != true,
                    then: Yup.date().required('Please add a date.')
                  })
              }))
          })}
          render={({ values }) => (
            <Form>
              { this.formNavigation(values) }
              { this.currentSection(values) }
            </Form>
          )}
          onSubmit={(values, { setSubmitting }) => {
            this.submitContract(values, this.props.contract);
          }}
        />
        </div>
      );
    }
  }
}

ContractForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
  deleteContract: PropTypes.func.isRequired
}
