import axios from 'axios';

export function updateUserTask(activeTask, token) {
  let users_checklist_item = activeTask;
  let axiosPromise = axios.patch(`/api/v1/users_checklist_items/${activeTask.id}`, {
    users_checklist_item,
    authenticity_token: token
  })
  return axiosPromise
}
