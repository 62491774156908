import React from 'react';
import PropTypes from 'prop-types';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false
    }
  }

  render() {
    return (
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className={"navbar-menu " + (this.state.isActive ? 'is-active' : '') }>
        { this.props.hideButtons &&
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <img src="/images/gb-logo.png" />
            </a>
          </div>
        }
          <div className="navbar-end">
          { !this.props.hideButtons &&
              <div className="navbar-item">
                <div className="buttons">
                  <a className="acts-as-button button button-login" href='/users/sign_in'>
                    Log in
                  </a>
                  <a className="acts-as-button button button-primary button-signup" href='/users/sign_up'>
                    Sign up
                  </a>
                </div>
              </div>
          }
            </div>
          </div>
      </nav>
    )
  }
}

Header.propTypes = {
  hideButtons: PropTypes.bool
}
