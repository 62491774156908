import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, useField } from 'formik';
import { FormTextInput, FormSelect, DatePickerField, FormRadioGroup, FormRadioButton, Loading } from '../../../forms/Helpers';
import * as Yup from 'yup';
import * as CONSTANTS from '../../../Constants'

export default class LocationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  submitDisabled = (values) => {
    let isDisabled = false;
    for (let i = 0; i < Object.keys(values).length; i++) {
      if (!values[Object.keys(values)[i]] || values[Object.keys(values)[i]].length === 0) {
        isDisabled = true;
        break
      }
    }

    return isDisabled;
  }


  render() {
    return (
      <Formik
        initialValues={{
          street_address: '',
          city: '',
          state_or_province: 'Alabama',
          country: 'United States',
          zip_code: '',
          name: '',
        }}
        validationSchema={Yup.object({
          street_address: Yup.string()
            .required('Required'),
          city: Yup.string()
            .required('Required'),
          country: Yup.string()
            .required('Required'),
          zip_code: Yup.string()
            .required('Required')
        })}
        onSubmit={(values) => {
          this.props.onFormSubmit(values);
        }}
        render={({ values }) => (
          <Form className='location-form'>
            <div className="field">
              <FormTextInput
                name="name"
                type="text"
                placeholder="Venue Name"
              />
            </div>

            <div className='field'>
              <FormTextInput
                name="street_address"
                type="text"
                placeholder="Street Address"
              />
            </div>

            <div className='field'>
              <FormTextInput
                name='city'
                type='text'
                placeholder='City'
              />
            </div>
            <div className='field is-grouped'>
            {  values.country === 'United States' &&
              <FormSelect name='state_or_province'>
                   { CONSTANTS.AmericanStates().map((s, index) => {
                     return <option key={index} value={s.name}>{ s.name} </option>
                   })}
                </FormSelect>
            }
            <FormTextInput
              name="zip_code"
              type="text"
              placeholder="Zip"
            />
            </div>

            <div className="field">
              <FormSelect name='country'>
                   { CONSTANTS.Countries().map((c, index) => {
                     return <option key={index} value={c.name}>{ c.name} </option>
                   })}
                </FormSelect>

            </div>
            <button disabled={this.submitDisabled(values)} className="button button-primary" type="submit">Submit</button>
            <button className='button' onClick={this.props.onCancel}>Cancel</button>
          </Form>
        )}
      >
      </Formik>
    )
  }
}

LocationForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired

}
