import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import { handleResponse, FormResponse, errorsFormatter } from '../../../forms/Response';
import { FormTextInput, FormSelect, Loading } from '../../../forms/Helpers';


export default class QuoteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleSubmit = (values) => {
    const is_venue = this.props.page === "Venue Quotes";
    let url = '/api/v1/quotes';
    let method = 'POST';
    if (this.props.quote) {
      url = `/api/v1/quotes/${this.props.quote.id}`;
      method = 'PATCH';
    }
    this.setState({ isSubmitting: true }, () => {
      axios({
        method: method,
        url,
        data: {
          quote: {
            company_name: values.companyName,
            amount: values.amount,
            note: values.note,
            vendor_type_id: values.vendor_type_id,
            is_venue,
            user_id: this.props.user.id,
            id: this.props.quote ? this.props.quote.id : null
          },
          authenticity_token: this.props.token
        }
      })
      .then((response) => {
        this.setState({ isSubmitting: false }, () => {
          if (this.props.quote) {
            this.props.handleUpdatedQuote(response.data.quote);
          } else {
            this.props.handleNewQuote(response.data.quote);
          }
        })
      })
      .catch((error) => {
        this.setState({ isSubmitting: false }, () => {
          handleResponse(this, 'There was a problem saving this quote.', error.response.status, 4000)
        })
      })
    })
  }

  initialValues = () => {
    let { quote } = this.props;
    if (quote) {
      return {
        companyName: quote.company_name || '',
        amount: quote.amount || '',
        note: quote.note || '',
        vendor_type_id: quote.vendor_type_id || ''
      }
    } else {
      return {
        companyName: '',
        amount: '',
        note: '',
        vendor_type_id: ''
      }
    }
  }

  render() {
    if (this.state.isSubmitting) {
      return (<Loading />)
    } else {
      let { responseMessage, responseCode } = this.state;
      const { quote, vendorTypes, vendorCategories } = this.props;
      return (
        <>
          <h4 className='title is-4 mb-one-em'>{ quote ? 'Edit Quote' : 'Add a New Vendor Quote'}</h4>
            { FormResponse(responseMessage, responseCode) }

            <Formik
              initialValues={this.initialValues()}
              validationSchema={Yup.object({
                companyName: Yup.string()
                  .required('Required')
              })}
              onSubmit={(values, { setSubmitting }) => {
                  this.handleSubmit(values)
              }}
              render={({ values }) => (
                <Form>
                  <div className='field'>
                    <FormTextInput
                      label='Company Name'
                      name="companyName"
                      type="text"
                      placeholder='Company Name'
                    />
                  </div>

                {
                  this.props.page === "Vendor Quotes" &&
                  <div className='field'>
                    <FormSelect label="Vendor Type" name='vendor_type_id'>
                      <option value=""></option>
                      {
                        vendorCategories?.map(c => {
                          if (vendorTypes?.map(t => t[2]).includes(c[0])) {
                            return (
                              <optgroup key={c[0]} label={c[1]}>
                                {
                                  vendorTypes?.map(t => {
                                    if (c[0] === t[2]) {
                                      return (
                                        <option key={t[0]} value={t[0]}>{t[1]}</option>
                                      )
                                    }
                                  })
                                }
                              </optgroup>
                            )
                          }
                        })
                      }
                    </FormSelect>
                  </div>
                }

                  <div className='field'>
                    <FormTextInput
                      label='Quote Amount'
                      name="amount"
                      type="number"
                      placeholder='$'
                    />
                  </div>

                  <div className='field'>
                    <FormTextInput
                      label='Services Offered/Notes'
                      name="note"
                      type="text"
                      placeholder='Note'
                    />
                  </div>

                  <div className='row-buttons'>
                    <button className='button button-primary' type="submit">Submit</button>
                    <button
                      className='button'
                      onClick={this.props.closeForm}
                      type="submit">Cancel
                    </button>
                  </div>
                </Form>
              )}>
            </Formik>

        </>
      )
    }
  }
}

QuoteForm.propTypes = {
  token: PropTypes.string.isRequired,
  handleNewQuote: PropTypes.func.isRequired,
  handleUpdatedQuote: PropTypes.func.isRequired,
}
