import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { ADYEN_PAYMENT_METHODS } from './AdyenConstants';

export default class Checkout extends React.PureComponent {
  constructor(props) {
    super(props);
    const stripe = new Stripe(this.props.stripePublishableKey);
    const elements = stripe.elements();
    const cardNumber = elements.create('cardNumber');
    const cardExpiry = elements.create('cardExpiry');
    const cardCvc = elements.create('cardCvc');

    this.state = {
      stripe,
      elements,
      cardNumber,
      cardExpiry,
      cardCvc,
      cardholderName: null,
      isLoading: false,
      responseMessage: null,
      responseStatus: null,
      idCoupon: ''
    }
  }

  componentDidMount() {
    const { cardNumber, cardExpiry, cardCvc } = this.state;
    cardNumber.mount('#cardNumber');
    cardExpiry.mount('#cardExpiry');
    cardCvc.mount('#cardCvc');
  }

  // adyen payments init
  // componentDidMount() {
  //   // const { dollarsPrice, subscriptionPer } = this.props;
  //   const translations = {
  //     "en-US": { "payButton": "Submit" }
  //   }

  //   const configuration = {
  //     paymentMethodsResponse: ADYEN_PAYMENT_METHODS || [],
  //     translations: translations,
  //     clientKey: this.props.clientKey,
  //     environment: "test",
      // onSubmit: (state, dropin) => {
      //   dropin.setStatus('loading');
      //   this.makePayment(state.data)
      //     .then(response => {
      //       if (response.data.action_required) {
      //         dropin.handleAction(response.data.response.response.action);
      //       } else {
      //         this.showFinalResult(dropin);
      //       }
      //    })
      //    .catch(error => {
      //      this.displayError(error, dropin);
      //    });
      // },
  //     onAdditionalDetails: (state, dropin) => {
        // this.finalizePayment(state)
        //   .then(response => {
        //     if (response.data.action_required) {
        //       dropin.handleAction(response.data.response.response.action);
        //     } else {
        //       this.showFinalResult(dropin);
        //    }
        //  })
        //  .catch(error => {
        //    this.displayError(error, dropin);
        // });
  //    },
  //    paymentMethodsConfiguration: {
  //      card: {
  //        hasHolderName: true,
  //        holderNameRequired: true,
  //        enableStoreDetails: false,
  //        hideCVC: false,
  //        name: 'Credit or debit card'
  //      }
  //    }
  //  };

  //   const checkout = new AdyenCheckout(configuration);
  //   const dropin = checkout.create('dropin').mount('#dropin');
  // }

  showFinalResult = () => {
    this.setState({
      responseStatus: 'success',
      responseMessage: 'Your payment information has been saved'
    })
    setTimeout(() => window.location.reload(), 500)
  }

  displayError = (error) => {
    let response = error.response;
    let defaultError = 'There was a problem processing your payment.';
    let message = response && response.data && response.data.error ? `${defaultError} Reason: ${response.data.error.refusalReason}` : defaultError

    this.setState({
      responseStatus: 'error',
      responseMessage: message
    })
  }

  createSubscription = ({ source }) => {

    return axios.get('/coupons').then((res) => {
      console.log('res', res)
      let couponId;
      if(this.props.promoCode && this.props.promoCode.length > 1) {
        const couponObj = res.data.coupon.data.find(item => item.name.toLowerCase() == this.props.promoCode.toLowerCase())
        console.log('couponObj', couponObj)
        couponId = couponObj.id 
        this.setState({ idCoupon: couponObj.id })
      }

      return axios.post('/subscriptions', {
        // payment_info: paymentInfo,
        source,
        promo_code: couponId ? couponId : '',
        user: { id: this.props.user.id },
        payment_behavior: couponId ? 'allow_incomplete' : 'error_if_incomplete',
        authenticity_token: this.props.token
      })
    })
  }

  finalizePayment = (state) => {
    return axios.post('/finalize_subscription_with_3ds2', {
      details: state.data.details,
      paymentData: state.data.paymentData,
      promo_code: this.state.idCoupon && this.state.idCoupon.length > 1? this.state.idCoupon : '',
      u: this.props.user.unique_identifier,
      token: this.props.token
    })
  }

  submitPaymentForm = (e, { stripe, cardNumber, cardCvc, cardholderName }) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    stripe.createToken(cardCvc, { name: cardholderName }).then((result) => {
      if (result.error) {
        this.setState({ isLoading: false });
        this.displayError({response: {
          data: { error: { refusalReason: result.error.message } }
        }})
        return;
      }

      console.log('result', result)

      this.createSubscription({ source: result.token.id }).then(
        () => this.showFinalResult()
      ).catch((e) => {
        this.setState({ isLoading: false });
        this.displayError(e)
      })
    });
  }

  renderResponseMessage() {
    const { responseStatus, responseMessage } = this.state;
    return <p className="mb-1em">
      <span className={"form-response form-response--" + (responseStatus === 'success' ? 'success' : 'failure')}>
        { responseMessage }
      </span>
    </p>
  }

  render() {
    return (
      <form
        id="dropin"
        className="mt-one-em mb-one-em"
        onSubmit={(e) => this.submitPaymentForm(e, this.state)}
      >
        <div className="mb-one-em">
          <label htmlFor="cardNumber">Card number</label>
          <div id="cardNumber" className="input"></div>
        </div>
        <div className="columns mb-one-em">
          <div className="column">
            <label htmlFor="cardExpiry">Expiry date</label>
            <div id="cardExpiry" className="input"></div>
          </div>
          <div className="column">
            <label htmlFor="cardCvc">CVC / CVV</label>
            <div id="cardCvc" className="input"></div>
          </div>
        </div>
        <div className="mb-one-em">
          <label htmlFor="cardName">Name on card</label>
          <input
            required
            type="text"
            id="cardName"
            className="input"
            placeholder="J. Smith"
            onChange={(e) => this.setState({ cardholderName: e.target.value }) }
          />
        </div>

        { this.renderResponseMessage() }
        <button
          type="submit"
          className="button button-primary"
          style={{width: '100%'}}
          disabled={this.state.isLoading}
        >Submit</button>
      </form>
    )
  }
}

Checkout.propTypes = {
  token: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  clientKey: PropTypes.string.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
  subscriptionPer: PropTypes.string.isRequired,
  dollarsPrice: PropTypes.number.isRequired,
  promoCode: PropTypes.string
}
